import React, { Component } from "react";
import axios from "axios";
import numeral from "numeral";
import LoadingOverlay from "react-loading-overlay";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { amtrustResponse } from "../../../utils/form_ques";
import { LibertateContext } from "../../../context/Context";
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
  clearErrHistoricalClaims,
} from "../../../utils/common";
import {
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  handleCheck,
} from "../../../utils/form_cmprRates";
import { awsUrl, awsUrl2 } from "../../../config";
import { Auth } from "aws-amplify";
import $ from "jquery";
import QuoteTable from "../../common/QuoteTable";
import RecalculateQuote from "../../common/RecalculateQuote";
import DropZones from "../../common/DropZones";
import Tabs from "../../common/Tabs";
import NewProgress from "../../../newcomponent/common/NewProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EmodQuestions from "./EmodQuestions";
import {
  carrierPeoProgramList,
  programMapping,
} from "../../../utils/carrierList.json";
import ToggleSwitch from "../../../utils/ToggleSwitch";
import {
  saveInUserstatusTable,
  saveInUserTable,
} from "../../../utils/form_compPro";
import SmallLoader from "../../../newcomponent/common/smallLoader";
let mono_states = require("../../../utils/mono_states.json");
const not_include_in_ncci = ["oh", "wa", "wy", "nd", "fl", "ca"];

let isMobile = window.innerWidth < 1000;
class FormCmprQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      visible: false,
      uuid_carrier: [],
      fileURL_Map: {},
      fileURL_Map1: {},
      fileURL2: undefined,
      loadingAccord: {},
      isNoLossChckd: false,
      loadingNoLoss: false,
      selectedCarrier: "",
      historicalClaimsError: false,
      historicalClaims: {},
      historicalClaimsCheck: {},
      historicalClaimsErrorStore: {},
      emodStatesData: {},
      peoData: {},
      show_acord: {},
      acordSelectedState: {},
      carrierSpecific: [],
      stateRecalculateObject: {},
      acordIncludPremium: {},
      latest_ncci_key_program: {},
      toggleSwitchCheck: false,
      toggleLoader: false,
      uwLogin: false,
      programDateMap: {},
      loadingEAcordNCCI: false,
      loadingEAcordCA: false,
      loadingEAcordFL: false,
      amtrustError: false,
    };
    this.getAllDataWRTCarrier = this.getAllDataWRTCarrier.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.QuoteView = this.QuoteView.bind(this);
    this.handleCheck = handleCheck.bind(this);
    this.clearErr = clearErrHistoricalClaims.bind(this);
  }
  componentWillMount() {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }

  async componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);

    let sessionData = await Auth.currentAuthenticatedUser();
    let uwLogin = false;
    if (sessionData) {
      let userGroups =
        sessionData.signInUserSession.accessToken.payload["cognito:groups"];

      if (userGroups.includes(process.env.REACT_APP_RECAL_GROUP)) {
        this.setState({ hasRecalGroup: true });
      }
      if (userGroups.includes(process.env.REACT_APP_UW_GROUPS)) {
        uwLogin = true;
        this.setState({ uwLogin });
      }
    }

    sessionStorage.removeItem("hasProrateValue");

    let { data } = this.state;
    let peoData = {};
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );
    if (currProspectDetails) {
      let { emodStatesData } = currProspectDetails;
      this.setState({ emodStatesData });
    }
    var formStage = sessionStorage.getItem("formStage");
    console.log("Form stage: ", formStage);
    if (
      formStage === "five" ||
      formStage === "six" ||
      formStage === "three" ||
      formStage === "four"
    ) {
      $("#loader").css("display", "block");
      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );

      let amtrustAccountDetails = sessionStorage.getItem(
        "amtrustAccountDetails"
      );
      let amtrustAccountDetailsCopy = sessionStorage.getItem(
        "amtrustAccountDetailsCopy"
      );

      try {
        amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
      } catch (error) {
        amtrustAccountDetails = {};
      }

      if (currProspectDetails !== null) {
        let peo_value;
        let user_id = sessionStorage.getItem("user_id");
        axios
          .get(awsUrl + "/api/getUserDataStatus/" + user_id + "/quote")
          .then(async (response) => {
            let dataFromAPI = response.data;
            // console.log(dataFromAPI, "======");
            let quoteTableData = {};
            let perStateQuoteTableData = {};
            let programs = [];
            let fileListToStore;
            let historicalClaims,
              historicalClaimsCheck,
              isNoLossChckd,
              startDate;
            let masterWithoutFundRateMap = {};
            let programStateType = {};
            let currentCarrierMap = {};
            let excludedClassCodeMap = {};
            let fClassCodeList = [];
            let stateCarrierData = {};
            let uw_net_rates;
            let _uwFileList;
            let carrierBasedData = {};

            let statearray = [];
            let sdr_factor = {};
            let quoteSubmitted = false;
            let submissionDate;
            let real_submission;
            // //Net Rates code after we send the payment plan to Amtrust

            let amtrustNetRatesResponse = {};
            let amtrustTriggerResponse;
            let carrierList_value = currProspectDetails?.carrierList?.value.map(
              (val) => val.value
            );
            let carrierList_label = currProspectDetails?.carrierList?.value.map(
              (val) => val.label
            );

            if (
              carrierList_value.includes("carrier_g") ||
              carrierList_label.includes("Amtrust")
            ) {
              programs.push("Insurecomp-Amtrust");
            }

            //Amtrust API for getting Net Rates
            if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
              try {
                if (!amtrustAccountDetails) {
                  let amtrustAccountDetailsCopy = JSON.parse(
                    sessionStorage.getItem("amtrustAccountDetailsCopy")
                  );
                  amtrustAccountDetails.QuoteId =
                    amtrustAccountDetailsCopy.QuoteId;
                }
                console.log(
                  "Fetching net rates for QuoteId:",
                  amtrustAccountDetails.QuoteId
                );

                amtrustTriggerResponse = await axios.get(
                  awsUrl2 +
                    `/api/triggerAmtrustGetNetRates/${amtrustAccountDetails.QuoteId}`
                );

                console.log("amtrustTriggerResponse:", amtrustTriggerResponse);

                let netRatesUuid;
                if (amtrustTriggerResponse.data.uuid) {
                  netRatesUuid = amtrustTriggerResponse.data.uuid;
                } else {
                  throw new Error(
                    "Error in amtrust create quote trigger: UUID not found"
                  );
                }

                let retryCount = 0;
                const maxRetries = 5;

                do {
                  amtrustNetRatesResponse = await amtrustResponse(
                    netRatesUuid,
                    "getNetRates"
                  );
                  console.log(
                    "amtrustNetRatesResponse:",
                    amtrustNetRatesResponse
                  );

                  if (amtrustNetRatesResponse === "error") {
                    throw new Error("Error in amtrust get net rates");
                  }

                  retryCount++;
                  if (retryCount > maxRetries) {
                    throw new Error(
                      "Max retries exceeded in amtrust get net rates"
                    );
                  }

                  await new Promise((resolve) => setTimeout(resolve, 1000)); // Adding delay between retries
                } while (amtrustNetRatesResponse === "processing");
              } catch (error) {
                console.error(
                  "Error in fetching amtrust net rates response:",
                  error
                );
                this.setState({ amtrustError: true });
              }
            }

            console.log("Amtrust net Rates", amtrustNetRatesResponse);
            if (
              Object.keys(amtrustNetRatesResponse).length > 0 &&
              amtrustNetRatesResponse !== "error"
            ) {
              let user_id = sessionStorage.getItem("user_id");
              let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
              let address = JSON.parse(sessionStorage.getItem("address"));
              let currProspectDetails = JSON.parse(
                sessionStorage.getItem("currProspect")
              );
              sessionStorage.setItem(
                "amtrustNetRatesResponse",
                JSON.stringify(amtrustNetRatesResponse)
              );

              let req_body_userstatus = {
                user_email_id: user_id,
                timestamp: quoteData.date,
                uuid: quoteData.uuid,
                address: address,
                peo:
                  currProspectDetails.peoDetails.selectedPeo === "demo"
                    ? "gms"
                    : currProspectDetails.peoDetails.selectedPeo,
                amtrustNetRatesResponse: JSON.parse(
                  JSON.stringify(amtrustNetRatesResponse)
                ),
              };

              let req_body_users = {
                user_email_id: user_id,
                amtrustNetRatesResponse: JSON.parse(
                  JSON.stringify(amtrustNetRatesResponse)
                ),
              };

              let result1 = saveInUserstatusTable(req_body_userstatus);
              let result2 = saveInUserTable(req_body_users);

              Promise.all([result1, result2])
                .then(([result1, result2]) => {})
                .catch((err) => {
                  console.log(err);
                });
            }
            // end of Net Rates.

            //set stateCarrierData : for status and comments on UW page.
            if (dataFromAPI.stateCarrierData) {
              stateCarrierData = dataFromAPI.stateCarrierData;
              // this.props.setStateCarrierData(dataFromAPI.stateCarrierData);
            }
            if (dataFromAPI.carrierBasedData) {
              carrierBasedData["data"] = dataFromAPI.carrierBasedData;
              carrierBasedData["uuid"] = dataFromAPI?.uuid;
            }
            this.props.setCarrierBasedData(carrierBasedData);

            let states_has_sdr_in_algo = [];

            for (let location in dataFromAPI.carrierBasedData) {
              for (let peo in dataFromAPI.carrierBasedData[location]) {
                let state_payroll;
                ({
                  historicalClaims,
                  historicalClaimsCheck,
                  isNoLossChckd,
                  startDate,
                  state_payroll,
                  sdr_factor,
                  quoteSubmitted,
                  real_submission,
                  submissionDate,
                  fileList: fileListToStore,
                  uwFileList: _uwFileList,
                } = dataFromAPI.carrierBasedData[location][peo]);

                if (quoteSubmitted && submissionDate) {
                  this.setState({ quoteSubmitted: true });
                  sessionStorage.setItem("submissionDate", submissionDate);
                }

                // on did mount setting the real submission to state
                if (real_submission) {
                  let real_submission_result =
                    real_submission === "true" ? true : false;
                  this.setState({ toggleSwitchCheck: real_submission_result });
                }

                if (fileListToStore) {
                  let fileList = {
                    first: {},
                    second: {},
                  };
                  for (let row in fileListToStore) {
                    if (!fileList[fileListToStore[row].keyToFile])
                      fileList[fileListToStore[row].keyToFile] = {};
                    fileList[fileListToStore[row].keyToFile][
                      fileListToStore[row].key.split("/").reverse()[0]
                    ] = "#empty";
                  }
                  this.setState({ fileList });
                }

                if (_uwFileList) {
                  let fileList = {
                    third: {},
                  };

                  for (let row in _uwFileList) {
                    if (!fileList[_uwFileList[row].keyToFile])
                      fileList[_uwFileList[row].keyToFile] = {};
                    fileList[_uwFileList[row].keyToFile][
                      _uwFileList[row].key.split("/").reverse()[0]
                    ] = "#empty";
                  }
                  this.props.setUwFileList(fileList);
                }

                if (!peo_value) {
                  peo_value = peo;
                }

                if (
                  dataFromAPI.carrierBasedData[location][peo]["uw_net_rates"]
                ) {
                  uw_net_rates =
                    dataFromAPI.carrierBasedData[location][peo]["uw_net_rates"];
                }

                for (let carrier in dataFromAPI.carrierBasedData[location][peo]
                  .carrier_location_data) {
                  let totalPayrollStateWise = 0;
                  let {
                    state,
                    program,
                    masterOrMcp: stateType,
                    total_estimated_annual_premium: teap,
                    employees,
                    net_rate_values: netRates,
                    fundRateOrigin,
                    manual_rate_values,
                    manual_premium_sum,
                    manual_premium_values,
                    minimum_premium_sum,
                    net_rate_values,
                    scheduled_rating_factor,
                    calculationType,
                    prorateValue,
                    total_tax,
                    estimated_annual_premium,
                    original_estimated_annual_premium,
                    f_class_codes,
                  } = dataFromAPI.carrierBasedData[location][
                    peo
                  ].carrier_location_data[carrier];
                  statearray.push({
                    carrier: carrier,
                    state: state,
                  });

                  if (!(carrier in stateCarrierData)) {
                    stateCarrierData[carrier] = {};
                  }

                  let carrierList_value = currProspectDetails?.carrierList?.value.map(
                    (val) => val.value
                  );
                  if (!(state in stateCarrierData[carrier])) {
                    stateCarrierData[carrier][state] = {
                      comments: "",
                      status: "select",
                      debit_credit: {
                        isChecked: false,
                        value: "",
                      },
                      isChecked: false,
                    };
                  }

                  if (
                    scheduled_rating_factor ||
                    scheduled_rating_factor === 0
                  ) {
                    states_has_sdr_in_algo.push({
                      state,
                    });
                  }

                  let ccEligData =
                    dataFromAPI.carrierBasedData[location][peo]
                      ?.classCodeEligibility?.[carrier];
                  if (ccEligData) {
                    for (let classCode in ccEligData) {
                      if (ccEligData[classCode] === "excluded") {
                        if (!excludedClassCodeMap[carrier]) {
                          excludedClassCodeMap[carrier] = {};
                        }
                        if (!excludedClassCodeMap[carrier][state]) {
                          excludedClassCodeMap[carrier][state] = [];
                        }
                        excludedClassCodeMap[carrier][state].push(classCode);
                      }
                    }
                  }

                  let fClassCodeData = f_class_codes;
                  if (fClassCodeData && fClassCodeData.length > 0) {
                    for (let i = 0; i < fClassCodeData.length; i++) {
                      if (!fClassCodeList[carrier]) {
                        fClassCodeList[carrier] = {};
                      }
                      if (!fClassCodeList[carrier][state]) {
                        fClassCodeList[carrier][state] = [];
                      }
                      if (!fClassCodeList.includes(fClassCodeData[i])) {
                        fClassCodeList[carrier][state].push(fClassCodeData[i]);
                      }
                    }
                  }

                  if (!(carrier in currentCarrierMap)) {
                    currentCarrierMap[carrier] = false;
                  }

                  if (employees) {
                    if (!(program in peoData)) {
                      peoData[program] = {};
                      peoData["Insurecomp-Amtrust"] = {};
                    }
                    let peoStateDataList = [];

                    // let total_row = {
                    //   state: 'total',

                    // };

                    for (let eIndx in employees) {
                      try {
                        let currObj = {};

                        currObj.state = state;
                        currObj.eligibility =
                          dataFromAPI?.carrierBasedData?.[location]?.[peo]
                            ?.classCodeEligibility?.[carrier]?.[
                            employees[eIndx].code
                          ] || "";

                        currObj.wc_code = employees[eIndx].code;
                        currObj.wc_desc = employees[eIndx].desc;

                        currObj.manual_rate = manual_rate_values?.[
                          employees?.[eIndx]?.code
                        ]
                          ? manual_rate_values[employees[eIndx].code]
                          : 0;
                        currObj.net_rate = net_rate_values?.[
                          employees?.[eIndx]?.code
                        ]
                          ? net_rate_values[employees[eIndx].code]
                          : 0;

                        currObj.manual_premium = manual_premium_values?.[
                          employees?.[eIndx]?.code
                        ]
                          ? manual_premium_values[employees[eIndx].code]
                          : 0;

                        currObj.payroll =
                          employees[eIndx].original_payroll ||
                          employees[eIndx].payroll;

                        totalPayrollStateWise +=
                          Number(employees[eIndx].original_payroll) ||
                          Number(employees[eIndx].payroll) ||
                          0;

                        try {
                          currObj.teap = numeral(employees[eIndx].payroll)
                            .multiply(currObj.net_rate)
                            .divide(100)
                            .value();
                        } catch (error) {}

                        try {
                          if (
                            prorateValue &&
                            prorateValue !== 1 &&
                            calculationType === "eap" &&
                            !(
                              stateType === "master" &&
                              fundRateOrigin === "fundrate"
                            ) &&
                            !minimum_premium_sum
                          ) {
                            sessionStorage.setItem("hasProrateValue", "true");

                            let tax_multiplier_factor = numeral(total_tax || 0)
                              .multiply(100)
                              .divide(estimated_annual_premium)
                              .value();

                            let tax_value = numeral(
                              original_estimated_annual_premium
                            )
                              .multiply(tax_multiplier_factor)
                              .divide(100)
                              .value();

                            let calculated_teap =
                              tax_value + original_estimated_annual_premium;

                            let calculated_state_fund_rate = numeral(
                              calculated_teap
                            )
                              .divide(manual_premium_sum)
                              .value();

                            let annualized_net_rate = numeral(
                              currObj.manual_rate
                            )
                              .multiply(calculated_state_fund_rate)
                              .value();

                            let annualized_eap = numeral(annualized_net_rate)
                              .multiply(currObj.payroll)
                              .divide(100)
                              .value();

                            currObj.annualized_net_rate = annualized_net_rate;
                            currObj.annualized_eap = annualized_eap;
                          } else {
                            currObj.annualized_net_rate = 0;
                            currObj.annualized_eap = 0;
                            currObj.masterWithFundRateOrMinimumPremSum =
                              (stateType === "master" &&
                                fundRateOrigin === "fundrate") ||
                              minimum_premium_sum
                                ? true
                                : false;

                            if (
                              stateType === "master" &&
                              fundRateOrigin === "fundrate"
                            ) {
                              currObj.masterWithFundRateProratedEAP = numeral(
                                currObj.teap
                              )
                                .multiply(prorateValue)
                                .value();
                            }
                          }
                        } catch (error) {
                          console.log(error);
                          currObj.annualized_net_rate = 0;
                          currObj.annualized_eap = 0;
                        }
                        peoStateDataList.push(currObj);
                      } catch (error) {}
                    }
                    peoData[program][state] = peoStateDataList;

                    if (carrierList_label.includes("Amtrust")) {
                      peoData["Insurecomp-Amtrust"][state] = peoStateDataList;
                    }
                  }

                  const checkManualPremiumIsPresent = (
                    state,
                    peoProgramData
                  ) => {
                    let result = false;
                    for (let key in peoProgramData?.[state]) {
                      if (peoProgramData[state][key]["manual_premium"] !== 0) {
                        result = true;
                        break;
                      }
                    }
                    return result;
                  };
                  if (program === "Insurecomp-Amtrust") {
                    fundRateOrigin = "emod";
                  }

                  let key = state;
                  let state_key = state;
                  let premium = teap ? Number(teap) : 0;
                  let isAmtrustCheck = carrierList_label.includes("Amtrust");
                  if (!programStateType[program]) {
                    programStateType[program] = {};
                  }

                  if (stateType === "master") {
                    key = "master";
                    if (!programStateType[program].master) {
                      programStateType[program].master = {};
                      if (isAmtrustCheck && program === "Insurecomp-Amtrust") {
                        programStateType["Insurecomp-Amtrust"].master = {};
                      }
                    }
                    if (fundRateOrigin === "emod") {
                      if (!masterWithoutFundRateMap[program]) {
                        masterWithoutFundRateMap[program] = [];
                      }
                      masterWithoutFundRateMap[program].push(state);
                      if (!programStateType[program].master.without_fundrate) {
                        programStateType[program].master.without_fundrate = [];
                      }
                      programStateType[program].master.without_fundrate.push(
                        state
                      );
                    } else if (fundRateOrigin === "fundrate") {
                      premium = this.getPremium(employees, netRates);
                      if (!programStateType[program].master.with_fundrate) {
                        programStateType[program].master.with_fundrate = [];
                        if (
                          isAmtrustCheck &&
                          program === "Insurecomp-Amtrust"
                        ) {
                          programStateType[
                            "Insurecomp-Amtrust"
                          ].master.with_fundrate = [];
                        }
                      }
                      programStateType[program].master.with_fundrate.push(
                        state
                      );
                      if (isAmtrustCheck && program === "Insurecomp-Amtrust") {
                        programStateType[
                          "Insurecomp-Amtrust"
                        ].master.with_fundrate.push(state);
                      }
                    }
                  } else {
                    programStateType[program][state] = "mcp";
                    if (isAmtrustCheck && program === "Insurecomp-Amtrust") {
                      programStateType["Insurecomp-Amtrust"][state] = "mcp";
                    }
                  }
                  if (mono_states.includes(state_key.toLowerCase())) {
                    stateType = "mono";
                    if (!uwLogin) continue;
                  }
                  if (!quoteTableData[state_key]) {
                    quoteTableData[state_key] = {
                      checked: { name: false },
                    };
                  }
                  if (!quoteTableData.total) {
                    quoteTableData.total = {
                      checked: { name: false },
                    };
                  }
                  if (!quoteTableData[state_key][program]) {
                    quoteTableData[state_key][program] = {
                      premium: 0,
                      payroll: 0,
                      state_type: stateType,
                    };
                    quoteTableData[state_key].checked[program] = false;
                  }
                  if (!quoteTableData.total[program]) {
                    quoteTableData.total[program] = {
                      premium: 0,
                      payroll: 0,
                    };
                    quoteTableData.total.checked[program] = false;
                  }
                  quoteTableData[state_key][program].premium += premium;
                  // console.log(state_key, program, carrier, quoteTableData[state_key][program], totalPayrollStateWise);
                  quoteTableData[state_key][program].payroll =
                    (state_key === "master"
                      ? quoteTableData[state_key][program].payroll
                      : 0) + totalPayrollStateWise;
                  quoteTableData.total[program].premium += premium;
                  quoteTableData.total[
                    program
                  ].payroll += totalPayrollStateWise;

                  if (stateType === "master" && fundRateOrigin === "fundrate")
                    quoteTableData[state_key][
                      program
                    ].isMasterWithFundRate = true;

                  //prepare perStateQuoteTableData here
                  if (!perStateQuoteTableData[state_key]) {
                    if (mono_states.includes(state_key.toLowerCase()))
                      stateType = "mono";
                    perStateQuoteTableData[state_key] = {
                      checked: { name: false },
                      type: stateType,
                    };
                  }
                  if (!perStateQuoteTableData.total) {
                    perStateQuoteTableData.total = {
                      checked: { name: false },
                    };
                  }
                  if (!perStateQuoteTableData[state_key][program]) {
                    perStateQuoteTableData[state_key][program] = {
                      premium: 0,
                      payroll: 0,
                    };
                    perStateQuoteTableData[state_key].checked[program] = false;
                  }
                  if (!perStateQuoteTableData.total[program]) {
                    perStateQuoteTableData.total[program] = {
                      premium: 0,
                      payroll: 0,
                    };
                    perStateQuoteTableData.total.checked[program] = false;
                  }
                  perStateQuoteTableData[state_key][program].premium += premium;
                  perStateQuoteTableData[state_key][
                    program
                  ].payroll = totalPayrollStateWise;
                  perStateQuoteTableData.total[program].premium += premium;
                  perStateQuoteTableData.total[
                    program
                  ].payroll += totalPayrollStateWise;
                  this.props.setPerStateQuoteTableData(perStateQuoteTableData);
                  if (!programs.includes(program)) {
                    programs.push(program);
                  }
                }
              }
            }

            //Adding Amtrust Caluculation Condition for perStateQuoteTableData
            if (
              carrierList_value.includes("carrier_g") ||
              carrierList_label.includes("Amtrust")
            ) {
              let amtrustNetRateSession = sessionStorage.getItem(
                "amtrustNetRatesResponse"
              );
              amtrustNetRateSession = JSON.parse(amtrustNetRateSession);
              if (
                amtrustNetRateSession != "error" &&
                amtrustNetRateSession !== null
              ) {
                if (Object.keys(amtrustNetRateSession)?.length > 0) {
                  let amtrustresponse = amtrustNetRateSession?.Data;
                  let amtrustresNr = amtrustresponse.map((res) => res.NetRate);
                  let amtrustresState = amtrustresponse.map((res) =>
                    res.State.toLowerCase()
                  );
                  let netState_obj = {};
                  for (let i = 0; i < amtrustresNr.length; i++) {
                    netState_obj[amtrustresState[i]] = amtrustresNr[i];
                  }
                  //State Wise check
                  let sum = [];
                  for (let i = 0; i < amtrustresState.length; i++) {
                    console.log(perStateQuoteTableData);
                    perStateQuoteTableData[amtrustresState[i]][
                      "Insurecomp-Amtrust"
                    ].premium =
                      perStateQuoteTableData[amtrustresState[i]]?.[
                        "Insurecomp-Amtrust"
                      ]?.payroll * netState_obj[amtrustresState[i]];

                    sum.push(
                      perStateQuoteTableData[amtrustresState[i]]?.[
                        "Insurecomp-Amtrust"
                      ]?.payroll * netState_obj[amtrustresState[i]]
                    );
                  }
                  //total Sum Check
                  let keySum = 0;
                  for (let key of sum) {
                    keySum += key;
                  }
                  perStateQuoteTableData.total[
                    "Insurecomp-Amtrust"
                  ].premium = keySum;
                  // perStateQuoteTableData.total[
                  //   "Insurecomp-Amtrust"
                  // ].payroll += totalPayrollStateWise;
                }
              }
            }

            //Adding Amtrust Caluculation for peoData Object
            function isObjectEmpty(obj) {
              for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                  return false;
                }
              }
              return true;
            }
            let amtrustNetRatesRes = JSON.parse(
              sessionStorage.getItem("amtrustNetRatesResponse")
            );
            if (
              amtrustAccountDetails &&
              amtrustNetRatesRes !== "error" &&
              !isObjectEmpty(amtrustAccountDetails) &&
              amtrustNetRatesRes !== null
            ) {
              amtrustNetRatesResponse = amtrustNetRatesRes;
              for (let obj of amtrustNetRatesResponse?.Data) {
                let amtrustState = obj.State.toLowerCase();
                if (peoData["Insurecomp-Amtrust"][amtrustState]) {
                  let amtrustPeoData =
                    peoData["Insurecomp-Amtrust"][amtrustState];
                  for (let res of amtrustPeoData) {
                    if (res.wc_code === obj.ClassCode) {
                      res.manual_rate = 0;
                      res.manual_premium = 0;
                      res.wc_desc = obj?.ClassDescription;
                      res.net_rate = obj?.NetRate * 100;
                      res.manual_premium = 0;
                      res.teap = Number(obj?.NetRate) * Number(res?.payroll);
                    }
                  }
                }
              }
            }

            if (stateCarrierData && Object.keys(stateCarrierData).length > 0) {
              this.props.setStateCarrierData(stateCarrierData);
            }

            // creating the specific carrierspecific state
            let newObj = {};
            let secondObj = {};
            let newarray = [];
            for (let key of statearray) {
              newarray.push(key.carrier);
            }
            let uniqueCarrier = new Set(newarray);

            for (let key1 of uniqueCarrier) {
              newObj[key1] = [];
              secondObj[key1] = [];
            }
            for (let key of statearray) {
              newObj[key.carrier].push(key.state);
            }
            this.setState({
              carrierSpecific: newObj,
              carrierWseSelectedState: secondObj,
            });
            console.log(this.state.carrierSpecific, "+++carrierSpecific");
            try {
              //Amtrust Premium and Payroll Calculations
              if (
                carrierList_value.includes("carrier_g") ||
                carrierList_label.includes("Amtrust")
              ) {
                let amtrust_state, amtrust_netRates;
                let amtrustNetRateSession = sessionStorage.getItem(
                  "amtrustNetRatesResponse"
                );
                amtrustNetRateSession = JSON.parse(amtrustNetRateSession);
                if (
                  Object.keys(amtrustNetRateSession).length > 0 &&
                  amtrustNetRateSession != "error"
                ) {
                  this.setState({ amtrustError: false });
                  amtrustNetRatesResponse = amtrustNetRateSession;
                  let amtrustresponse = amtrustNetRatesResponse?.Data;
                  let amtrustresNr = amtrustresponse.map((res) => res.NetRate);
                  let amtrustresState = amtrustresponse.map((res) => res.State);

                  let amtrustRes = {};
                  for (let i = 0; i < amtrustresNr.length; i++) {
                    amtrustRes[amtrustresState[i].toLowerCase()] =
                      amtrustresNr[i];
                  }

                  for (let state in amtrustRes) {
                    if (quoteTableData[state]?.["Insurecomp-Amtrust"]) {
                      let amtrustObj =
                        quoteTableData[state]?.["Insurecomp-Amtrust"];
                      amtrustObj.premium =
                        amtrustObj.payroll * amtrustRes[state];
                    }
                  }

                  //Mulitiple carrier Along with Amtrust
                  if (carrierList_label.length > 1) {
                    //Total Amtrust Payroll
                    let totalamtrustPayroll = [];
                    let quoteTable_State = Object.keys(quoteTableData).filter(
                      (x) => x !== "total"
                    );
                    for (let i = 0; i < quoteTable_State.length; i++) {
                      let amtrustPayrollStatewise =
                        quoteTableData[quoteTable_State[i]]?.[
                          "Insurecomp-Amtrust"
                        ]?.["payroll"];
                      totalamtrustPayroll.push(amtrustPayrollStatewise);
                    }
                    //Total premium for amtrust
                    let amtrustPremiumSum = totalamtrustPayroll.reduce(function(
                      sum,
                      element
                    ) {
                      return sum + element;
                    },
                    0);
                    quoteTableData["total"][
                      "Insurecomp-Amtrust"
                    ].payroll = Number(amtrustPremiumSum);

                    //Creating the premium
                    if (
                      Object.keys(amtrustNetRateSession).length > 0 &&
                      amtrustNetRateSession != "error"
                    ) {
                      amtrust_state = amtrustNetRatesResponse.Data.map((ele) =>
                        ele.State.toLowerCase()
                      );
                      amtrust_netRates = amtrustNetRatesResponse.Data.map(
                        (ele) => ele.NetRate
                      );

                      //   // Amtrust every state payroll
                      let totalamtrustPremium = [];
                      for (let i = 0; i < amtrust_state.length; i++) {
                        let amtrustProgram =
                          quoteTableData[amtrust_state[i]]?.[
                            "Insurecomp-Amtrust"
                          ];
                        totalamtrustPremium.push(amtrustProgram["premium"]);
                      }
                      //   //total premium for amtrust
                      let amtrustPremiumSum = totalamtrustPremium.reduce(
                        function(sum, element) {
                          return sum + element;
                        },
                        0
                      );
                      quoteTableData["total"][
                        "Insurecomp-Amtrust"
                      ].premium = Number(amtrustPremiumSum);
                    }
                    //Single Amtrust Carrier only
                  } else if (carrierList_label.length === 1) {
                    let currProspectDetailsValues = Object.values(
                      currProspectDetails?.childrenLoc
                    );
                    let classcodevalue = currProspectDetailsValues.map((obj) =>
                      Object.values(obj.classCodesInfo).map((subObj) =>
                        Number(
                          subObj.payroll.value.split("$")[1].replace(/,/g, "")
                        )
                      )
                    );
                    amtrust_netRates = amtrustNetRatesResponse.Data.map(
                      (ele) => ele.NetRate
                    );
                    amtrust_state = amtrustNetRatesResponse.Data.map((ele) =>
                      ele.State.toLowerCase()
                    );

                    let netState_obj = {};
                    for (let i = 0; i < amtrust_state.length; i++) {
                      netState_obj[amtrust_state[i]] = amtrust_netRates[i];
                    }

                    let totalpayrollLocation = classcodevalue.map((arr) =>
                      arr.reduce(function(sum, element) {
                        return sum + Number(element);
                      }, 0)
                    );
                    let currprospectState = Object.values(
                      currProspectDetails?.childrenLoc
                    ).map((obj) => obj.state.value.toLowerCase());

                    let sessionDataValue = {};
                    for (let i = 0; i < totalpayrollLocation.length; i++) {
                      if (sessionDataValue[currprospectState[i]]) {
                        sessionDataValue[currprospectState[i]] +=
                          totalpayrollLocation[i];
                      } else {
                        sessionDataValue[currprospectState[i]] =
                          totalpayrollLocation[i];
                      }
                    }

                    let current_state = Object.keys(sessionDataValue);
                    let payrollarray = Object.values(sessionDataValue);

                    let payrollstate = {};
                    for (let i = 0; i < payrollarray.length; i++) {
                      payrollstate[current_state[i]] = payrollarray[i];
                    }

                    var totalpermium = 0;

                    for (var key in netState_obj) {
                      if (
                        netState_obj.hasOwnProperty(key) &&
                        payrollstate.hasOwnProperty(key)
                      ) {
                        totalpermium += netState_obj[key] * payrollstate[key];
                      }
                    }

                    let totalPayrollAmtrust = payrollarray.reduce(function(
                      sum,
                      element
                    ) {
                      return sum + Number(element);
                    },
                    0);
                    for (let state of current_state) {
                      quoteTableData[state] = {
                        checked: {
                          name: false,
                          "Insurecomp-Amtrust": false,
                        },
                        "Insurecomp-Amtrust": {
                          premium:
                            netState_obj[state] * sessionDataValue[state],
                          payroll: sessionDataValue[state],
                          state_type: "master",
                        },
                      };

                      quoteTableData["total"] = {
                        checked: {
                          name: false,
                          "Insurecomp-Amtrust": false,
                        },
                        "Insurecomp-Amtrust": {
                          premium: totalpermium,
                          payroll: totalPayrollAmtrust,
                        },
                      };
                    }
                  }
                }
              }
            } catch (err) {
              console.log(
                "Error while Creating the amtrust premium Caluculation",
                err
              );
            }

            // console.log("programs", programs);
            console.log("mwr", masterWithoutFundRateMap);
            // console.log("pst", programStateType);
            // console.log("peoData: ", peoData);
            // console.log("++ccsm", excludedClassCodeMap);

            let programRange = async (headers) => {
              let dateResult;
              await axios
                .post(awsUrl2 + "/api/getProgramData", headers)
                .then((res) => {
                  let apiRes = res.data;
                  if (apiRes) {
                    for (let key in apiRes) {
                      if (apiRes[key] === null) {
                        apiRes[key] = null;
                      } else {
                        apiRes[key.toLocaleLowerCase()] = moment(
                          apiRes[key]
                        ).format("MM-DD-YYYY");
                      }
                    }
                    dateResult = apiRes;
                    this.setState({ programDateMap: dateResult });
                  }
                  console.log("dataapi", dateResult);
                })
                .catch((err) => {
                  console.log("Error:", err);
                });
            };
            //request Body
            const apiResquestArray = programs.map((item) => {
              return {
                carrier: programMapping[item]?.carrier,
                peo: programMapping[item]?.peo,
                program: item,
              };
            });
            console.log("apiBody", apiResquestArray);
            programRange(apiResquestArray);

            if (
              excludedClassCodeMap &&
              Object.keys(excludedClassCodeMap || {}).length > 0
            ) {
              let excludedPopupDataList = [];
              let excludedPopupData = [];
              for (let carrier in excludedClassCodeMap) {
                for (let state in excludedClassCodeMap[carrier]) {
                  for (let classCode of excludedClassCodeMap[carrier][state]) {
                    let value = `${state.toUpperCase()}-${classCode}`;
                    let excludedValue = classCode;
                    if (!excludedPopupDataList.includes(value)) {
                      excludedPopupDataList.push(value);
                      excludedPopupData.push(excludedValue);
                    }
                  }
                }
              }
              let fCCPopupDataList = [];
              let fClassCodeExcludedPopupData = [];
              for (let carrier in fClassCodeList) {
                for (let state in fClassCodeList[carrier]) {
                  for (let classCode of fClassCodeList[carrier][state]) {
                    let value = `${state.toUpperCase()}-${classCode}`;
                    let fccExcludedValue = classCode;
                    if (!fCCPopupDataList.includes(value)) {
                      fCCPopupDataList.push(value);
                      fClassCodeExcludedPopupData.push(fccExcludedValue);
                    }
                  }
                }
              }

              // Return F class codes that are present in excludedPopupData.
              const fClassCodeExcludedValues = fClassCodeExcludedPopupData.map(
                (code) => {
                  if (excludedPopupData.includes(code)) {
                    return code;
                  }
                }
              );

              // Remove values from excludedPopupDataList that are present in fClassCodeList for 3rd condition
              if (fClassCodeExcludedPopupData.length > 0) {
                excludedPopupDataList = excludedPopupDataList.filter((code) => {
                  const [state, classCode] = code.split("-");
                  return !fClassCodeExcludedPopupData.includes(classCode);
                });
              }

              if (fClassCodeExcludedPopupData.length <= 0) {
                this.props.updatePopUpData({
                  show: true,
                  title:
                    "Some class codes have been excluded for certain carriers!",
                  disableNoButton: true,
                  children: (
                    <>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        {excludedPopupDataList.map((e) => (
                          <>
                            <div>{e}</div>
                          </>
                        ))}
                      </span>
                    </>
                  ),
                  acceptBtn: "OK",
                  acceptBtnCallback: () => {
                    console.log("okay");
                  },
                });
              } else if (
                excludedPopupData.length === fClassCodeExcludedValues.length
              ) {
                this.props.updatePopUpData({
                  show: true,
                  title:
                    "Please contact the underwriter to place the client with appropriate carrier as the client is exposed to federal class codes.",
                  disableNoButton: true,
                  children: (
                    <>
                      <span style={{ fontWeight: "bold" }}>
                        Contact : underwriting@assuredpartners.com
                      </span>
                      <br></br>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        {fCCPopupDataList.map((e) => (
                          <>
                            <div>{e}F</div>
                          </>
                        ))}
                      </span>
                    </>
                  ),
                  acceptBtn: "OK",
                  acceptBtnCallback: () => {
                    console.log("okay");
                  },
                });
              } else {
                this.props.updatePopUpData({
                  show: true,
                  title:
                    "Some class codes have been excluded for certain carriers and the client is exposed to federal class code",
                  disableNoButton: true,
                  children: (
                    <>
                      <span style={{ fontWeight: "bold" }}>
                        Please contact Underwriter for furthur details @
                        underwriting@assuredpartners.com
                      </span>
                      <br></br>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        {excludedPopupDataList.map((e) => (
                          <>
                            <div>{e}</div>
                          </>
                        ))}
                        {fCCPopupDataList.map((e) => (
                          <>
                            <div>{e}F</div>
                          </>
                        ))}
                      </span>
                    </>
                  ),
                  acceptBtn: "OK",
                  acceptBtnCallback: () => {
                    console.log("okay");
                  },
                });
              }
            }

            let stateRecalculateObject = {};
            for (let key of states_has_sdr_in_algo) {
              if (sdr_factor && sdr_factor[key.state]) {
                let value = Number(sdr_factor[key.state]);
                stateRecalculateObject[key.state] = {
                  selected: value < 0 ? true : false,
                  value: Math.abs(value),
                };
              } else {
                stateRecalculateObject[key.state] = {
                  selected: false,
                  value: "",
                };
              }
            }

            this.setState({
              quoteTableData,
              programs,
              masterWithoutFundRateMap,
              programStateType,
              peoData,
              startDate: startDate ? moment(startDate, "YYYY-MM-DD") : "",
              historicalClaims: historicalClaims || {},
              historicalClaimsCheck: historicalClaimsCheck || {},
              isNoLossChckd: isNoLossChckd || false,
              selectedPeo: peo_value,
              currentCarrierMap,
              excludedClassCodeMap,
              stateRecalculateObject,
            });

            this.props.setPrograms(programs);
            this.props.setProgramStateType(programStateType);
            this.props.setMasterWithoutFundRateMap(masterWithoutFundRateMap);
            this.props.setPeoData(peoData);
            this.props.setExcludedClassCodeMap(excludedClassCodeMap);
            this.props.setCurrentCarrierMap(currentCarrierMap);
            this.props.setEnableUnderwritingPage(true);
            this.props.setUwNetRates(uw_net_rates || {});
            $("#loader").css("display", "none");
          })
          .catch((err) => {
            console.log("error: ", err);
            showErrAlert(err);
            $("#loader").css("display", "none");
          });
      } else {
        sessionStorage.setItem("formStage", "one");
        deleteAllCookies();
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }

  acordCheckboxHandler = (event, carrier, acordselected) => {
    console.log("acordselected: ", acordselected);
    console.log("carrier: ", carrier);
    let { acordIncludPremium, acordSelectedState } = JSON.parse(
      JSON.stringify(this.state)
    );
    acordIncludPremium[carrier][acordselected] = !acordIncludPremium?.[
      carrier
    ]?.[acordselected];

    this.setState({ acordIncludPremium, acordSelectedState });
  };

  updateAcordSelectedState = async (carrier, state) => {
    let { acordSelectedState } = JSON.parse(JSON.stringify(this.state));
    acordSelectedState[carrier] = state;
    this.setState({ acordSelectedState });
  };

  updateSelectedCarrierMap = async (
    carr,
    val,
    program,
    state,
    state_value,
    selectedStateList
  ) => {
    try {
      let {
        currentCarrierMap,
        selectedPeo,
        show_acord,
        acordSelectedState,
        carrierSpecific,
        loadingAccord,
        quoteTableData,
        acordIncludPremium,
        carrierWseSelectedState,
        fileURL_Map,
        fileURL_Map1,
      } = JSON.parse(JSON.stringify(this.state));

      let ncci_state_list = [];

      if (!selectedStateList) selectedStateList = [];

      carrierWseSelectedState[carr] = selectedStateList;

      currentCarrierMap[carr] = val;

      if (state && program) {
        if (quoteTableData[state][program]) {
          quoteTableData[state].checked[program] = state_value;
        }
      }

      if (!state && program) {
        for (let _state in quoteTableData) {
          if (quoteTableData[_state][program]) {
            quoteTableData[_state].checked[program] = val;
          }
        }
      }

      let ncciStateCheck = false;

      let stateList = carrierSpecific[carr];

      for (let state of selectedStateList) {
        if (!not_include_in_ncci.includes(state)) {
          ncciStateCheck = true;
          ncci_state_list.push(state);
        }
      }

      let acordStateList = [],
        non_ncci_available = false;
      // create a list of states to send to the acord API for the selected carrier

      for (let _state of not_include_in_ncci) {
        if (selectedStateList.includes(_state.toLowerCase())) {
          acordStateList.push(_state.toUpperCase());
          acordSelectedState[carr] = _state.toUpperCase();
          non_ncci_available = true;
        }
      }

      if (ncciStateCheck) {
        acordStateList.push("Ncci");
      }

      // create an object containig the selected carriers
      if (ncciStateCheck) {
        acordSelectedState[carr] = "Ncci";
      } else if (!non_ncci_available && !ncciStateCheck) {
        if (acordSelectedState[carr]) delete acordSelectedState[carr];
      }

      if (!show_acord) show_acord = {};

      if (selectedStateList.length > 0) show_acord[carr] = true;
      else delete show_acord[carr];

      let isLoggedIn = await Auth.currentAuthenticatedUser();
      let ProducerName = JSON.parse(sessionStorage.getItem("currProspect"));

      let producerDetails = {
        // producerName: isLoggedIn.attributes.name || " ",
        producerName:
          ProducerName?.["companyProfile"]?.["producer"]?.["value"] ||
          "Paul Hughes",
        producerEmail: isLoggedIn.attributes.email || " ",
        producerFax: isLoggedIn.attributes["custom:fax"] || " ",
        producerPhone: isLoggedIn.attributes["custom:phoneNo."] || " ",
        producerMobile: isLoggedIn.attributes["custom:mobile"] || " ",
      };
      // console.log(Object.keys(fileURL_Map) == carr, "fileURL_Map");

      loadingAccord[carr] = true;

      this.setState(
        {
          show_acord,
          carrierWseSelectedState,
          selectedStateList,
          currentCarrierMap,
          quoteTableData,
          acordSelectedState,
          loadingAccord,
        },
        async () => {
          if (selectedStateList.length) {
            let promiseList = [];
            // for (let selectedCarrier of selectedCarriers) {
            // load acord for all states of the selected carrier at once

            let stateList = carrierSpecific[carr];
            let acordStateList = [];
            // create a list of states to send to the acord API for the selected carrier
            // if (stateList.includes("fl")) {
            //   acordStateList.push("FL");
            // }
            // if (stateList.includes("ca")) {
            //   acordStateList.push("CA");
            // }
            let stateSet = JSON.parse(sessionStorage.getItem("stateSet"));
            stateList = Object.values(stateSet).map((state) =>
              state.toLowerCase()
            );
            for (let _state of not_include_in_ncci) {
              if (stateList.includes(_state.toLowerCase())) {
                acordStateList.push(_state.toUpperCase());
              }
            }

            if (ncciStateCheck) {
              acordStateList.push("Ncci");
            }

            // for (let state of acordStateList) {
            //   let tempCarrierStateMap = {
            //     [carr]: state,
            //   };

            //   promiseList.push(
            //     this.generateAccordAndStore(
            //       carr,
            //       selectedPeo,
            //       fileURL_Map,
            //       show_acord,
            //       producerDetails,
            //       tempCarrierStateMap,
            //       "false",
            //       selectedStateList,
            //       ncci_state_list.sort().join("_")
            //     )
            //   );
            //   promiseList.push(
            //     this.generateAccordAndStore(
            //       carr,
            //       selectedPeo,
            //       fileURL_Map1,
            //       show_acord,
            //       producerDetails,
            //       tempCarrierStateMap,
            //       "true",
            //       selectedStateList,
            //       ncci_state_list.sort().join("_")
            //     )
            //   );
            // }
            // }

            await Promise.allSettled(promiseList).then((res) => {
              let { loadingAccord, latest_ncci_key_program } = JSON.parse(
                JSON.stringify(this.state)
              );

              for (let row of res) {
                let {
                  key,
                  selectedCarrier,
                  url,
                  showAcord,
                  state,
                  ncci_key,
                  excludeAnnualPremium,
                } = row.value;

                let map_to_use =
                  excludeAnnualPremium === "true" ? fileURL_Map1 : fileURL_Map;

                if (!map_to_use[selectedCarrier]) {
                  map_to_use[selectedCarrier] = {};
                }

                let value_to_use;

                if (state === "Ncci") {
                  value_to_use = map_to_use[selectedCarrier]?.["Ncci"] || {};
                  value_to_use[ncci_key] = url;
                } else value_to_use = url;

                map_to_use[selectedCarrier][state] = value_to_use;

                if (state === "Ncci") {
                  if (ncci_key) latest_ncci_key_program[carr] = ncci_key;
                  else delete latest_ncci_key_program[carr];
                }

                if (excludeAnnualPremium === "true") {
                  fileURL_Map1 = map_to_use;
                  acordIncludPremium[selectedCarrier] = {
                    CA: false,
                    FL: false,
                    Ncci: false,
                  };
                } else fileURL_Map = map_to_use;
              }

              loadingAccord[carr] = false;

              this.setState((prevState, props) => ({
                loadingAccord,
                acordIncludPremium,
                latest_ncci_key_program,
                fileURL_Map: { ...prevState.fileURL_Map, ...fileURL_Map },
                fileURL_Map1: { ...prevState.fileURL_Map1, ...fileURL_Map1 },
              }));
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  getPremium = (employees, netRates) => {
    let premium = 0;
    try {
      for (let employee of employees) {
        let { code, payroll } = employee;
        premium += numeral(netRates[code])
          .multiply(payroll)
          .divide(100)
          .value();
      }
    } catch (error) {
      console.log(error);
    }

    return Math.round(premium);
  };

  open() {
    this.setState({
      visible: true,
    });
  }
  close() {
    this.setState({
      visible: false,
    });
  }
  QuoteView(event) {
    let carrierTemplate = event.target.id.split("#+");
    let carrier = carrierTemplate[0];
    let timestamp = carrierTemplate[1];
    let getQuoteDetails = {};
    let email = JSON.parse(sessionStorage.getItem("currProspect")).emailAddress;
    getQuoteDetails.email = email;
    getQuoteDetails.timestamp = timestamp;
    getQuoteDetails.carrier = carrier;
    Auth.currentSession().then((res) => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(
          awsUrl + "/api/generateQuotePDF",
          JSON.stringify(getQuoteDetails),
          header
        )
        .then((res) => {
          let pdf = res.data.data;
          let fileName = "Quote.pdf";
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);
          let newWindow = window.open("./QuotePDF", "_blank");
          newWindow.onload = () => {
            newWindow.location = fileURL;
          };
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("View Quote is not available due to error");
        });
    });
  }

  // generateAccordAndStore = async (
  //   selectedCarrier,
  //   selectedPeo,
  //   fileURL_Map,
  //   show_acord,
  //   producerDetails,
  //   acordSelectedState,
  //   excludeAnnualPremium,
  //   selectedStateList,
  //   ncci_key
  // ) => {
  //   return new Promise((resolve, reject) => {
  //     let currProspect = sessionStorage.getItem("currProspect")
  //       ? JSON.parse(sessionStorage.getItem("currProspect"))
  //       : undefined;
  //     let address = sessionStorage.getItem("address")
  //       ? JSON.parse(sessionStorage.getItem("address"))
  //       : undefined;
  //     let quoteData = sessionStorage.getItem("quoteData")
  //       ? JSON.parse(sessionStorage.getItem("quoteData"))
  //       : undefined;

  //     console.log("ncci_key: ", ncci_key);

  //     let user_id = sessionStorage.getItem("user_id");

  //     let childrenLoc = currProspect.childrenLoc;
  //     let stateSet = new Set();
  //     for (let childrenLocIndx in childrenLoc) {
  //       let childrenLocObj = childrenLoc[childrenLocIndx];
  //       if (childrenLocObj.state && childrenLocObj.state.value) {
  //         stateSet.add(childrenLocObj.state.value);
  //       }
  //     }
  //     let companyName = currProspect?.companyProfile?.companyName?.value || "";

  //     let actualCarrierName;

  //     for (let row of carrierPeoProgramList) {
  //       if (row.carrier === selectedCarrier) {
  //         actualCarrierName = row.actualCarrierName;
  //         break;
  //       }
  //     }
  //     let key = `${companyName}-${actualCarrierName}-Acord 130`;

  //     if (
  //       currProspect &&
  //       address &&
  //       quoteData &&
  //       actualCarrierName &&
  //       selectedStateList?.length
  //     ) {
  //       let _selectedStateList = [];
  //       let _address = [];

  // for(let state of selectedStateList) {
  //   _selectedStateList.push(state.toUpperCase());
  //   for (let add of address) {
  //     if (add.includes(state.toUpperCase())) {
  //       _address.push(add);
  //     }
  //   }
  // }

  // let carrierListValue = currProspect?.carrierList?.value;
  // let selectedcarrierList = carrierListValue.map((obj) => obj.label);

  // let isAmtrustSelected = selectedcarrierList.includes("Amtrust");

  //       let carrierListValue = currProspect?.carrierList?.value;
  //       let selectedcarrierList = carrierListValue.map((obj) => obj.label);

  //       let isAmtrustSelected = selectedcarrierList.includes("Amtrust");

  //       let etQuoteDetails = {
  //         email: user_id,
  //         timestamp: quoteData.date,
  //         carrier: selectedCarrier,
  //         mail_address: "",
  //         address: address,
  //         state: _selectedStateList,
  //         peo: selectedPeo,
  //         producerDetails,
  //         isAmtrustSelected,
  //       };
  //       if (excludeAnnualPremium === "true") {
  //         etQuoteDetails = { ...etQuoteDetails, excludeAnnualPremium };
  //       }

  //       let ncciStateCheck = false;
  //       for (let key of etQuoteDetails.state) {
  //         if (not_include_in_ncci.includes(key)) {
  //           continue;
  //         } else {
  //           ncciStateCheck = true;
  //         }
  //       }

  //       if (
  //         acordSelectedState[selectedCarrier] !== "CA" &&
  //         acordSelectedState[selectedCarrier] !== "FL" &&
  //         not_include_in_ncci.includes(
  //           acordSelectedState[selectedCarrier].toLowerCase()
  //         ) &&
  //         etQuoteDetails.state.includes(acordSelectedState[selectedCarrier])
  //       ) {
  //         let _state_to_send = acordSelectedState[selectedCarrier],
  //           _address_to_send = [];

  //         for (let add of address) {
  //           if (add.includes(_state_to_send)) {
  //             _address_to_send.push(add);
  //           }
  //         }

  //         etQuoteDetails.address = _address_to_send;
  //         etQuoteDetails.state = [_state_to_send];

  //         if (!fileURL_Map?.[selectedCarrier]?.[_state_to_send]) {
  //           axios
  //             .post(
  //               awsUrl2 + "/api/generateAcordNcci",
  //               JSON.stringify(etQuoteDetails)
  //             )
  //             .then((res) => {
  //               let pdf = res.data.data;
  //               let file = blobPdfFromBase64String(pdf);

  //               var fileURL = URL.createObjectURL(file);

  //               resolve({
  //                 key,
  //                 selectedCarrier,
  //                 url: fileURL,
  //                 showAcord: true,
  //                 excludeAnnualPremium,
  //                 state: _state_to_send,
  //               });
  //             })
  //             .catch((err) => {
  //               console.log("Err", err);
  //               showErrAlert("View Quote is not available due to error.");
  //               fileURL_Map[selectedCarrier] = "";
  //               resolve({
  //                 key,
  //                 selectedCarrier,
  //                 url: "",
  //                 showAcord: false,
  //                 excludeAnnualPremium,
  //               });
  //             });
  //         } else {
  //           resolve({
  //             key,
  //             selectedCarrier,
  //             url: fileURL_Map[selectedCarrier][_state_to_send],
  //             showAcord: true,
  //             state: _state_to_send,
  //             excludeAnnualPremium,
  //           });
  //         }
  //       } else if (
  //         acordSelectedState[selectedCarrier] == "CA" &&
  //         etQuoteDetails.state.includes("CA")
  //       ) {
  //         // with the Total Annaul Premium for CA state

  //         if (!fileURL_Map?.[selectedCarrier]?.["CA"]) {
  //           axios
  //             .post(
  //               awsUrl2 + "/api/generateAcordCA",
  //               JSON.stringify(etQuoteDetails)
  //             )
  //             .then((res) => {
  //               let pdf = res.data.data;
  //               let file = blobPdfFromBase64String(pdf);

  //               var fileURL = URL.createObjectURL(file);

  //               resolve({
  //                 key,
  //                 selectedCarrier,
  //                 url: fileURL,
  //                 showAcord: true,
  //                 excludeAnnualPremium,
  //                 state: "CA",
  //               });
  //             })
  //             .catch((err) => {
  //               console.log("Err", err);
  //               showErrAlert("View Quote is not available due to error.");
  //               fileURL_Map[selectedCarrier] = "";
  //               resolve({
  //                 key,
  //                 selectedCarrier,
  //                 url: "",
  //                 showAcord: false,
  //                 excludeAnnualPremium,
  //               });
  //             });
  //         } else {
  //           resolve({
  //             key,
  //             selectedCarrier,
  //             url: fileURL_Map[selectedCarrier]["CA"],
  //             showAcord: true,
  //             state: "CA",
  //             excludeAnnualPremium,
  //           });
  //         }
  //         console.log("excludeAnnualPremium", excludeAnnualPremium);
  //       } else if (
  //         acordSelectedState[selectedCarrier] == "FL" &&
  //         etQuoteDetails.state.includes("FL")
  //       ) {
  //         // with the Total Annaul Premium for FL state

  //         if (!fileURL_Map?.[selectedCarrier]?.["FL"]) {
  //           axios
  //             .post(
  //               awsUrl2 + "/api/generateAcordFL",
  //               JSON.stringify(etQuoteDetails)
  //             )
  //             .then((res) => {
  //               let pdf = res.data.data;
  //               let file = blobPdfFromBase64String(pdf);

  //               var fileURL = URL.createObjectURL(file);

  //               resolve({
  //                 key,
  //                 selectedCarrier,
  //                 url: fileURL,
  //                 showAcord: true,
  //                 state: "FL",
  //                 excludeAnnualPremium,
  //               });
  //             })
  //             .catch((err) => {
  //               console.log("Err", err);
  //               showErrAlert("View Quote is not available due to error.");
  //               fileURL_Map[selectedCarrier] = "";
  //               resolve({
  //                 key,
  //                 selectedCarrier,
  //                 url: "",
  //                 showAcord: false,
  //                 excludeAnnualPremium,
  //               });
  //             });
  //         } else {
  //           resolve({
  //             key,
  //             selectedCarrier,
  //             url: fileURL_Map[selectedCarrier]["FL"],
  //             showAcord: true,
  //             state: "FL",
  //             excludeAnnualPremium,
  //           });
  //         }
  //       } else if (
  //         ncciStateCheck &&
  //         acordSelectedState[selectedCarrier] == "Ncci"
  //       ) {
  //         if (!fileURL_Map?.[selectedCarrier]?.["Ncci"]?.[ncci_key]) {
  //           let _list_to_send = [];
  //           let _curr_list = etQuoteDetails.state;

  //           for (let st of _curr_list) {
  //             if (!not_include_in_ncci.includes(st.toLowerCase())) {
  //               _list_to_send.push(st);
  //             }
  //           }

  //           etQuoteDetails.state = _list_to_send;

  //           axios
  //             .post(
  //               awsUrl2 + "/api/generateAcordNcci",
  //               JSON.stringify(etQuoteDetails)
  //             )
  //             .then((res) => {
  //               let pdf = res.data.data;
  //               let file = blobPdfFromBase64String(pdf);

  //               var fileURL = URL.createObjectURL(file);

  //               resolve({
  //                 key,
  //                 selectedCarrier,
  //                 url: fileURL,
  //                 showAcord: true,
  //                 state: "Ncci",
  //                 ncci_key,
  //                 excludeAnnualPremium,
  //               });
  //             })
  //             .catch((err) => {
  //               console.log("Err", err);
  //               showErrAlert("View Quote is not available due to error.");
  //               fileURL_Map[selectedCarrier] = "";
  //               resolve({
  //                 key,
  //                 selectedCarrier,
  //                 url: "",
  //                 showAcord: false,
  //                 excludeAnnualPremium,
  //               });
  //             });
  //         } else {
  //           resolve({
  //             key,
  //             selectedCarrier,
  //             url: fileURL_Map[selectedCarrier]["Ncci"][ncci_key],
  //             showAcord: true,
  //             state: "Ncci",
  //             ncci_key,
  //             excludeAnnualPremium,
  //           });
  //         }
  //       } else {
  //         resolve({
  //           key,
  //           selectedCarrier,
  //           url: fileURL_Map[`${companyName}-${actualCarrierName}-Acord 130`],
  //           showAcord: true,
  //         });
  //       }
  //     } else {
  //       resolve({
  //         key,
  //         selectedCarrier,
  //         url: fileURL_Map[`${companyName}-${actualCarrierName}-Acord 130`],
  //         showAcord: true,
  //       });
  //     }
  //   });
  // };

  generateNoLoss = async (date) => {
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let sortKeyList = sessionStorage.getItem("sortKeyList")
      ? JSON.parse(sessionStorage.getItem("sortKeyList"))
      : undefined;

    if (currProspect) {
      this.setState({ loadingNoLoss: true });
      let cName = `${currProspect.companyProfile.firstName.value} ${currProspect.companyProfile.lastName.value}`;
      await axios
        .post(
          awsUrl2 + "/api/getNoLossData",
          JSON.stringify({
            company: currProspect.companyProfile.companyName.value,
            name: cName,
            dFrom: date,
            fein: currProspect.companyProfile.fein.value,
          })
        )
        .then(async (res) => {
          let pdf = res.data.data;
          let file = blobPdfFromBase64String(pdf);

          var fileURL2 = URL.createObjectURL(file);

          // let dataToSend = {
          //   user_email_id: currProspect.companyProfile.emailId.value,
          //   uuid_carrier: sortKeyList[0],
          //   nolossGenerated: true,
          //   dFrom: date,
          // };
          // await axios
          //   .post(awsUrl2 + "/api/updateSolveUserStatusTable", dataToSend)
          //   .then((res) => {})
          //   .catch((error) => {
          //     console.log("error in API-updateSolveUserStatusTable", error);
          //   });
          this.setState({ fileURL2 });
          this.setState({ loadingNoLoss: false });
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("View Quote is not available due to error.");
          this.setState({ loadingNoLoss: false });
        });
    }
  };

  getAllDataWRTCarrier(carrier, data) {
    let calculated_data = "";
    let requireData = {};
    let places = [];
    let teapSum = 0;
    for (let addres in data) {
      requireData["status"] = data[addres].quote_status;
      requireData["timestamp"] = data[addres].date;
      places.push(addres);
      teapSum += Number(data[addres].teap);
      calculated_data = data[addres].calculated_data;
    }

    requireData["places"] = places;
    requireData["teapSum"] = teapSum;
    requireData["calculated_data"] = calculated_data;
    return requireData;
  }

  startFresh() {
    sessionStorage.clear();
    window.location.reload();
  }

  handleBind = async () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
    let domain = sessionStorage.getItem("domain");

    let body = {
      companyName: currProspect.companyProfile.companyName.value,
      date: quoteData.date,
      uuid: quoteData.uuid,
      email: currProspect.companyProfile.emailId.value,
    };

    try {
      let requestBody = {
        domain,
        email: currProspect.companyProfile.emailId.value,
      };

      await axios
        .post(
          awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateProposalStatus",
          JSON.stringify(requestBody)
        )
        .then((res) => {
          console.log("updated proposal count");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }

    sessionStorage.setItem("formStage", "four");
    window.location.reload();

    // axios
    //   .post(awsUrl2 + "/api/sendBindMail", JSON.stringify(body))
    //   .then((response) => {
    //     console.log("bind mail sent successfully");
    //   })
    //   .catch((error) => {
    //     console.log("error while sending bind email.", error);
    //   })
    //   .finally(() => {
    //     try {
    //       let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));
    //       let email = JSON.parse(sessionStorage.getItem("currProspect"))
    //         .companyProfile.emailId.value;

    //       let updateBody = {
    //         sortKeyList,
    //         email,
    //       };

    //       axios
    //         .post(
    //           awsUrl2 + "/api/updateSolveUsersData",
    //           JSON.stringify(updateBody)
    //         )
    //         .then((res) => {
    //           console.log("updated table data successfully");
    //         })
    //         .catch((error) => {
    //           console.log("error", error);
    //         })
    //         .finally(() => {
    //           sessionStorage.setItem("formStage", "four");
    //           window.location.reload();
    //         });
    //     } catch (error) {
    //       console.log("error", error);
    //       sessionStorage.setItem("formStage", "four");
    //       window.location.reload();
    //     }
    //   });
  };

  handleCheckClicked = async () => {
    let { isNoLossChckd } = this.state;
    this.setState({
      isNoLossChckd: !isNoLossChckd,
    });
  };

  handleDateSelected = (date) => {
    this.setState({ startDate: date });
    this.generateNoLoss(date);
  };

  handleTotPaidBlur = (e) => {
    // zeroDefault(e);
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];
    historicalClaims = histClaimCalcTP(historicalClaims, year);
    this.setState({ historicalClaims });
  };

  handleChange = (e, key) => {
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];

    if (!historicalClaims[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }
    this.setState({ historicalClaims });
  };

  handleSave = async () => {
    let { updatePopUpData } = this.props;
    let {
      historicalClaims,
      historicalClaimsCheck,
      isNoLossChckd,
      startDate,
    } = this.state;
    let currProspect = sessionStorage.getItem("currProspect");
    let user_id = sessionStorage.getItem("user_id");
    let address = sessionStorage.getItem("address");
    let quoteData = sessionStorage.getItem("quoteData");
    let _sortKeyList = sessionStorage.getItem("sortKeyList");

    try {
      let isLoggedIn = await Auth.currentAuthenticatedUser();
      let loggedIn_emailID = isLoggedIn.attributes.email;

      currProspect = JSON.parse(currProspect);
      quoteData = JSON.parse(quoteData);
      address = JSON.parse(address);
      try {
        if (startDate) {
          startDate = moment(startDate).format("YYYY-MM-DD");
        } else {
          throw "invalid date";
        }
      } catch (error) {
        startDate = "";
      }

      let requestBody = {
        user_email_id: user_id,
        historicalClaims,
        historicalClaimsCheck,
        isNoLossChckd,
        startDate,
        address,
        timestamp: quoteData.date,
        uuid: quoteData.uuid,
        peo: currProspect.peoDetails.selectedPeo,
      };

      await axios
        .post(awsUrl2 + "/api/updateUserStatusTable", requestBody)
        .then((res) => {})
        .catch((error) => {
          console.log("error in API-updateFourthUserStatusTable", error);
        });

      await axios.post(awsUrl + "/api/updateStatusDetails", {
        button_name: "save_clicked",
        user_id: loggedIn_emailID,
        user_email_id: user_id,
        sort_key_list: JSON.parse(_sortKeyList),
      });
    } catch (error) {
      console.log("error while saving claims info", error);
    } finally {
      updatePopUpData({
        show: true,
        children: (
          <span style={{ fontWeight: "bold" }}>
            Submission saved successfully
          </span>
        ),
        acceptBtn: "Okay",
        disableNoButton: true,
      });
    }
  };

  createTable = () => {
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    if (!currProspectDetails) return [];
    let table = [];
    let j = 0;
    let {
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsErrorStore,
    } = this.state;

    historicalClaimsErrorStore = !historicalClaimsErrorStore
      ? {}
      : historicalClaimsErrorStore;
    historicalClaims = !historicalClaims ? {} : historicalClaims;
    historicalClaimsCheck = !historicalClaimsCheck ? {} : historicalClaimsCheck;

    let yearsInBusiness = Number(
      currProspectDetails.companyProfile.yearsInBusiness.value
    );
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;

    rowIdYear = new Date().getFullYear();
    rowIdDupYear = new Date().getFullYear();
    let historyLimitYear = rowIdYear - yearsInBusiness;

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      // if (historicalClaimsCheck[absYear]) {
      //   backgroundColor = "#dddddd";
      // } else {
      //   backgroundColor = "";
      // }
      rowId = rowId.toString();
      if (i % 2 === 0) {
        j++;
        let yearId = Number(rowId);
        // loss_data_index = loss_data
        //   ? loss_data.findIndex((e) => e.year === rowId)
        //   : null;
        table.push(
          <div className="row">
            <tr id={rowIdDup} key={rowIdDup}>
              <td className="col-4" id="carrier">
                <Form.Group>
                  <label
                    htmlFor={`carrier-${absYear}`}
                    className="font-family-montserrat-semi-bold"
                  >
                    Carrier
                  </label>
                  <input
                    autoComplete="off"
                    id={`carrier-${absYear}`}
                    type="text"
                    name="carText"
                    onChange={(e) => this.handleChange(e, "carrier")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].carrier
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "carrier");
                    }}
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["carrier"]
                        ? historicalClaims[absYear]["carrier"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>
              <td className="col-3" id="Annaulprem">
                <Form.Group>
                  <label
                    htmlFor={`annPre-${absYear}`}
                    title="Annual Premium"
                    // style={{ fontSize: "12px" }}
                    className="font-family-montserrat-semi-bold"
                  >
                    Annual Premium
                  </label>
                  <NumberFormat
                    name="anualPremium"
                    id={`annPre-${absYear}`}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "annPre");
                    }}
                    thousandSeparator={true}
                    onChange={(e) => this.handleChange(e, "annPre")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].annPre
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    prefix={"$"}
                    className="text-center"
                    type="text"
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["annPre"]
                        ? historicalClaims[absYear]["annPre"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>
              <td className="col-3" id="claims">
                <Form.Group>
                  <label
                    title="Number of claims"
                    htmlFor={`claims-${absYear}`}
                    className="font-family-montserrat-semi-bold"
                  >
                    # Claims
                  </label>
                  <NumberFormat
                    name="claims"
                    id={`claims-${absYear}`}
                    maxLength="2"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "claim");
                    }}
                    onChange={(e) => this.handleChange(e, "claim")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].claim
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    className="text-center"
                    type="text"
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["claim"]
                        ? historicalClaims[absYear]["claim"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>
              <td className="col-4" id="totalincurred">
                <Form.Group>
                  <label
                    htmlFor={`totInc-${absYear}`}
                    title="Total Incurred"
                    className="font-family-montserrat-semi-bold"
                  >
                    Total Incurred
                  </label>
                  <NumberFormat
                    onFocus={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "totInc");
                    }}
                    id={`totInc-${absYear}`}
                    autoComplete="off"
                    name="totInc"
                    thousandSeparator={true}
                    onChange={(e) => this.handleChange(e, "totInc")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].totInc
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    prefix={"$"}
                    className="text-center"
                    type="text"
                    // onBlur={(e) => this.zeroDefault(e, "totInc")}
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["totInc"]
                        ? historicalClaims[absYear]["totInc"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>
              {/* <td className="per10">
              <Form.Group>
                <label
                  htmlFor={`totPaid-${absYear}`}
                  title="Total Paid"
                  className="font-family-montserrat-semi-bold"
                >
                  Total Paid
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totPaid");
                  }}
                  onBlur={this.handleTotPaidBlur}
                  id={`totPaid-${absYear}`}
                  autoComplete="off"
                  name="totPaid"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totPaid")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totPaid
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totPaid"]
                      ? historicalClaims[absYear]["totPaid"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per10">
              <Form.Group>
                <label
                  htmlFor={`totRes-${absYear}`}
                  title="Total Reserves"
                  className="font-family-montserrat-semi-bold"
                >
                  Total Reserves
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totRes");
                  }}
                  id={`totRes-${absYear}`}
                  autoComplete="off"
                  name="totRes"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totRes")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totRes
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center "
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totRes"]
                      ? historicalClaims[absYear]["totRes"]
                      : ""
                  }
                />
              </Form.Group>
            </td> */}
            </tr>
          </div>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
            <td className="no-coverage">
              <input
                type="checkbox"
                id={`check-${absYear}`}
                disabled={historyLimitYear > yearId}
                checked={
                  historyLimitYear > yearId || historicalClaimsCheck[absYear]
                    ? historicalClaimsCheck[absYear] || true
                    : false
                }
                onChange={(e) => this.handleCheck(e)}
              />
              No coverage in {yearId}
            </td>
            {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
          </tr>
        );
        absYear--;
      }
    }
    return table;
  };

  setSelectedCarrier = (val) => {
    let { selectedCarrier } = this.state;
    if (selectedCarrier !== val) {
      this.setState({ selectedCarrier: val }, () => {
        // this.generateAccordAndStore();
      });
    }
  };

  updateEmodInfo = (state, fieldName, value) => {
    let { emodStatesData } = this.state;
    let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
    if (dataFieldNames.includes(fieldName)) {
      emodStatesData[state][fieldName] = {
        value: value,
      };
    } else {
      emodStatesData[state][fieldName] = value;
    }

    this.setState({ emodStatesData });
  };

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");
  }

  submitToUnderWriter = async () => {
    try {
      $("#loader").css("display", "block");
      let isLoggedIn = await Auth.currentAuthenticatedUser();
      let peotype = isLoggedIn?.attributes["custom:user_type"]
        ? isLoggedIn?.attributes["custom:user_type"]
        : "";
      let loggedIn_emailID = isLoggedIn.attributes.email;

      let address = JSON.parse(sessionStorage.getItem("address"));
      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );
      let _sortKeyList = sessionStorage.getItem("sortKeyList");
      let user_id = sessionStorage.getItem("user_id");
      let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
      let amtrustAccountDetails = JSON.parse(
        sessionStorage.getItem("amtrustAccountDetails")
      );
      let amtrustAccountDetailsCopy = JSON.parse(
        sessionStorage.getItem("amtrustAccountDetailsCopy")
      );

      let req_body_userstatus = {
        user_email_id: user_id,
        quoteSubmitted: "true",
        submissionDate: moment().format("x"),
        address: address,
        timestamp: quoteData.date,
        uuid: quoteData.uuid,
        peo:
          currProspectDetails.peoDetails.selectedPeo === "demo"
            ? "gms"
            : currProspectDetails.peoDetails.selectedPeo,
        amtrustAccountDetails,
        amtrustAccountDetailsCopy,
      };

      let req_body_users = {
        user_email_id: user_id,
        quoteSubmitted: "true",
        submissionDate: moment().format("x"),
      };

      let result1 = saveInUserstatusTable(req_body_userstatus);
      let result2 = saveInUserTable(req_body_users);

      Promise.all([result1, result2])
        .then(([result1, result2]) => {})
        .catch((err) => {
          console.log(err);
        });

      await axios.post(awsUrl + "/api/updateStatusDetails", {
        button_name: "submit_to_underwriter",
        user_id: loggedIn_emailID,
        user_email_id: user_id,
        sortKeyList: JSON.parse(_sortKeyList),
      });

      // remove the Uw_release from icomp2usertable.
      let req_body = {
        user_email_id: user_id,
      };
      await this.removeUwReleasedFlagIfPresent(req_body);
      sessionStorage.setItem("hideButtons", "true");
      if (peotype) {
        let companyName =
          currProspectDetails?.companyProfile?.companyName?.value;
        let peoName = this.toCamelCase(
          currProspectDetails?.peoDetails?.selectedPeo
        );
        let req_obj = {
          to: [],
          cc: [],
          subject: `Submission - ${peoName} - ${companyName} `,
          message:
            "Hi, \nA submission has been created. Please visit the portal to review the submission details..\n\nThanks\nInsureComp",
        };
        if (process.env.REACT_APP_MODE === "prod") {
          req_obj.to = [
            "kevin.williams@accretive-ins.com",
            "jennifer.bush@ballator.com ",
            "workcomp@accretive-ins.com",
            "info.trigger@insurecomp.com",
          ];
          await axios
            .post(
              "https://n8pm5lcvtb.execute-api.us-east-1.amazonaws.com/prod/api/sendEmail",
              req_obj
            )
            .then((res) => {
              console.log("Email Sent Succesfully");
            })
            .catch((err) => {
              console.log("Error in sending Email:", err);
            });
        } else {
          req_obj.to = ["info.trigger@insurecomp.com"];
          await axios
            .post(
              "https://2j7w7a8hw7.execute-api.us-east-1.amazonaws.com/dev/api/sendEmail",
              req_obj
            )
            .then((res) => {
              console.log("Email Sent Succesfully");
            })
            .catch((err) => {
              console.log("Error in sending Email:", err);
            });
        }
      }
      this.setState({ quoteSubmitted: true });
      $("#loader").css("display", "none");
    } catch (error) {
      console.log("error: ", error);
      $("#loader").css("display", "none");
    }
  };
  toggleSwitchHandler = async (checked) => {
    this.setState({ toggleLoader: true });
    let value = checked === true ? "true" : "false";

    try {
      let isLoggedIn = await Auth.currentAuthenticatedUser();
      let loggedIn_emailID = isLoggedIn.attributes.email;

      let address = JSON.parse(sessionStorage.getItem("address"));
      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );

      let _sortKeyList = sessionStorage.getItem("sortKeyList");

      let user_id = sessionStorage.getItem("user_id");
      let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));

      let req_body_userstatus = {
        user_email_id: user_id,
        real_submission: value,
        address: address,
        timestamp: quoteData.date,
        uuid: quoteData.uuid,
        peo: currProspectDetails.peoDetails.selectedPeo,
      };

      let req_body_users = {
        user_email_id: user_id,
        real_submission: value,
        submissionDate: moment().format("x"),
      };

      let result1 = saveInUserstatusTable(req_body_userstatus);
      let result2 = saveInUserTable(req_body_users);

      Promise.all([result1, result2])
        .then(([result1, result2]) => {})
        .catch((err) => {
          console.log(err);
        });

      await axios.post(awsUrl + "/api/updateStatusDetails", {
        button_name: "submission_switch",
        user_id: loggedIn_emailID,
        user_email_id: user_id,
        sortKeyList: JSON.parse(_sortKeyList),
      });

      this.setState({ toggleSwitchCheck: checked, toggleLoader: false });
    } catch (error) {
      this.setState({ toggleLoader: false });
      console.log("error: ", error);
    }
  };

  removeUwReleasedFlagIfPresent = async (requestBody) => {
    new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/userTableData/removeUwReleased", requestBody)
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          console.log("error: ", err);
          reject();
        });
    });
  };
  amtrustQuoteBind = async () => {
    //amtrust bind api call
    let response = true;
    let { salespersonCheck } = this.state;
    try {
      let amtrustAccountDetails = sessionStorage.getItem(
        "amtrustAccountDetails"
      );

      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);

      let user_id = sessionStorage.getItem("user_id");
      let salesID = sessionStorage.getItem("salesID");
      let currProspect = sessionStorage.getItem("currProspect");
      let amtrustEligibility = sessionStorage.getItem("AmtrustEligibility");
      let eligibilityStatus = JSON.parse(amtrustEligibility)?.Eligibility;

      let amtrustTriggerResponse = await axios.post(
        awsUrl2 + `/api/triggerAmtrustBindQuote`,
        JSON.stringify({
          quoteId: amtrustAccountDetails.QuoteId,
          amtrustPaygoEligibility: eligibilityStatus, /// need to ask aachman on this part for whole body
          user_email_id: user_id
            ? user_id
            : salespersonCheck && salesID
            ? salesID.toLowerCase()
            : currProspect.companyProfile.companyName &&
              currProspect.companyProfile.companyName.value
            ? currProspect.companyProfile.companyName.value.toLowerCase()
            : "",
          sortKeyList: sessionStorage.getItem("sortKeyList")
            ? JSON.parse(sessionStorage.getItem("sortKeyList"))
            : undefined,
        })
      );
      // await axios.post(awsUrl2 + `/api/triggerAmtrustBindQuote`);

      // let amtrustTriggerResponse;
      let uuid;
      if (amtrustTriggerResponse.data.uuid) {
        uuid = amtrustTriggerResponse.data.uuid;
      } else {
        throw "error in amtrust create quote trigger";
      }

      let amtrustQuoteBindResponse;
      do {
        amtrustQuoteBindResponse = await amtrustResponse(
          uuid,
          "getBindQuoteResponse"
        );

        if (amtrustQuoteBindResponse === "error") {
          throw "error in amtrust quote binding";
        } else if (
          amtrustQuoteBindResponse &&
          amtrustQuoteBindResponse !== "processing"
        ) {
          amtrustAccountDetails = {
            ...amtrustAccountDetails,
            BindData: amtrustQuoteBindResponse,
          };
        }
      } while (amtrustQuoteBindResponse === "processing");
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(amtrustAccountDetails)
      );
    } catch (error) {
      console.log(error);
      response = false;
    }
    return response;
  };

  downloadEAcord = async (acordType) => {
    try {
      let eAcordPdf;
      let currProspect = sessionStorage.getItem("currProspect")
        ? JSON.parse(sessionStorage.getItem("currProspect"))
        : undefined;
      let address = sessionStorage.getItem("address")
        ? JSON.parse(sessionStorage.getItem("address"))
        : undefined;
      let quoteData = sessionStorage.getItem("quoteData")
        ? JSON.parse(sessionStorage.getItem("quoteData"))
        : undefined;
      let isLoggedIn;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log("Authentication error here", error);
      }
      let ProducerName = JSON.parse(sessionStorage.getItem("currProspect"));

      let producerDetails = {
        producerName:
          ProducerName?.["companyProfile"]?.["producer"]?.["value"] ||
          "Paul Hughes",
        producerEmail: isLoggedIn.attributes.email || " ",
        producerFax: isLoggedIn.attributes["custom:fax"] || " ",
        producerPhone: isLoggedIn.attributes["custom:phoneNo."] || " ",
        producerMobile: isLoggedIn.attributes["custom:mobile"] || " ",
      };
      for (let carrier in this.state.carrierWseSelectedState) {
        let stateList = [];
        if (acordType == "NCCI") {
          stateList = this.state.carrierWseSelectedState[carrier]
            .filter((state) => state !== "ca" && state !== "fl")
            .map((state) => state.toUpperCase());
        } else if (
          this.state.carrierWseSelectedState[carrier].includes("ca") &&
          acordType == "CA"
        ) {
          stateList = ["CA"];
        } else if (
          this.state.carrierWseSelectedState[carrier].includes("fl") &&
          acordType == "FL"
        ) {
          stateList = ["FL"];
        }
        if (stateList.length) {
          let etQuoteDetails = {
            email: sessionStorage.getItem("user_id"),
            timestamp: quoteData.date,
            carrier,
            mail_address: "",
            address: address,
            state: stateList,
            peo: this.state.selectedPeo,
            producerDetails,
          };
          axios
            .post(
              awsUrl2 + `/api/generateEAcord${acordType}`,
              JSON.stringify(etQuoteDetails)
            )
            .then(async (res) => {
              const filename = `editable_acord_${acordType}_${carrier}.pdf`;
              if (res.data.startsWith("http")) {
                const signedUrl = res.data;
                const response = await fetch(signedUrl);
                const blob = await response.blob();

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                console.log("signedUrl", signedUrl);
                await axios.delete(
                  awsUrl2 + `/api/deleteEAcord?url=${signedUrl}`
                );
              } else {
                eAcordPdf = res.data;
                const blob = this.base64ToBlob(eAcordPdf, "application/pdf");
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `editable_acord_${acordType}_${carrier}.pdf`;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
              }
              if (acordType == "CA") this.setState({ loadingEAcordCA: false });
              if (acordType == "NCCI")
                this.setState({ loadingEAcordNCCI: false });
              if (acordType == "FL") this.setState({ loadingEAcordFL: false });
            });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to download editable Acord. Please try again later.");
    }
  };

  base64ToBlob = (base64Data, contentType) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  render() {
    let { updatePopUpData } = this.props;
    let calculated_data = "";
    let {
      fileURL_Map,
      fileList,
      quoteStatus,
      isNoLossChckd,
      fileURL2,
      loadingNoLoss,
      startDate,
      peoType,
      historicalClaimsError,
      quoteTableData,
      programs,
      emodStatesData,
      masterWithoutFundRateMap,
      programStateType,
      peoData,
      currentCarrierMap,
      loadingAccord,
      show_acord,
      excludedClassCodeMap,
      acordSelectedState,
      carrierSpecific,
      stateRecalculateObject,
      quoteSubmitted,
      hasRecalGroup,
      fileURL_Map1,
      acordIncludPremium,
      carrierWseSelectedState,
      latest_ncci_key_program,
      toggleSwitchCheck,
      toggleLoader,
      uwLogin,
      programDateMap,
      amtrustError,
    } = this.state;
    const table = this.createTable();

    let tabList = [];

    let _quoteSubmitted = quoteSubmitted ? true : false;

    if (isNoLossChckd && fileURL2 && startDate) {
      tabList.push({
        tabName: "No Loss",
        children: (
          <iframe
            id={`iframeNoLoss`}
            src={fileURL2 || "about:blank" + "#toolbar=0"}
            style={{ border: "none", height: "645px" }}
            width="100%"
          ></iframe>
        ),
      });
    }

    for (let carr in show_acord) {
      let fileURL,
        state_shown,
        non_ncci_used = false;

      for (let _state of not_include_in_ncci) {
        let __state = _state.toUpperCase();
        if (acordSelectedState?.[carr] === __state) {
          state_shown = __state;
          non_ncci_used = true;
          if (acordIncludPremium?.[carr]?.[__state]) {
            fileURL = fileURL_Map1?.[carr]?.[__state];
          } else {
            fileURL = fileURL_Map?.[carr]?.[__state];
          }
        }
      }

      if (non_ncci_used === true) {
      } else if (
        latest_ncci_key_program?.[carr] &&
        fileURL_Map?.[carr]?.["Ncci"]?.[latest_ncci_key_program[carr]]
      ) {
        state_shown = "Ncci";
        if (acordIncludPremium?.[carr]?.["Ncci"]) {
          fileURL = fileURL_Map1[carr]["Ncci"][latest_ncci_key_program[carr]];
        } else {
          fileURL = fileURL_Map[carr]["Ncci"][latest_ncci_key_program[carr]];
        }
      }

      // let { fileURL, state_shown } =
      //   acordSelectedState?.[carr] == "CA"
      //     ? { fileURL: fileURL_Map?.[carr]?.["CA"], state_shown: "CA" }
      //     : acordSelectedState?.[carr] == "FL"
      //       ? { fileURL: fileURL_Map?.[carr]?.["FL"], state_shown: "FL" }
      //       : (
      //         latest_ncci_key_program?.[carr] && fileURL_Map?.[carr]?.["Ncci"]?.[latest_ncci_key_program[carr]] ?
      //           { fileURL: fileURL_Map[carr]["Ncci"][latest_ncci_key_program[carr]], state_shown: "Ncci" } : { fileURL: "", state_shown: "" }
      //       );

      if (fileURL)
        tabList.push({
          tabName: carr,
          state_shown: state_shown,
          children: (
            <div
              className={
                isMobile ? "accord-container-mobile" : "accord-container"
              }
            >
              <div
                className={
                  isMobile ? "accord-toolbar-mobile" : "accord-toolbar"
                }
              >
                {isMobile ? (
                  ""
                ) : (
                  <a href={fileURL} download={carr} title={`Download: ${carr}`}>
                    <img
                      height="30"
                      src={require("../../../images/download-file.png")}
                    ></img>
                  </a>
                )}
                <div className="ml-2">
                  <label>
                    <input
                      type="checkbox"
                      checked={
                        this.state.acordIncludPremium?.[carr]?.[
                          acordSelectedState?.[carr]
                        ]
                      }
                      onChange={(event) =>
                        this.acordCheckboxHandler(
                          event,
                          carr,
                          acordSelectedState?.[carr]
                        )
                      }
                    />
                  </label>
                  <b style={{ fontSize: "13px" }}>
                    Include Premium and Carrier Name
                  </b>
                </div>
                {isMobile ? (
                  <a
                    href={fileURL}
                    download={carr}
                    title={`Download: ${carr}`}
                    style={{ paddingLeft: "2rem", paddingTop: "15px" }}
                  >
                    <img
                      height="30"
                      src={require("../../../images/download-file.png")}
                    ></img>
                    <span style={{ color: "white" }}>
                      {isMobile ? "Download Acord" : ""}
                    </span>
                  </a>
                ) : (
                  ""
                )}
              </div>
              {isMobile ? (
                ""
              ) : (
                <iframe
                  id={`iframeAccord`}
                  src={fileURL || "about:blank" + "#toolbar=0"}
                  style={{ border: "none", height: "645px" }}
                  width="100%"
                ></iframe>
              )}
            </div>
          ),
        });
    }

    if (this.state.data !== undefined && this.state.data !== null) {
      for (let carrier in this.state.data) {
        let requireData = this.getAllDataWRTCarrier(
          carrier,
          this.state.data[carrier]
        );
        calculated_data = requireData.calculated_data;
      }
    }

    if (fileList === undefined || fileList === null) {
      fileList = {
        first: {},
        second: {},
      };
    }
    let randNum = Math.round(Math.random() * 10);

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;
    let hideUwButton = sessionStorage.getItem("uw_flow") ? true : false;

    return (
      <div id="cmprQts" className="container-fluid per98">
        <NewProgress />
        <div id="compareQuote">
          <QuoteTable
            quoteTableData={quoteTableData}
            programs={programs}
            masterWithoutFundRateMap={masterWithoutFundRateMap}
            programStateType={programStateType}
            peoData={peoData}
            updateSelectedCarrierMap={this.updateSelectedCarrierMap}
            excludedClassCodeMap={excludedClassCodeMap}
            currentCarrierMap={currentCarrierMap}
            acordSelectedState={acordSelectedState}
            uwLogin={uwLogin}
            programDateMap={programDateMap}
            amtrustError={amtrustError}
            // dataFromAPI={this.state.dataFromAPI}
            // data={this.state.data}
            // selectedCarrier={this.state.selectedCarrier}
            // setSelectedCarrier={this.setSelectedCarrier}
          />

          {quoteTableData && !hideUwButton && (
            <>
              <div className="submit-to-underwriter mt-3 mb-3">
                <div className="submitUW">
                  <button
                    className="btn btn-warning"
                    onClick={(e) => {
                      if (!_quoteSubmitted) {
                        updatePopUpData({
                          show: true,
                          title: <b>{"Do you wish to continue?"}</b>,
                          children: (
                            <>
                              {/* <br></br>
                          <span style={{ fontWeight: "bold" }}>
                          
                        </span> */}
                            </>
                          ),
                          acceptBtn: "Yes",
                          acceptBtnCallback: async () => {
                            await this.submitToUnderWriter();
                          },
                        });
                      }
                    }}
                    disabled={_quoteSubmitted}
                  >
                    Submit to Underwriter
                  </button>
                </div>
                <div className="d-flex">
                  <span className="align-item-left">
                    <label>
                      <b>{`Submission ?`}&nbsp;</b>
                    </label>
                    <ToggleSwitch
                      id="quoteTableToggle"
                      toggleSwitchCheck={toggleSwitchCheck}
                      toggleSwitchHandler={this.toggleSwitchHandler}
                    />
                  </span>
                  {toggleLoader ? <SmallLoader /> : ""}
                </div>
              </div>
              {/* <div className="eAcordButtons">
                {this.state.acordSelectedState &&
                  Object.values(this.state.acordSelectedState).includes(
                    "Ncci"
                  ) && (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        this.downloadEAcord("NCCI");
                        this.setState({ loadingEAcordNCCI: true });
                      }}
                    >
                      {this.state.loadingEAcordNCCI
                        ? "Downloading NCCI EAcord..."
                        : "Download NCCI EAcord"}
                    </button>
                  )}
                {String(
                  Object.values(this.state.carrierWseSelectedState)
                ).includes("ca") && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      this.downloadEAcord("CA");
                      this.setState({ loadingEAcordCA: true });
                    }}
                  >
                    {this.state.loadingEAcordCA
                      ? "Downloading CA EAcord..."
                      : "Download CA EAcord"}
                  </button>
                )}
                {String(
                  Object.values(this.state.carrierWseSelectedState)
                ).includes("fl") && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      this.downloadEAcord("FL");
                      this.setState({ loadingEAcordFL: true });
                    }}
                  >
                    {this.state.loadingEAcordFL
                      ? "Downloading FL EAcord..."
                      : "Download FL EAcord"}
                  </button>
                )}
              </div>{" "} */}
            </>
          )}
          {quoteTableData && (
            <div className="eAcordButtons">
              <div>
                {this.state.acordSelectedState &&
                  Object.values(this.state.acordSelectedState).includes(
                    "Ncci"
                  ) && (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        this.downloadEAcord("NCCI");
                        this.setState({ loadingEAcordNCCI: true });
                      }}
                    >
                      {this.state.loadingEAcordNCCI
                        ? "Downloading NCCI EAcord..."
                        : "Download NCCI EAcord"}
                    </button>
                  )}
              </div>
              <div>
                {String(
                  Object.values(this.state.carrierWseSelectedState)
                ).includes("ca") && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      this.downloadEAcord("CA");
                      this.setState({ loadingEAcordCA: true });
                    }}
                  >
                    {this.state.loadingEAcordCA
                      ? "Downloading CA EAcord..."
                      : "Download CA EAcord"}
                  </button>
                )}
              </div>
              <div>
                {String(
                  Object.values(this.state.carrierWseSelectedState)
                ).includes("fl") && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      this.downloadEAcord("FL");
                      this.setState({ loadingEAcordFL: true });
                    }}
                  >
                    {this.state.loadingEAcordFL
                      ? "Downloading FL EAcord..."
                      : "Download FL EAcord"}
                  </button>
                )}
              </div>
            </div>
          )}
          {/* {Object.keys(stateRecalculateObject).length > 0 && hasRecalGroup && (
            <RecalculateQuote stateRecalculateObject={stateRecalculateObject} />
          )} */}
          <div className="mt-5">
            <div
              className="pink-header font-family-montserrat-bold"
              style={{ fontSize: "x-large" }}
            >
              Please provide the following information to get bindable quote
            </div>
            <DropZones
              updatePopUpData={updatePopUpData}
              fileList={fileList}
              fileKeyName="fileList"
              displayArray={[true, true, false]}
            />
          </div>
          <div className="d-flex mt-4 p-1 historical_no_claims">
            <input
              className="mr-1"
              type="checkbox"
              id="chck-1"
              name={"chck"}
              checked={isNoLossChckd}
              disabled={loadingNoLoss || quoteStatus === "proceed_uw"}
              onClick={this.handleCheckClicked}
            />
            <label for="chck-1">No historical claims</label>
          </div>
          <div id="datepicker-startDate">
            {isNoLossChckd && (
              <>
                <label
                  for="startDate"
                  title="Business start date"
                  className="mr-2 "
                >
                  Business Start Date
                </label>
                <span id="startDate">
                  <DatePicker
                    selected={startDate}
                    onChange={this.handleDateSelected}
                    maxDate={moment()}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    disabled={quoteStatus === "proceed_uw"}
                  />
                </span>
              </>
            )}
          </div>
          {!isNoLossChckd && (
            <div className="formTitle historical-claims mb-3">
              <h4 className="history-header">
                <b className="font-family-montserrat-bold">
                  Historical claims information
                </b>
              </h4>
            </div>
          )}
          {!isNoLossChckd && (
            <div className="row historical-claims-container container ">
              {historicalClaimsError && (
                <p id="claims-error" className="text-danger">
                  Claims submitted should be for consecutive years!
                </p>
              )}
              {/* <hr /> */}
              <div className="row grey-table-row">
                <table id="claimTable" className="grey-table">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>{table}</tbody>
                </table>
              </div>
            </div>
          )}
          {/* <div className="emod-ques mb-3">
            {Object.values(emodStatesData).length > 0 && (
              <h4 className="emod-ques-heading font-family-montserrat-bold mb-3">
                <b>Experience Modification</b>
              </h4>
            )}
            {Object.keys(emodStatesData).map((e) => {
              return (
                <EmodQuestions
                  key={e}
                  state={e}
                  emodData={emodStatesData[e]}
                  updateEmodInfo={this.updateEmodInfo}
                />
              );
            })}
          </div> */}

          {tabList.length > 0 && (
            <div className="mt-2">
              <div className="row no-gutters d-flex justify-content-end w-100">
                {/* <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    if (document.getElementById("iframeAccord"))
                      document
                        .getElementById("iframeAccord")
                        .contentWindow.location.reload();

                    if (document.getElementById("iframeNoLoss"))
                      document
                        .getElementById("iframeNoLoss")
                        .contentWindow.location.reload();
                  }}
                >
                  {isMobile ? "" : "Refresh"}
                </button>
                <Tabs
                  updateAcordSelectedState={this.updateAcordSelectedState}
                  carrierSpecific={carrierWseSelectedState}
                  tabList={tabList}
                  loadingAccord={loadingAccord}
                  not_include_in_ncci={not_include_in_ncci}
                  className="mt-4"
                /> */}
              </div>
            </div>
          )}
          <div className="row mt-5 justify-content-center">
            <div className="d-flex flex-column">
              <button
                onClick={(e) => {
                  if (!hideButtons) this.handleSave();
                }}
                type="button"
                className="align-self-center btn btn-warning btn-lg btn-warning-shadow proceed-bind"
                disabled={hideButtons}
              >
                Save
              </button>
            </div>
          </div>
          {/* <div className="mt-5">
            <p>
              *This is a Pricing Indication only, and subject to detailed
              underwriting review. Our Account Executive will get back to you
              within 24 hours with next steps. Thank you for your business.
            </p>
          </div> */}
          {/*{this.state.data !== undefined && this.state.data !== null && (
            <div>
              {!this.state.visible && (
                <button type="button" onClick={this.open} className="btnGen">
                  Open
                </button>
              )}
              {this.state.visible && (
                <button type="button" onClick={this.close} className="btnGen">
                  Close
                </button>
              )}
              {this.state.visible && (
                <div>
                  <pre id="json">
                    {JSON.stringify(calculated_data, undefined, 10)}
                  </pre>
                </div>
              )}
            </div>
          )} */}
          <div> </div>
        </div>
      </div>
    );
  }
}
export default function FormCmprQuoteWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => (
        <FormCmprQuote
          updatePopUpData={props.updatePopUpData}
          setPerStateQuoteTableData={context.setPerStateQuoteTableData}
          setStateCarrierData={context.setStateCarrierData}
          setPrograms={context.setPrograms}
          setMasterWithoutFundRateMap={context.setMasterWithoutFundRateMap}
          setProgramStateType={context.setProgramStateType}
          setPeoData={context.setPeoData}
          setExcludedClassCodeMap={context.setExcludedClassCodeMap}
          setCurrentCarrierMap={context.setCurrentCarrierMap}
          setEnableUnderwritingPage={props.setEnableUnderwritingPage}
          setUwNetRates={context.setUwNetRates}
          setUwFileList={context.setUwFileList}
          setCarrierBasedData={context.setCarrierBasedData}
        />
      )}
    </LibertateContext.Consumer>
  );
}
