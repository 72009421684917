import React, { FC, useEffect, useState } from "react";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { awsUrl2 } from "../../config";
import {
  ClassCodesDto,
  NewRatesDto,
  GetsRatesRequestDto,
  ProgramsList,
  separator,
  EmodDataDto,
  __debounce,
  __promise_options,
} from "./NewRatesDtos";
import { SelectOptions, IClassCodeDropDownProps } from "./NewRatesModel";

export const ClassCodeDropDown = (props: IClassCodeDropDownProps) => {
  let [defaultOptions, setDefaultOptions] = useState<Array<any>>([]);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [emodStatesData, setEmodStatesData] = useState<EmodDataDto>({});

  const ismobile = window.screen.width < 1250;

  let debounce: __debounce = (fun: Function) => {
    let timeout: NodeJS.Timeout | null;
    return function (this: any, ...args: Parameters<any>) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        fun.apply(context, args);
      }, 800);
    };
  };

  const promiseOptions: __promise_options = async (
    inputValue: string,
    callback: any
  ) => {
    let stateIndex = props.stateIndex;
    let response = await getClassCodes(
      props.newRatesModel.states[stateIndex].state.value,
      inputValue
    );
    const options = new ClassCodesDto().getOptions(response);
    return callback(options);
  };

  const onClassCodeChange = async (
    value: SingleValue<SelectOptions>,
    stateIndex: number,
    classCodeindex: number
  ) => {
    console.log("class code value:");
    let classCode = (value as SelectOptions).value;
    let description = (value as SelectOptions).label
      .split(":")
      .slice(1)
      .join(":")
      .trim();
    let eligibility = (value as SelectOptions).eligibility;

    let state = props.newRatesModel.states;
    let currentState = props.newRatesModel.states[stateIndex]["state"]["value"];
    let eligibilityData =
      JSON.parse(JSON.stringify(props.carrierEligibility)) || {};
    if (value) {
      state[stateIndex].classCodes[classCodeindex].classCode = value;
      props.setNewRatesModel((prevState) => ({
        programs: prevState.programs,
        states: state,
      }));
      let key = classCode + ": " + description;
      if (currentState) {
        if (!(currentState in eligibilityData))
          eligibilityData[currentState] = {};
        eligibilityData[currentState][key] = eligibility;
      }
      props.setCarrierEligibility(eligibilityData);
    }

    if (classCode)
      await getRates(stateIndex, classCode, description).catch((error) =>
        console.log("error in getting rates", error)
      );
  };

  const getClassCodes = async (state: string | undefined, value: string) => {
    console.log("get class codes..", state, value);
    let _classCodes: ClassCodesDto[] = [];

    let valueLengthThreshold;
    try {
      valueLengthThreshold = isNaN(Number(value)) ? 3 : 1;
    } catch (error) {
      valueLengthThreshold = 3;
    }
    if (value.length >= valueLengthThreshold) {
      const response = await axios.get(
        awsUrl2 + "/api/getCcodes/" + state?.toLocaleLowerCase() + "/" + value
      );
      if (response && response.status == 200 && response.data) {
        for (let _data of response.data.data) {
          _classCodes.push(
            new ClassCodesDto(
              _data.class_code,
              _data.description,
              _data.eligibility
            )
          );
        }
      }
    }
    return _classCodes;
  };

  const getRates = (
    stateIndex: number,
    classCode: string,
    description: string
  ) => {
    return new Promise(async (accept, reject) => {
      try {
        console.log(props.selectedProgram);
        let _tempSelecedProgram = props.selectedProgram
          ? JSON.parse(JSON.stringify(props.selectedProgram))
          : null;
        let indx = 0;
        if (_tempSelecedProgram) {
          for (let _data of _tempSelecedProgram) {
            if (_data.value === "all") break;
            indx++;
          }
          _tempSelecedProgram.splice(indx, 1);
        }
        var request = new GetsRatesRequestDto(
          props.newRatesModel.states[stateIndex].state.value.toLowerCase(),
          props.effectiveDate,
          classCode,
          description,
          new ProgramsList().getPrograms(_tempSelecedProgram),
          emodStatesData
        );
        console.log("get rates..", request);
        const response = await axios.post(
          awsUrl2 + "/api/getNetRateAndManualrate",
          request
        );
        console.log("rates...", response.data);
        console.log("selected programs...", _tempSelecedProgram);
        if (response && response.data && Object.keys(response.data).length) {
          console.log("new rates...", props.newRatesModel);
          const _state = props.newRatesModel.states;
          if (_tempSelecedProgram) {
            props.newRatesModel.states.forEach((itemState, stateIndex) => {
              if (
                itemState.state.value.toLowerCase() ==
                request.state.toLowerCase()
              ) {
                props.newRatesModel.states[stateIndex].classCodes.forEach(
                  (item, ratesIndex) => {
                    if (item.classCode.value === request.classCode) {
                      let _newRatesResponse: NewRatesDto[] = [];
                      for (let _data of _tempSelecedProgram) {
                        let _splitvalue = _data.value.split(separator);
                        let _responseData =
                          response.data[_splitvalue[0]][_splitvalue[1]];
                        let _newRates = new NewRatesDto();
                        _newRates.program = _data.label;
                        _newRates.manualRate = _responseData["manualRate"];
                        _newRates.netRate = _responseData["netRate"];
                        _newRates.fundRate = _responseData["fundRate"];
                        _newRatesResponse.push(_newRates);
                      }
                      _state[stateIndex].classCodes[
                        ratesIndex
                      ].newRatesResponse = _newRatesResponse;
                    }
                  }
                );
              }
            });
          }
          console.log("new rates updating...");
          props.setNewRatesModel((prevState) => ({
            programs: prevState.programs,
            states: _state,
          }));
          accept("");
        } else {
          reject("error in getting rates");
        }
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  useEffect(() => {
    setDefaultOptions([]);
  }, [props.classcode.classCode]);

  const onMenuOpen = async (data: SelectOptions) => {
    let classCode = (data as SelectOptions).value;
    if (classCode !== "0") {
      let callback = (options: any) => {
        return options;
      };
      setIsLoading(true);
      let options = await promiseOptions(classCode, callback);
      setDefaultOptions(options);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setEmodStatesData(props.emodStatesData);
  }, [props.emodStatesData]);

  return (
    <AsyncSelect
      className={`newrates-select workCompCode ${ismobile ? "col-8" : " "} `}
      cacheOptions
      defaultOptions={defaultOptions}
      isLoading={isLoading}
      loadOptions={debounce(promiseOptions)}
      onChange={(e) =>
        onClassCodeChange(e, props.stateIndex, props.classCodeIndex)
      }
      onMenuOpen={() => onMenuOpen(props.classcode.classCode)}
      styles={{
        singleValue: (provided, state) => {
          let color;
          if (
            !props.classcode?.classCode?.label ||
            props.classcode?.classCode?.label === "--Select--"
          ) {
            color = "hsl(0, 0%, 50%) !important";
          }
          return {
            ...provided,
            color,
          };
        },
      }}
      value={props.classcode.classCode}
      onFocus={() => props.dismissToast()}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      menuPosition="fixed"
    />
  );
};
