"use strict";
import React, {
  Component,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import axios from "axios";
import numeral from "numeral";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import { alpha } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import parse from "html-react-parser";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { setCommentBoxColor } from "../../utils/common";
import { RotatingLines } from "react-loader-spinner";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";

import { carrierMapping } from "../../utils/carrierList.json";
// import pdf from "../../images/pdf.svg";
// import excel from "../../images/excel.svg";

// import uploaddownload from "../../images/uploading.svg";

// import DealClosedLostButtons from "../subcompo/DealClosedLostButtons";

// import generateExcelData from "../../utils/generate_excel_data";
// import xlsx from "json-as-xlsx";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
// import { Auth } from "aws-amplify";
import Popup from "../common/Popup";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Auth } from "aws-amplify";
import { createCommentForDownload } from "../common/dashboard_helper";

const moment = require("moment");

const {
  DownloadDashboardFiles,
} = require("../subcompo/DownloadDashboardFiles.jsx");

let ismobile = window.innerWidth < 1600;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// const ExportToExcel = (data, fileName) => {
//   let settings = {
//     fileName: fileName, // Name of the resulting spreadsheet
//     extraLength: 1, // A bigger number means that columns will be wider
//     writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
//     writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
//     RTL: false, // Display the columns from right-to-left (the default value is false)
//   };

//   try {
//     xlsx(data, settings); // Will download the excel file
//   } catch (error) {
//     console.log("error while generating excel: ", error);
//   }
// };

// Fein format and adding 0 precedence
const formatFein = (value) => {
  if (!value) return "-";
  let fein = "-";
  value = value.split("-").join("");
  var len = value.length,
    dummystr = "000000000";

  if (len === 0) {
    fein = "-";
  } else if (len > 0 && len < 9) {
    value = dummystr.slice(0, 9 - len) + "" + value;
    fein = value[0] + value[1] + "-" + value.slice(2);
  } else {
    fein = value[0] + value[1] + "-" + value.slice(2);
  }

  return fein;
};

// Camel case for companyName
const camelCase = (str) => {
  if (str)
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  return str;
};

const fontfunciton = () => {
  if (window.innerWidth < 1600 && window.innerWidth > 1500) {
    return "0.84rem";
  }
  if (window.innerWidth < 1500 && window.innerWidth > 1410) {
    return "0.8rem";
  }
  if (window.innerWidth < 1410 && window.innerWidth > 1310) {
    return "0.76rem";
  }
  if (window.innerWidth < 1300) {
    return "0.72rem";
  }
};
export const updateData = async (data) => {
  new Promise((resolve, reject) => {
    try {
      axios
        .post(awsUrl + "/api/underWriterData/update", data)
        .then((res) => {
          console.log("data saved");
          resolve();
        })
        .catch((err) => {
          console.log("error while saving", err);
          reject();
        });
    } catch (error) {}
  });
};

export const updateDataInUser = (data) => {
  new Promise((resolve, reject) => {
    try {
      let data_req = JSON.parse(JSON.stringify(data));
      if (data_req.sortKeyList) {
        delete data_req.sortKeyList;
      }
      axios
        .post(awsUrl2 + "/api/userTableData/update", data_req)
        .then((res) => {
          console.log("data saved");
          resolve();
        })
        .catch((err) => {
          console.log("error while saving", err);
          reject();
        });
    } catch (error) {}
  });
};

const toastSuccess = (text) =>
  toast.success(text, {
    duration: 3000,
  });

const toastError = (text) =>
  toast.error(text, {
    duration: 3000,
  });

let Changing_fontvalue = fontfunciton();
let initialcomment = {
  comment: "",
  commentTimestamp: "",
  email: "",
};

const UnderWriterTab = (props) => {
  const myRef = useRef();
  const [rowDataList, setRowDataList] = useState([]);
  const [releasedRowDataList, setReleasedRowDataList] = useState([]);
  const [isLoading, setIsLoading] = useState({ tab1: true, tab2: true });
  const [modalData, setModalData] = useState({});
  const [domainButtonMap, setDomainButtonMap] = useState({});
  const [style, setStyle] = useState({});
  const [tabsCheck, setTabsCheck] = useState({ tab1: true, tab2: false });
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [popupData, updatePopUpData] = useState({
    title: "",
    children: <></>,
    acceptBtn: "",
    acceptBtnCallback: () => {},
    rejectBtnCallback: () => {},
    show: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState([]);
  const [uwButton, setUwButton] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    getAllData();
    getUWReleasedData();
  }, []);

  const dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YY");
    let momentB = moment(b[field], "M/D/YY");

    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  const onReleaseClick = async (rowData) => {
    let dataToSave = {};
    dataToSave.user_email_id = rowData.user_email_id;
    dataToSave.sortKeyList = rowData.uuidCarriers;
    dataToSave.uw_status = "";
    let recordDetails = {
      uuidList: rowData.uuidCarriers,
      userId: rowData.user_email_id,
    };

    let isLoggedIn = await Auth.currentAuthenticatedUser();
    let loggedIn_emailID = isLoggedIn.attributes.email;

    axios
      .post(awsUrl2 + "/api/removeSalesPersonFlag", recordDetails)
      .then(async (res) => {
        //filter to only show the required fields
        setRowDataList((prevstate) => {
          const _rowDataList = prevstate.filter(
            (element) => element["user_email_id"] !== rowData["user_email_id"]
          );
          return _rowDataList;
        });
        setReleasedRowDataList((prevstate) => {
          return [...prevstate, { ...rowData }];
        });
        let _updatedData = updateData(dataToSave);
        let _updateDataInUser = updateDataInUser(dataToSave);
        const combinedUserandUserStatusPromise = Promise.all([
          _updatedData,
          _updateDataInUser,
        ]);
        combinedUserandUserStatusPromise
          .then(([result1, result2]) => {
            console.log("updated both the table: " + result1, result2);
          })
          .catch((err) => {
            console.error(err);
          });

        await axios.post(awsUrl + "/api/updateStatusDetails", {
          button_name: "release_clicked",
          user_id: loggedIn_emailID,
          user_email_id: rowData.user_email_id,
          sort_key_list: rowData.uuidCarrier,
        });
      })
      .catch((err) => console.log("error: ", err));
  };

  const onClickCompanyName = async (rowData) => {
    await axios
    .post(awsUrl + "/api/underWriterData/fetchSortList", { 
        user_email_id: rowData.user_email_id,
        dateuuid: `${rowData.quoteData.date}@${rowData.quoteData.uuid}`
      })
    .then((res) => {
      if (rowData.currProspect) {
        sessionStorage.setItem(
          "currProspect",
          JSON.stringify(rowData.currProspect)
        );
      }
      if (rowData.user_email_id) {
        sessionStorage.setItem("user_id", rowData.user_email_id);
      }
      sessionStorage.setItem("uw_flow", "true");
  
      if (rowData.salesforceData) {
        sessionStorage.setItem("salesforceData", rowData.salesforceData);
      }
  
      if (res.data.address) {
        sessionStorage.setItem("address", JSON.stringify(res.data.address));
      }
  
      if (rowData.quoteData) {
        sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
      }
  
      if (res.data.uuid_carrier_list) {
        sessionStorage.setItem(
          "sortKeyList",
          JSON.stringify(res.data.uuid_carrier_list)
        );
      }
  
      if (rowData.amtrustAccountDetails) {
        sessionStorage.setItem(
          "amtrustAccountDetails",
          JSON.stringify(rowData.amtrustAccountDetails)
        );
      }
      if (rowData.amtrustAccountDetailsCopy) {
        sessionStorage.setItem(
          "amtrustAccountDetailsCopy",
          JSON.stringify(rowData.amtrustAccountDetailsCopy)
        );
      }
      if (rowData.amtrustNetRatesResponse) {
        sessionStorage.setItem(
          "amtrustNetRatesResponse",
          JSON.stringify(rowData.amtrustNetRatesResponse)
        );
      }
      if (rowData.amtrustEligibility) {
        sessionStorage.setItem(
          "AmtrustEligibility",
          JSON.stringify(rowData.amtrustEligibility)
        );
      }
  
      sessionStorage.setItem("formStage", "three");

      window.location.href = "/Quote";
    }).catch(err => console.log("error: ", err));
  };

  const selectedPeoFormat = (selectedPeo) => {
    let displayPEOName = selectedPeo?.toLowerCase();
    if (selectedPeo === "insurecomp") {
      displayPEOName = "LIB";
    } else if (selectedPeo === "esg halcyon") {
      displayPEOName = "ESG Halcyon";
    } else if (selectedPeo === "expresshr" || selectedPeo === "express hr") {
      displayPEOName = "Express HR";
    } else if (selectedPeo === "gregory") {
      displayPEOName = "J Gregory";
    } else if (selectedPeo === "totalsolution") {
      displayPEOName = "Total Solution";
    } else if (selectedPeo === "payrollmadeeasy") {
      displayPEOName = "Payroll Made Easy";
    }
    displayPEOName = displayPEOName?.replace(/\b\w/g, (char) =>
      char?.toUpperCase()
    );

    // Capitalize entire word if it's only 4 or less than 4 letters
    if (displayPEOName?.length <= 4) {
      displayPEOName = displayPEOName?.toUpperCase();
    }
    return displayPEOName;
  };

  const columnData = [
    {
      title: "Submitted Date",
      field: "submittedDate",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      customSort: (a, b) => dateSort(a, b, "submittedDate"),

      cellStyle: {
        wordBreak: "break-all",
        padding: "6px 0",
        minWidth: "80px",
        padding: "2px 0px 2px 16px",
      },
      headerStyle: {
        padding: "2px 0px 2px 16px",
        minWidth: "90px",
      },
    },
    {
      title: "Company Name",
      field: "companyname",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "200px",
        wordBreak: "break-word",
      },
      headerStyle: {
        padding: "6px 5px 6px 10px",
      },
      render: (rowData) => {
        return (
          <Clamp lines={2}>
            <span onClick={() => onClickCompanyName(rowData)} className="btn-link" style={{cursor: "pointer"}}>
              {/* <Link to={"/Quote"}> */}
              {camelCase(rowData.companyname)}
              {/* </Link> */}
            </span>
          </Clamp>
        );
      },
      customSort: (a, b) => {
        const nameA = a.companyname.toUpperCase().trim();
        const nameB = b.companyname.toUpperCase().trim();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "FEIN",
      field: "fein",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      cellStyle: {
        wordBreak: "break-all",
        padding: "2px 0px 2px 6px",
        minWidth: "90px",
      },
      headerStyle: {
        padding: "10px 0",
        minWidth: "90px",
      },
      render: (rowData) => formatFein(rowData.fein),
    },
    {
      title: "Primary State",
      field: "primaryState",
      filterPlaceholder: "Filter",

      cellStyle: {
        wordBreak: "break-all",
        padding: "2px 0px 2px 16px",
        minWidth: "90px",
      },
      headerStyle: {
        padding: "10px 0px 10px 16px",
        minWidth: "90px",
      },
      render: (rowData) => (
        <Clamp lines={1}>{rowData.primaryState || "-"}</Clamp>
      ),
    },
    {
      title: "Gov CC State",
      field: "govCcState",
      filterPlaceholder: "Filter",

      cellStyle: {
        wordBreak: "break-all",
        padding: "2px 0px 2px 6px",
        minWidth: "90px",
      },
      headerStyle: {
        padding: "10px 0",
        minWidth: "90px",
      },
      render: (rowData) => <Clamp lines={1}>{rowData.govCcState || "-"}</Clamp>,
    },
    {
      title: "Class code",
      field: "govClassCode",
      filterPlaceholder: "Filter",

      cellStyle: {
        wordBreak: "break-all",
        padding: "2px 0px 2px 6px",
        minWidth: "90px",
      },
      headerStyle: {
        padding: "10px 0",
        minWidth: "90px",
      },
      render: (rowData) => (
        <Clamp lines={1}>{rowData.govClassCode || "-"}</Clamp>
      ),
      customSort: (a, b) => {
        // Convert govClassCode to number for comparison
        const codeA = +a.govClassCode || 0;
        const codeB = +b.govClassCode || 0;
        return codeA - codeB;
      },
    },
    {
      title: "Payroll",
      field: "payroll",
      filtering: true,
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "120px",
        textAlign: "right",
      },
      headerStyle: {
        padding: " 0px 0px 0px 10px",
        minWidth: "120px",
        paddingLeft: "1.5rem",
      },

      render: (rowData) => (
        <div className="payrollBox">
          <Clamp lines={1}>{rowData.payroll || "-"}</Clamp>
        </div>
      ),
    },
    {
      title: "Distributing Partners",
      field: "selectedPeoToDisplay",
      filtering: true,
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "120px",
      },
      headerStyle: {
        padding: " 0px 0px 0px 10px",
        minWidth: "120px",
      },

      render: (rowData) => (
        <Clamp lines={1}>{rowData.selectedPeoToDisplay}</Clamp>
      ),
    },
    {
      title: "Submitted By",
      field: "submittedUserName",
      filterPlaceholder: "Filter",
      filtering: true,
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "00px",
        wordBreak: "break-word",
      },
      headerStyle: {
        padding: "6px 5px 6px 10px",
        minWidth: "00px",
      },
      render: (rowData) => {
        return (
          <Clamp lines={1}>
            {rowData.submittedUserName
              ? rowData.submittedUserName.split("@")[0].length <= 3
                ? rowData.submittedUserName.split("@")[0].toUpperCase()
                : rowData.submittedUserName.split("@")[0][0].toUpperCase() +
                  rowData.submittedUserName.split("@")[0].slice(1)
              : ""}
          </Clamp>
        );
      },
      customSort: (a, b) => {
        const nameA = a.submittedUserName.toUpperCase();
        const nameB = b.submittedUserName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Modified Date",
      field: "uploadTimestamp",
      filtering: true,
      filterPlaceholder: "Filter",
      render: (rowData) => rowData.uploadTimestamp,
      customSort: (a, b) => this.dateSort(a, b, "uploadTimestamp"),
      headerStyle: {
        padding: "0px 0px 0px 8px",
        minWidth: "120px",
        textAlign: "center",
      },
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "120px",
        width: "120px",
        textAlign: "center",
      },
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
    },
    {
      title: "Comments",
      field: "uw_comments_download",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "240px",
      },
      headerStyle: {
        minWidth: "240px",
      },
      render: (rowData) => {
        const callbackFunction = (data) => {
          updateData(data);
          updateDataInUser(data);
        };
        return (
          <div className="d-flex">
            <TextField
              key={`${rowData.user_email_id}_comment`}
              value={
                typeof rowData?.uw_comments?.[0] === "object"
                  ? rowData?.uw_comments?.[0]?.comment
                  : rowData?.uw_comments?.[0]
              }
              callback={callbackFunction}
              rowData={rowData}
              comments={rowData?.uw_comments}
              uw_comments="uw_comments"
              date={rowData?.submittedDate}
            />
            <Tooltip title="Click to view comment history" arrow>
              <button
                className="comment-btn"
                onClick={() =>
                  handleUWCommentHistoryButtonClick(
                    rowData,
                    rowData?.uw_comments,
                    rowData?.submittedDate
                  )
                }
              >
                ...
              </button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Agent Comments",
      field: "agent_comments_download",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "210px",
      },
      headerStyle: {
        minWidth: "210px",
      },
      render: (rowData) => {
        const callbackFunction = (data) => {
          updateData(data);
          updateDataInUser(data);
        };
        return (
          <div className="d-flex">
            <TextField
              key={`${rowData.user_email_id}_comment`}
              value={
                typeof rowData?.agent_comments?.[0] === "object"
                  ? rowData?.agent_comments?.[0]?.comment ||
                    rowData?.agent_comments?.[0].comment
                  : rowData?.agent_comments?.[0]
              }
              callback={callbackFunction}
              rowData={rowData}
              comments={rowData?.agent_comments}
              agent_comments="agent_comments"
            />
            <Tooltip title="Click to view comment history" arrow>
              <button
                className="comment-btn"
                onClick={() =>
                  handleAgentCommentHistory(
                    [rowData],
                    `${rowData.user_email_id}`
                  )
                }
              >
                ...
              </button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      // title: (
      //   <img
      //     src={require("../../images/file-download-circle-green.svg")}
      //     alt=""
      //     style={{
      //       width: "40px",
      //       // filter: "grayscale(100%)  opacity(0.4)",
      //     }}
      //   />),
      title: "Download",
      sorting: false,
      disableSortBy: true,
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "55px",
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "right",
        padding: "6px 2px 4px 14px",
      },
      render: (rowData) => {
        let title = "";

        if (rowData?.fileListToStore?.length) {
          for (let file of rowData?.fileListToStore) {
            let fileToDisplay = file.split("/");
            title =
              title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
          }
        }

        return (
          <button
            className="btn pt-0"
            type="button"
            title={parse(title)}
            data-html="true"
            onClick={() => {
              setModalData({
                showModel: true,
                modalTitle: <h1>{"Download"}</h1>,
                modalContent: (
                  <>
                    <DownloadDashboardFiles
                      fileListToStore={rowData.fileListToStore}
                    />
                  </>
                ),
              });
            }}
          >
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={
                rowData?.fileListToStore?.length
                  ? {
                      width: "30px",
                      filter: "grayscale(0)",
                    }
                  : {
                      width: "30px",
                      filter: "grayscale(100%)  opacity(0.4)",
                    }
              }
            />
          </button>
        );
      },
    },
    {
      title: "Status",
      field: "uw_status",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "80px",
      },
      render: (rowData) => {
        return (
          <>
            <select
              id="statusDrop"
              value={domainButtonMap[rowData?.user_email_id]?.uw_status}
              onChange={(event) => {
                let statusMapping = {
                  Bound: "Bound",
                  Quoted: "Quoted",
                  Closed: "Closed",
                  Declined: "Declined",
                  "UW Quest": "UW Quest",
                  Submitted: "Submitted",
                };
                if (event.target.value === statusMapping[event.target.value]) {
                  updateButtonStatus(
                    rowData,
                    statusMapping[event.target.value]
                  );
                }
              }}
              style={{ height: "28px" }}
            >
              <option value={""}>Select</option>
              <option value="Bound"> Bound</option>
              <option value="Quoted"> Quoted</option>
              <option value="Closed"> Closed </option>
              <option value="Declined"> Declined</option>
              <option value="UW Quest"> UW Quest</option>
              <option value="Submitted"> Submitted </option>
            </select>
          </>
        );
      },
    },
    {
      title: "Release",
      sorting: false,
      field: "Release",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "70px",
      },
      render: (rowData) => {
        return (
          <button
            className="btn btn-sm btn-warning"
            disabled={rowData?.dealConfirmed}
            onClick={(e) => {
              if (!rowData?.dealConfirmed)
                updatePopUpData({
                  show: true,
                  title: "",
                  children: (
                    <>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        This action will release the prospect back to Agent. Do
                        you want to Proceed?
                      </span>
                    </>
                  ),
                  acceptBtn: "Yes",
                  acceptBtnCallback: async () => {
                    try {
                      await onReleaseClick(rowData);
                    } catch (error) {
                      console.log("error: ", error);
                    }
                  },
                });
            }}
          >
            Release
          </button>
        );
      },
    },
  ];

  const releasedColumnData = [
    {
      title: "Submitted Date",
      field: "submittedDate",
      filterPlaceholder: "Filter",
      // defaultSort: "asc",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      cellStyle: {
        wordBreak: "break-all",
        padding: "6px 0",
        minWidth: "80px",
        padding: "2px 0px 2px 16px",
      },
      headerStyle: {
        padding: "2px 0px 2px 16px",
        minWidth: "90px",
      },
    },
    {
      title: "Company Name",
      field: "companyname",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "200px",
        wordBreak: "break-word",
      },
      headerStyle: {
        padding: "6px 5px 6px 10px",
      },
      render: (rowData) => {
        return <Clamp lines={2}>{camelCase(rowData.companyname)}</Clamp>;
      },
    },
    {
      title: "FEIN",
      field: "fein",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      cellStyle: {
        wordBreak: "break-all",
        padding: "2px 0px 2px 6px",
        minWidth: "90px",
      },
      headerStyle: {
        padding: "10px 0",
        minWidth: "90px",
      },
      render: (rowData) => formatFein(rowData?.fein),
    },
    {
      title: "Payroll",
      field: "payroll",
      filtering: true,
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "120px",
        textAlign: "right",
      },
      headerStyle: {
        padding: " 0px 0px 0px 10px",
        minWidth: "120px",
        paddingLeft: "2rem",
      },

      render: (rowData) => (
        <div className="releasedPayrollBox">
          <Clamp lines={1}>{rowData.payroll || "-"}</Clamp>
        </div>
      ),
    },
    {
      title: "Comments",
      field: "uw_comments",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "140px",
      },
      render: (rowData) => {
        const callbackFunction = (data) => {
          updateData(data);
          updateDataInUser(data);
        };
        return (
          <TextField
            key={`${rowData.user_email_id}_comment`}
            value={
              typeof rowData?.uw_comments?.[0] === "object"
                ? rowData?.uw_comments?.[0]?.comment
                : rowData?.uw_comments?.[0]
            }
            callback={callbackFunction}
            rowData={rowData}
            uw_comments="uw_comments"
            uw_disabled="uw_disabled"
          />
        );
      },
    },

    // {
    //   title: "Agent Comments",
    //   field: "agent_comments",
    //   filterPlaceholder: "Filter",
    //   cellStyle: {
    //     padding: "2px 0px 2px 6px",
    //     minWidth: "140px",
    //   },
    //   render: (rowData) => {
    //     const callbackFunction = (data) => {
    //       updateData(data);
    //       updateDataInUser(data);
    //     };
    //     return (
    //       <TextField
    //         key={`${rowData.user_email_id}_comment`}
    //         value={rowData?.agent_comments}
    //         callback={callbackFunction}
    //         rowData={rowData}
    //         agent_comments="agent_comments"
    //       />
    //     );
    //   },
    // },
  ];

  const customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };
  const handleUWCommentHistoryButtonClick = (data, comments, submittedDate) => {
    setUwButton(true);
    if (comments !== undefined) {
      if (typeof comments[0] === "string" && comments[0] !== "") {
        let updatedComment = [
          {
            ...initialcomment,
            comment: comments[0],
            commentTimestamp: submittedDate,
          },
        ];
        setModalBody(updatedComment);
      } else {
        setModalBody(comments);
      }
    } else {
      setModalBody([initialcomment]);
    }
    setOpenModal(true);
  };

  const handleAgentCommentHistory = (rowdata, rowkey) => {
    setUwButton(false);
    rowdata.forEach((item) => {
      if (item.user_email_id === rowkey && item.agent_comments !== undefined) {
        if (typeof item.agent_comments[0] === "object") {
          setModalBody(item.agent_comments);
        } else if (typeof item.agent_comments[0] === "string") {
          let updatedComment = [
            {
              ...initialcomment,
              comment: item.agent_comments[0],
              commentTimestamp: item.submittedDate,
            },
          ];
          setModalBody(updatedComment);
        }
      } else if (item.agent_comments === undefined) {
        setModalBody([initialcomment]);
      }
    });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const setTableData = (rowDataList, columnData, isLoading) => {
    // getting the data from backend

    const scrollLeftHandler = () => {
      const tableContainerDiv =
        myRef.current.lastChild.lastChild.children[2].children[1].children[0]
          .children[0];
      if (tableContainerDiv) {
        tableContainerDiv.scrollLeft -= 150;
        if (tableContainerDiv.scrollLeft == 0) {
          setScrollLeft(false);
          setScrollRight(true);
        }

        const maxScrollLeft =
          tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

        if (tableContainerDiv.scrollLeft < maxScrollLeft) {
          setScrollRight(true);
        }
      }
    };
    const scrollRightHandler = () => {
      const tableContainerDiv =
        myRef.current.lastChild.lastChild.children[2].children[1].children[0]
          .children[0];

      if (tableContainerDiv) {
        tableContainerDiv.scrollLeft += 150;
        setScrollLeft(true);
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft >= maxScrollLeft) {
        setScrollRight(false);
      }
    };

    return (
      <>
        <div style={{ position: "relative" }}>
          {/* {scrollLeft && (
            <button className="ChevronLeft" onClick={scrollLeftHandler}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={scrollRightHandler}>
              &gt;
            </button>
          )} */}
          <MaterialTable
            icons={tableIcons}
            data={rowDataList}
            columns={columnData}
            detailPanel={
              tabsCheck["tab1"] && false
                ? [
                    {
                      tooltip: "Status info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return (
                          <div style={{ padding: "25px" }}>
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead
                                  style={{
                                    background: "#00000066",
                                    color: "whitesmoke",
                                  }}
                                >
                                  <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Date</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Object.values(rowData.status_info_rows).map(
                                    (element, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell scope="row">
                                            {element.status}
                                          </TableCell>
                                          <TableCell scope="row">
                                            {element.timestamp}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        );
                      },
                    },
                  ]
                : []
            }
            options={{
              stickyHeader: true,
              // doubleHorizontalScroll: true,
              filtering: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, 100],
              exportFileName: "GetQuote Dashborad File",
              exportButton: {
                csv: true,
                pdf: false,
              },
              headerStyle: {
                fontSize: ismobile ? fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
              // headerStyle: {
              //   fontSize: "12px",
              //   backgroundColor: "#003764",
              //   color: "#FFF",
              //   padding: "1px 5px 1px 5px",
              //   fontFamily: "Montserrat",
              // },
              // rowStyle: {
              //   fontSize: "12px",
              //   fontFamily: "Montserrat",
              // },
              // searchFieldStyle: {
              //   fontSize: "12px",
              //   fontFamily: "Montserrat",
              // },
              // filterCellStyle: {
              //   padding: "5px 15px 5px 4px",
              //   fontFamily: "Montserrat",
              // },
            }}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  };

  // const ButtonGroup = (props) => {
  //   // const [isApproved, setIsApproved] = useState(false);
  //   // const [isRejected, setIsRejected] = useState(false);
  //   // const [isOnHold, setIsOnHold] = useState(false);

  //   // useEffect(() => {
  //   //   setIsApproved(
  //   //     props?.domainButtonMap?.[props?.rowData?.["user_email_id"] || "test"]
  //   //       ?.isApproved || false
  //   //   );
  //   //   setIsRejected(
  //   //     props?.domainButtonMap?.[props?.rowData?.["user_email_id"] || "test"]
  //   //       ?.isRejected || false
  //   //   );
  //   //   setIsOnHold(
  //   //     props?.domainButtonMap?.[props?.rowData?.["user_email_id"] || "test"]
  //   //       ?.isOnHold || false
  //   //   );
  //   // }, [
  //   //   props.domainButtonMap?.isApproved,
  //   //   props.domainButtonMap?.isRejected,
  //   //   props.domainButtonMap?.isOnHold,
  //   // ]);

  //   const updateButtonStatus = (status) => {
  //     let row = props.rowData;
  //     let _domainButtonMap = Object.assign({}, props.domainButtonMap);

  //     let dataToSave = {};
  //     dataToSave.user_email_id = row.user_email_id;
  //     dataToSave.sortKeyList = row.uuidCarriers;
  //     dataToSave.uw_status = status;

  //     if (status === "approved") {
  //       _domainButtonMap[row?.["user_email_id"]].isApproved = true;
  //     } else if (status === "rejected") {
  //       _domainButtonMap[row?.["user_email_id"]].isRejected = true;
  //     } else if (status === "onHold") {
  //       _domainButtonMap[row?.["user_email_id"]].isOnHold = true;
  //     }
  //     props.updateData(dataToSave);
  //     props.updateDataInUser(dataToSave);
  //     props.setDomainButtonMap(_domainButtonMap);
  //   };

  //   return (
  //     <div>
  //       <>
  //         <select
  //           // value={props?.rowData?.uw_status}
  //           defaultValue={props?.rowData?.uw_status}
  //           onChange={(event) => {
  //             console.log(event.target, props.rowData);
  //             if (event.target.value === "approved") {
  //               updateButtonStatus("approved");
  //             }
  //             if (event.target.value === "onHold") {
  //               updateButtonStatus("onHold");
  //             }
  //             if (event.target.value === "rejected") {
  //               updateButtonStatus("rejected");
  //             }
  //           }}
  //         >
  //           <option value="approved"> Approve</option>
  //           <option value="onHold"> onHold</option>
  //           <option value="rejected"> Rejected </option>
  //         </select>
  //       </>
  //     </div>
  //   );
  // };
  const updateButtonStatus = (rowData, status) => {
    let dataToSave = {};
    dataToSave.user_email_id = rowData.user_email_id;
    dataToSave.sortKeyList = rowData.uuidCarriers;
    dataToSave.uw_status = status;
    let _domainButtonMap = Object.assign({}, domainButtonMap);

    _domainButtonMap[rowData?.user_email_id].uw_status = status;

    // const modifiedRow = rowDataList.find(
    //   (row) => row?.user_email_id === rowData.user_email_id
    // );

    // try {
    //   modifiedRow.uw_status = status;
    // } catch (error) {
    //   // Handle the error
    //   console.log(error);
    // }

    updateData(dataToSave);
    updateDataInUser(dataToSave);
    // setRowDataList(rowDataList);
    setDomainButtonMap(_domainButtonMap);
  };

  const makeNameReadable = (str) => {
    let _str = "";
    if (str) {
      _str = str
        .split("_")
        .map((e) => {
          return e[0].toUpperCase() + e.substr(1);
        })
        .join(" ");
    }
    return _str;
  };

  const getAllDataApi = (_globalDbData, _lastEvaluatedKey) => {
    return new Promise(async (resolve, reject) => {
      let lastEvaluatedKey = _lastEvaluatedKey;
      let dataFromAPI = {};
      // do {
      try {
        await axios
          .post(awsUrl + "/api/underWriterData/getAll", { lastEvaluatedKey })
          .then((res) => {
            for (let domain in res.data.emailBasedData) {
              if (!dataFromAPI[domain] && !_globalDbData[domain]) {
                dataFromAPI[domain] = [...res.data.emailBasedData[domain]];
              }
              // dataFromAPI[domain].push(...res.data.emailBasedData[domain]);
            }
            // console.log("dataFromAPI", res.data.lastEvaluatedKey);
            lastEvaluatedKey = res.data.lastEvaluatedKey;
          });
      } catch (e) {
        console.log("API Error", e);
      }
      // } while (lastEvaluatedKey);
      resolve({ lastEvaluatedKey, dataFromAPI });
    });
  };

  const getAllData = async () => {
    try {
      let lastEvalKey = null,
        globalDbData = {};
      do {
        await new Promise((reso, rej) => {
          getAllDataApi(globalDbData, lastEvalKey)
            .then((res) => {
              // console.log("getAllData", res);

              let { dataFromAPI, lastEvaluatedKey } = res;
              let rowDataList = [];
              let domainButtonMap = {};

              lastEvalKey = lastEvaluatedKey;
              globalDbData = { ...globalDbData, ...dataFromAPI };

              try {
                for (let domain in dataFromAPI) {
                  let createPreparedData = true;
                  let preparedData = {};

                  for (let domainWiseQuoteData of dataFromAPI[domain]) {
                    let payroll;
                    let classcodeInfo;
                    let sum = 0;

                    let childloc =
                      domainWiseQuoteData["currProspectDetails"]["childrenLoc"];

                    for (let domainWiseQuoteData in childloc) {
                      let userChildlocData = childloc[domainWiseQuoteData];
                      classcodeInfo = userChildlocData.classCodesInfo;
                      classcodeInfo = Object.entries(classcodeInfo);
                      for (let i = 0; i < classcodeInfo.length; i++) {
                        let paydata = classcodeInfo[i];
                        const stringAmount = paydata[1]?.payroll?.value;
                        if (!stringAmount) continue;
                        const numberAmount = parseFloat(
                          stringAmount.replace(/[^\d.-]/g, "")
                        );
                        sum = sum + numberAmount;
                      }
                      payroll = sum;
                    }

                    //  Process to get Governing class code and Governing class code state.

                    let governingClassCode;
                    let govClassCode = [];
                    let maxPayroll = -Infinity;
                    let govccstate;

                    // Loop through each location
                    if (childloc) {
                      Object.values(childloc).map((location) => {
                        if (location.classCodesInfo) {
                          Object.values(location.classCodesInfo).map(
                            (classCode) => {
                              if (classCode) {
                                let classCodeobj = {
                                  payroll: classCode.payroll.value
                                    ? parseFloat(
                                        classCode.payroll.value.replace(
                                          /[^\d.-]/g,
                                          ""
                                        )
                                      )
                                    : 0,
                                  classCode: classCode.classCodeDescription?.value?.split(
                                    ":"
                                  )[0],
                                  govccstate: location.state,
                                };
                                govClassCode.push(classCodeobj);
                              }
                            }
                          );
                        }
                      });
                    }
                    govClassCode.forEach((item) => {
                      if (item.payroll > maxPayroll) {
                        maxPayroll = item.payroll;
                        governingClassCode = item.classCode;
                        govccstate = item.govccstate.value;
                      }
                    });

                    if (createPreparedData) {
                      try {
                        preparedData = {
                          user_email_id: domainWiseQuoteData?.user_email_id,
                          salesforceData: domainWiseQuoteData?.salesforceData,
                          uploadTimestamp:
                            (domainWiseQuoteData?.date
                              ? moment(
                                  Number(domainWiseQuoteData?.date),
                                  "x"
                                ).format("M/D/YY")
                              : "-") || "",
                          submittedDate:
                            (domainWiseQuoteData?.submissionDate
                              ? moment(
                                  Number(domainWiseQuoteData?.submissionDate),
                                  "x"
                                ).format("M/D/YY")
                              : "-") || "",
                          submittedUserName:
                            domainWiseQuoteData?.producerEmail || "",
                          companyname:
                            domainWiseQuoteData?.currProspectDetails
                              ?.companyProfile?.companyName?.value || "",
                          fein:
                            domainWiseQuoteData?.currProspectDetails
                              ?.companyProfile?.fein?.value || "",
                          primaryState:
                            domainWiseQuoteData?.currProspectDetails?.companyProfile?.state1?.value?.toUpperCase() ||
                            "-",
                          govClassCode: governingClassCode,
                          govCcState: govccstate,
                          uw_comments: domainWiseQuoteData?.uw_comments
                            ? typeof domainWiseQuoteData?.uw_comments ===
                              "string"
                              ? [domainWiseQuoteData?.uw_comments]
                              : domainWiseQuoteData?.uw_comments
                            : [""],
                          agent_comments: domainWiseQuoteData?.agent_comments
                            ? typeof domainWiseQuoteData?.agent_comments ===
                              "string"
                              ? [domainWiseQuoteData?.agent_comments]
                              : domainWiseQuoteData?.agent_comments
                            : [""],
                          currProspect:
                            domainWiseQuoteData?.currProspectDetails,
                          uuidCarriers: domainWiseQuoteData?.uuid_carrier
                            ? [domainWiseQuoteData?.uuid_carrier]
                            : [],
                          address: domainWiseQuoteData?.uuid_carrier
                            ? [
                                domainWiseQuoteData?.uuid_carrier
                                  ?.split("+")
                                  ?.pop(),
                              ]
                            : [],
                          uw_status: domainWiseQuoteData?.uw_status,
                          fileList: domainWiseQuoteData?.fileList,
                          uwFileList: domainWiseQuoteData?.uwFileList,
                          dealConfirmed: domainWiseQuoteData?.dealConfirmed,
                          selectedPeo:
                            domainWiseQuoteData?.["currProspectDetails"]
                              ?.peoDetails?.selectedPeo || "-",
                          selectedPeoToDisplay: selectedPeoFormat(
                            domainWiseQuoteData?.["currProspectDetails"]
                              ?.peoDetails?.selectedPeo || "-"
                          ),
                          payroll: payroll?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                          }),
                          amtrustAccountDetails:
                            domainWiseQuoteData?.amtrustAccountDetails || "",
                          amtrustAccountDetails:
                            domainWiseQuoteData?.amtrustAccountDetails || "",
                          amtrustNetRatesResponse:
                            domainWiseQuoteData?.amtrustNetRatesResponse || "",
                          status_info_rows: [],
                        };

                        let _status_info =
                          domainWiseQuoteData?.status_info || {};

                        try {
                          for (let status in _status_info) {
                            if (status === "underwriting_page_status") {
                              for (let carr in _status_info[status]) {
                                for (let sts in _status_info[status][carr]) {
                                  preparedData.status_info_rows.push({
                                    status:
                                      makeNameReadable(sts) +
                                      " (with carrier: " +
                                      (carrierMapping[carr] || carr) +
                                      ")",
                                    timestamp: moment(
                                      _status_info[status][carr][sts].timestamp,
                                      "x"
                                    ).format("MM/DD/YYYY HH:mm:ss"),
                                  });
                                }
                              }
                            } else {
                              preparedData.status_info_rows.push({
                                status: makeNameReadable(status),
                                timestamp: moment(
                                  _status_info[status].timestamp,
                                  "x"
                                ).format("MM/DD/YYYY HH:mm:ss"),
                              });
                            }
                          }
                        } catch (error) {
                          console.log(error);
                        }

                        preparedData.status_info_rows.sort((a, b) =>
                          a.timestamp < b.timestamp
                            ? 1
                            : a.timestamp > b.timestamp
                            ? -1
                            : 0
                        );

                        if (preparedData.status_info_rows.length) {
                          console.log(preparedData.status_info_rows);
                        }

                        createCommentForDownload(preparedData, "uw_comments");
                        createCommentForDownload(
                          preparedData,
                          "agent_comments"
                        );
                        if (
                          domainWiseQuoteData?.date &&
                          domainWiseQuoteData?.uuid
                        ) {
                          preparedData.quoteData = {
                            uuid: domainWiseQuoteData.uuid,
                            date: domainWiseQuoteData.date,
                          };
                        }

                        let _fileListToStore = [];
                        if (domainWiseQuoteData?.fileList?.length) {
                          domainWiseQuoteData.fileList.forEach((ele) => {
                            _fileListToStore.push(ele.key);
                          });
                        }
                        if (domainWiseQuoteData?.uwFileList?.length) {
                          domainWiseQuoteData.uwFileList.forEach((ele) => {
                            _fileListToStore.push(ele.key);
                          });
                        }
                        preparedData["fileListToStore"] = _fileListToStore;

                        createPreparedData = false;
                      } catch (error) {
                        console.log(error);
                      }
                    } else {
                      if (domainWiseQuoteData?.uuid_carrier) {
                        preparedData.uuidCarriers.push(
                          domainWiseQuoteData.uuid_carrier
                        );
                        preparedData.address.push(
                          domainWiseQuoteData?.uuid_carrier?.split("+").pop()
                        );
                      }
                    }
                    if (!(domain in domainButtonMap))
                      domainButtonMap[domain] = {
                        uw_status: domainWiseQuoteData?.uw_status || "Select",
                      };
                  }
                  rowDataList.push(preparedData);
                }
              } catch (error) {}

              // setRowDataList(rowDataList);
              // setDomainButtonMap(domainButtonMap);
              setRowDataList(_rowDataList => [..._rowDataList, ...rowDataList]);
              setDomainButtonMap(_domainButtonMap => {return {..._domainButtonMap, ...domainButtonMap}});
              console.log(rowDataList, "rowDatalist");
              setIsLoading((values) => {
                return { ...values, tab1: false };
              });
              reso();
            })
            .catch((err) => {
              setRowDataList([]);
              setIsLoading((values) => {
                return { ...values, tab1: false };
              });
              rej();
            });
        });
      } while (lastEvalKey);
      setDataLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getUWReleasedData = () => {
    try {
      axios
        .post(awsUrl + "/api/underWriterData/uwReleased", {})
        .then((res) => {
          let dataFromAPI = res.data;
          let _releasedRowDataList = [];

          try {
            for (let domain in dataFromAPI) {
              let createPreparedData = true;
              let preparedData = {};

              for (let domainWiseQuoteData of dataFromAPI[domain]) {
                let payroll;
                let classcodeInfo;
                let sum = 0;

                let childloc = domainWiseQuoteData["childrenLoc"];

                for (let domainWiseQuoteData in childloc) {
                  let userChildlocData = childloc[domainWiseQuoteData];
                  classcodeInfo = userChildlocData.classCodesInfo;
                  classcodeInfo = Object.entries(classcodeInfo);
                  for (let i = 0; i < classcodeInfo.length; i++) {
                    let paydata = classcodeInfo[i];
                    const stringAmount = paydata[1]?.payroll?.value;
                    if (!stringAmount) continue;
                    const numberAmount = parseFloat(
                      stringAmount.replace(/[^\d.-]/g, "")
                    );
                    sum = sum + numberAmount;
                  }
                  payroll = sum;
                }

                try {
                  preparedData = {
                    submittedDate:
                      (domainWiseQuoteData?.submissionDate
                        ? moment(
                            Number(domainWiseQuoteData?.submissionDate),
                            "x"
                          ).format("M/D/YY")
                        : "-") || "",
                    submittedUserName: domainWiseQuoteData?.producerEmail || "",
                    companyname:
                      domainWiseQuoteData?.companyProfile?.companyName?.value ||
                      "",
                    fein:
                      domainWiseQuoteData?.companyProfile?.fein?.value || "",
                    uw_comments: domainWiseQuoteData?.uw_comments || "",
                    agent_comments: domainWiseQuoteData?.agent_comments || "",
                    payroll: payroll?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                    }),
                  };
                } catch (error) {}

                _releasedRowDataList.push(preparedData);
              }
            }
          } catch (error) {}

          _releasedRowDataList.sort(
            (a, b) => new Date(b.submittedDate) - new Date(a.submittedDate)
          );

          setReleasedRowDataList((values) => {
            return [...values, ..._releasedRowDataList];
          });
          setIsLoading((values) => {
            return { ...values, tab2: false };
          });
        })
        .catch((err) => {
          setReleasedRowDataList([]);
          setIsLoading((values) => {
            return { ...values, tab2: false };
          });
        });
    } catch (error) {
      console.log("error in fetching data ", error);
    }
  };

  let NavButtons = () => {
    let tabMap = { tab1: "UW dashboard", tab2: " Released dashboard" };
    return (
      <>
        {Object.keys(tabMap).map((tab) => {
          return (
            <>
              <button
                className={`btn pl-2 pr-2 ml-2 mr-2 ${
                  tabsCheck[[tab]] ? " btn-primary" : "btn-secondary"
                }`}
                onClick={() => {
                  setTabsCheck((values) => {
                    Object.keys(values).forEach((item) => {
                      values[item] = false;
                    });
                    return { ...values, [tab]: true };
                  });
                }}
              >
                {tabMap[tab]}
              </button>
            </>
          );
        })}
      </>
    );
  };
  let { showModel, modalTitle, modalContent } = modalData;
  return (
    <>
      <div className={"Submission-uw-dashboard"} style={style}>
        <NavButtons />
        {tabsCheck["tab1"] && (
          <div>
            {dataLoading ? (
              <div style={{ marginLeft: "50%" }}>
                <RotatingLines
                  visible={true}
                  height="40"
                  width="40"
                  color="black"
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
                <b>Data Loading.....</b>
              </div>
            ) : (
              <div style={{ marginLeft: "50%" }}></div>
            )}
            <div ref={myRef}>
              {setTableData(rowDataList, columnData, isLoading["tab1"])}
            </div>
          </div>
        )}

        {tabsCheck["tab2"] && (
          <div>
            {setTableData(
              releasedRowDataList,
              releasedColumnData,
              isLoading["tab2"]
            )}
          </div>
        )}

        {/* comments history modal */}
        {uwButton ? (
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>
              <p style={{ textAlign: "center" }}>
                {(modalBody.length > 0 &&
                  modalBody[0] !== "" &&
                  modalBody?.[0]?.comments !== "") ||
                modalBody?.[0]?.previousComment
                  ? "Comment History"
                  : "No Comment History"}
              </p>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "5px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {modalBody.length > 0 &&
                modalBody[0] !== "" &&
                modalBody.map((item, index) => {
                  let date = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[0];
                  let time = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[1];
                  if (item.comment !== "") {
                    if (item.commentTimestamp || item.email || item) {
                      return (
                        <div>
                          <div>
                            <span>
                              <b>{date}</b>
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <b>{time}</b>
                            </span>
                            <span>{item.email ? "- " : ""}</span>
                            <span>
                              <b>{item.email?.split("@")[0]}</b>
                            </span>
                          </div>
                          <div>
                            <p>
                              {item?.comment
                                ? item.comment
                                : modalBody[index + 1]?.comment
                                ? modalBody[index + 1].comment
                                : item}
                            </p>
                          </div>
                          <br />
                        </div>
                      );
                    }
                  } else {
                    return;
                  }
                })}
            </DialogContent>
          </Dialog>
        ) : (
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>
              <p style={{ textAlign: "center" }}>
                {(modalBody.length > 0 && modalBody[0]?.comment !== "") ||
                modalBody?.[0]?.previousComment
                  ? "Comment History"
                  : "No Comment History"}
              </p>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "5px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {modalBody.length > 0 &&
                modalBody[0] !== "" &&
                modalBody.map((item, index) => {
                  let date = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[0];
                  let time = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[1];
                  if (item.comment !== "") {
                    if (item.commentTimestamp || item.email || item) {
                      return (
                        <div>
                          <div>
                            <span>
                              <b>{date}</b>
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <b>{time}</b>
                            </span>
                            <span>{item.email ? "- " : ""}</span>
                            <span>
                              <b>{item.email?.split("@")[0]}</b>
                            </span>
                          </div>
                          <div>
                            <p>
                              {item?.comment
                                ? item.comment
                                : modalBody[index + 1]?.comment
                                ? modalBody[index + 1].comment
                                : item}
                            </p>
                          </div>
                          <br />
                        </div>
                      );
                    }
                  } else {
                    return;
                  }
                })}
            </DialogContent>
          </Dialog>
        )}

        {/* Release button modal */}
        <Modal
          show={showModel}
          onHide={() => {
            setModalData({
              showModel: false,
              modalTitle: null,
              modalContent: null,
            });
          }}
          dialogClassName="model-download modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
        </Modal>
      </div>
      <Toaster />
      {popupData.show && (
        <Popup popupData={popupData} updatePopUpData={updatePopUpData} />
      )}
    </>
  );
};

function TextField(props) {
  const [value, setValue] = useState("");
  const [loggedInEmail, setLoggedInEmail] = useState("");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    loggedInEmailId();
  }, []);

  let loggedInEmailId = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      setLoggedInEmail(sessionData["attributes"]["email"]);
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  return (
    <textarea
      value={value || ""}
      className="underwriter_comment"
      rows="1"
      style={{
        width: "100%",
        backgroundColor: setCommentBoxColor(props?.comments),
      }}
      onChange={(e) => setValue(e.target.value)}
      disabled={props.agent_comments ? true : props.uw_disabled ? true : false}
      onBlur={(e) => {
        let uwCommentsCopy = props.rowData.uw_comments;

        let currentTimestamp = moment().valueOf();
        let dataToSave = {};

        if (uwCommentsCopy === undefined && e.target.value.trim() !== "") {
          uwCommentsCopy = [
            {
              comment: e.target.value.trim(),
              commentTimestamp: currentTimestamp,
              email: loggedInEmail,
            },
          ];
        } else if (
          e.target.value.length === 0 ||
          e.target.value.trim() === ""
        ) {
          if (uwCommentsCopy === undefined) {
            return;
          } else if (
            uwCommentsCopy[0]?.comment &&
            e.target.value.trim() !== uwCommentsCopy[0]?.comment
          ) {
            uwCommentsCopy.unshift({
              comment: e.target.value.trim(),
              commentTimestamp: currentTimestamp,
              previousComment: "true",
              email: loggedInEmail,
            });
          } else if (
            uwCommentsCopy[0] &&
            typeof uwCommentsCopy[0] === "string"
          ) {
            e.target.value.trim() !== uwCommentsCopy[0] &&
              uwCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                previousComment: "true",
                email: loggedInEmail,
              });
          } else {
            console.log("I m returning as value is 0");
            return;
          }
        } else if (typeof uwCommentsCopy[0] === "string") {
          e.target.value.trim() !== uwCommentsCopy[0] &&
            uwCommentsCopy.unshift({
              comment: e.target.value.trim(),
              commentTimestamp: currentTimestamp,
              email: loggedInEmail,
            });
        } else {
          e.target.value.trim() !== uwCommentsCopy[0].comment &&
            uwCommentsCopy.unshift({
              comment: e.target.value.trim(),
              commentTimestamp: currentTimestamp,
              email: loggedInEmail,
            });
        }

        dataToSave.user_email_id = props.rowData.user_email_id;
        dataToSave.sortKeyList = props.rowData.uuidCarriers;
        dataToSave.uw_comments = uwCommentsCopy;
        dataToSave.uw_comments && props.callback(dataToSave);
      }}
    ></textarea>
  );
}

export default UnderWriterTab;
