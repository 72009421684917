import React, { Component, useEffect } from "react";
import numeral from "numeral";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import MaterialTable from "material-table";
import { useState } from "react";
import { quoteView } from "../../utils/form_comp_quote";
import carrierMap from "../../utils/carrierMap.json";
import SmallLoader from "../common/SmallLoader";
import QuoteActionButtons from "../common/QuoteActionButtons";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../config";
import { blobPdfFromBase64String } from "../../utils/common";
import { isDeepEquals } from "../../utils/isDeepEquals";
import mono_states from "../../utils/mono_states.json";
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import $ from "jquery";
import moment from "moment";

let isMobile = window.innerWidth < 900;
// const Merger = require("./PdfMerger");
// import PdfMerger from "./PdfMerger.jsx";
const { mergePdf } = require("./MergePdf");
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

const programMapping = require("../../utils/carrierList.json").programMapping;
const carrierNameMap = require("../../utils/carrierList.json").carrierNameMap;

class QuoteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      selectedCarrier: this.props.selectedCarrier,
      isLoading: true,
      columnseleted: " ",
      rowDataList: [],
      columns: [
        {
          title: "",
          field: "name",
          align: "center",
          headerStyle: {
            backgroundColor: "#2f75b5",
          },
          render: (rowData) => (
            <div className="d-flex align-items-center fontName-Size justify-content-end ">
              <div className="row" style={{ width: "100%" }}>
                <div className="col-6" style={{ textAlign: "center" }}>
                  {rowData.name === "MASTER" ? "Master States" : rowData.name}
                </div>
                <div className="col-6 row">
                  <div
                    className="col-12 smaller-text-quote-table green-text"
                    style={
                      rowData.name === "MASTER" ? { marginTop: "5px" } : {}
                    }
                  >
                    Premium
                  </div>
                  <div
                    className="col-12 smaller-text-quote-table blue-text"
                    style={{ marginTop: "5px" }}
                  >
                    Payroll
                  </div>
                </div>
              </div>

              <span>&emsp;</span>
              {/* {rowData.name !== "TOTAL" && false && (
                <input
                  className="chekcbox-input "
                  type="checkbox"
                  checked={rowData.checked.name}
                  onChange={(e) =>
                    this.handleRowCheck(rowData, e.target.checked)
                  }
                />
              )}
              {rowData.name === "TOTAL" && (
                <div style={{ width: "16px" }}></div>
              )} */}
            </div>
          ),
        },
      ],
      download_data: [],
    };
    this.quoteView = quoteView.bind(this);
  }

  handleRowCheck = (rowData, value) => {
    let rowDataListCopy = JSON.parse(JSON.stringify(this.state.rowDataList));
    let index = rowData.tableData.id;
    for (let header in rowDataListCopy[index].checked) {
      rowDataListCopy[index].checked[header] = value;
    }
    this.setState({ rowDataList: rowDataListCopy });
  };

  handleCheck = (rowData, key, value) => {
    let rowDataListCopy = JSON.parse(JSON.stringify(this.state.rowDataList));
    let index = rowData.tableData.id;
    rowDataListCopy[index].checked[key] = value;
    if (!value) {
      rowDataListCopy[index].checked.name = value;
    }
    this.setState({ rowDataList: rowDataListCopy });
  };

  prepareRowDataList = (quoteTableData, programs) => {
    let peoData = this.props.peoData;
    let rowDataList = [];
    let masterRow, totalRow;
    try {
      for (let state in quoteTableData) {
        if (state.toUpperCase() === "MASTER") {
          masterRow = {
            name: state.toUpperCase(),
            ...quoteTableData[state],
          };
        } else if (state.toUpperCase() === "TOTAL") {
          totalRow = {
            name: state.toUpperCase(),
            ...quoteTableData[state],
          };
        } else {
          rowDataList.push({
            name: state.toUpperCase(),
            ...quoteTableData[state],
          });
        }
      }

      for (let row of rowDataList) {
        if (row.name !== "MASTER" && row.name !== "TOTAL") {
          for (let program of programs) {
            if (
              !row?.[program]?.premium &&
              row?.[program]?.premium !== 0 &&
              peoData?.[program]?.[row.name.toLowerCase()]
            ) {
              row[program] = { premium: "Included in Master" };
            }
          }
        }
      }

      rowDataList.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });

      if (masterRow) {
        rowDataList.unshift(masterRow);
      }
      if (totalRow) {
        rowDataList.push(totalRow);
      }
    } catch (error) {
      console.log(error);
    }
    return rowDataList;
  };

  updateColumnSelection = (selectedProgram, programs) => {
    try {
      let columns = [...this.state.columns];

      for (let column of columns) {
        if (programs.includes(column.field)) {
          if (column.field !== this.state.columnseleted) {
            console.log(selectedProgram);
            if (column.field === selectedProgram) {
              column.headerStyle = {
                ...column.headerStyle,
                // backgroundColor: "#2f75b5",
                // borderRadius: "20px",
                // // boxShadow:
                // //   " 6px 10px 8px #484848 , -6px 10px 8px  #484848 ,0px -10px 8px #484848",
                // transition: "all ease 0.2s",
                // fontSize: "1.7rem",
                // // textShadow: "0.8px 0.8px",
                // borderLeft: "1px solid white",
                // borderRight: "1px solid white",
                // borderTop: "1px solid white",
                // borderBottom: "1px solid white",
              };
              column.cellStyle = {
                ...column.cellStyle,
                // borderLeft: "1px solid white",
                // borderRight: "1px solid white",
                // borderTop: "1px solid white",
                // borderBottom: "1px solid white",
                // // boxShadow: "5px 10px 7px #484848 ,-5px 10px 7px #484848",
                // transition: "all ease 0.2s",
                // // textShadow: "0.8px 0.8px",
              };

              this.setState({ columnseleted: selectedProgram });
            } else {
              console.log(selectedProgram, " chekcing the value one");

              column.headerStyle = {
                ...column.headerStyle,
                boxShadow: "none",
                backgroundColor: "#2f75b5",
                fontSize: "1.5rem",
                borderRadius: "10px",
                textShadow: "0px 0px",
                transition: "all ease 0.2s",
                borderLeft: "1px solid white",
                borderRight: "1px solid white",
                borderTop: "1px solid white",
                borderBottom: "1px solid white",
              };

              column.cellStyle = {
                ...column.cellStyle,
                boxShadow: "none",
                borderLeft: "1px solid white",
                borderRight: "1px solid white",
                borderTop: "1px solid white",
                borderBottom: "1px solid white",
                textShadow: "0px 0px",
                transition: "all ease 0.2s",
              };
              this.setState({ columnseleted: !selectedProgram });
            }
          } else {
            if (column.field === selectedProgram) {
              {
                column.headerStyle.backgroundColor = "#2f75b5";
                column.headerStyle.boxShadow = "none";
                column.cellStyle.boxShadow = "none";
                column.headerStyle.borderLeft = "1px solid white";
                column.headerStyle.borderRight = "1px solid white";
                column.cellStyle.borderLeft = "1px solid white";
                column.cellStyle.borderRight = "1px solid white";
                column.headerStyle.borderTop = "1px solid white";
                column.headerStyle.fontSize = "1.5rem";

                column.headerStyle.borderRadius = "10px";
                column.cellStyle.textShadow = "none";
                column.headerStyle.textShadow = "none";
                column.cellStyle.transition = "all ease 0.2s";
                column.headerStyle.transition = "all ease 0.2s";
              }
            } else {
              column.headerStyle.backgroundColor = "#2f75b5";
              column.headerStyle.boxShadow = "none";
              column.cellStyle.boxShadow = "none";
              column.headerStyle.borderLeft = "1px solid white";
              column.headerStyle.borderRight = "1px solid white";
              column.cellStyle.borderLeft = "1px solid white";
              column.cellStyle.borderRight = "1px solid white";
              column.headerStyle.borderTop = "1px solid white";
              column.headerStyle.fontSize = "1.5rem";

              column.headerStyle.borderRadius = "10px";
              column.cellStyle.textShadow = "none";
              column.headerStyle.textShadow = "none";
              column.cellStyle.transition = "all ease 0.2s";
              column.headerStyle.transition = "all ease 0.2s";
            }
          }
        }
      }

      this.setState({ columns });
    } catch (error) {
      console.log(error);
    }
  };

  handleCarrierClick = (header, value, state) => {
    const { programStateType } = this.props;
    let { rowDataList } = this.state;
    let selectedCarrier;
    let isAllSelected = true;
    let response = {};

    for (let row of rowDataList) {
      if (((state && row.name === state) || !state) && row[header]) {
        row.checked[header] = value;
      }
    }

    for (let row of rowDataList) {
      if (row.name !== "TOTAL" && row.checked[header] === false) {
        isAllSelected = false;
        break;
      }
    }

    for (let row of rowDataList) {
      let name = row.name.toLowerCase();
      if (row.name !== "TOTAL")
        for (let program in row.checked) {
          if (row[program].premium !== "Included in Master") {
            if (program !== "name" && row.checked[program]) {
              if (!response[program]) {
                response[program] = [];
              }
              if (name === "master") {
                let l1 =
                  programStateType[program][name]?.without_fundrate || [];
                let l2 = programStateType[program][name]?.with_fundrate || [];
                response[program].push(...l1, ...l2);
              } else {
                response[program].push(name);
              }
            }
          }
        }
    }

    let value_to_send = false;
    if (state) {
      if (isAllSelected) value_to_send = true;
      else value_to_send = false;
    }
    for (let program in programMapping) {
      if (header === program) {
        selectedCarrier = programMapping[program].carrier;
        break;
      }
    }
    this.props.updateSelectedCarrierMap(
      selectedCarrier,
      state ? value_to_send : value,
      header,
      state?.toLowerCase(),
      value,
      response[header]
    );

    this.setState({ rowDataList });
  };

  prepareColumns = (headers, currentCarrierMap, programDateMap) => {
    let columns = [
      {
        title: (
          <div className="row" style={{ marginInline: "1%" }}>
            <div className="col-6 text-align-center small-text-quote-table">
              State
            </div>
            <div className="col-6 text-align-center small-text-quote-table">
              Metrics
            </div>
          </div>
        ),
        field: "name",
        align: "center",
        headerStyle: {
          backgroundColor: "#2f75b5",
          color: "#FFF",
          fontWeight: "bold",
          fontFamily: "MontserratSemiBold",
          borderLeft: "1px solid white",
          borderRight: "1px solid white",
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
          fontSize: "1.7rem",
          borderRadius: "13px",
          boxShadow: "none",
          // padding: "16px 0px 16px 0px",
          textShadow: "0px 0px",
        },
        cellStyle: {
          borderLeft: "1px solid white",
          borderRight: "1px solid white",
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
        },
        render: (rowData) => (
          // <div className="d-flex align-items-center fontName-Size justify-content-end ">
          <div className="row">
            <div
              className={
                (mono_states.includes(rowData.name.toLowerCase())
                  ? "red-text"
                  : "brown-text") +
                " col-6 text-align-center small-text-quote-table"
              }
            >
              {rowData.name === "MASTER" ? "Master States" : rowData.name}
            </div>
            <div className="col-6 row text-align-center">
              {rowData.name !== "TOTAL" && (
                <div className="col-12 small-text-quote-table dark-blue-text constant-margin-top-bottom">
                  State Type
                </div>
              )}
              <div
                className={
                  (mono_states.includes(rowData.name.toLowerCase())
                    ? "red-text"
                    : "green-text") +
                  " col-12 small-text-quote-table constant-margin-top-bottom"
                }
              >
                Premium
              </div>
              <div className="col-12 small-text-quote-table blue-text constant-margin-top-bottom">
                Payroll
              </div>
            </div>
          </div>
          // </div>
        ),
      },
    ];
    let { acordSelectedState } = this.props;
    let currProspect = sessionStorage.getItem("currProspect");
    try {
      currProspect = JSON.parse(currProspect);
    } catch (error) {
      currProspect = {};
    }

    let demoPeo = currProspect?.peoDetails?.selectedPeo === "demo";

    let returnShortenCarrieName = (name) => {
      let nameMap = {
        "Zurich American": "Zurich Am.",
        "American Zurich": "Am. Zurich",
        "Berkley Casualty": "Berkley",
        "Carolina Casualty": "Carolina",
        "Midwest Employers Casualty": "Midwest",
      };
      if (name in nameMap) return nameMap[name];
      return name;
    };

    let programDate = (header) => {
      if (header) {
        if (programDateMap[header.toLowerCase()]) {
          return `\n (${programDateMap[header.toLowerCase()]})`;
        } else {
          return "";
        }
      }
    };

    try {
      for (let header of headers) {
        // let isAllSelected = true;

        // for (let row of rowDataList) {
        //   if (row.name !== "TOTAL" && row.checked[header] === false) {
        //     isAllSelected = false;
        //     break;
        //   }
        // }

        // console.log("header: ", header, isAllSelected);

        // let programDate = programDateMap[header.toLowerCase()] || !null ? ` (${programDateMap[header.toLowerCase()]})` : "";

        columns.push({
          title: (
            <div className="d-flex w-100 align-items-center">
              <div
                id="columnselect"
                className="w-75 small-text-quote-table"
                onClick={() => this.updateColumnSelection(header, headers)}
                style={{ float: "left" }}
              >
                {demoPeo
                  ? programMapping[header].carrier?.toUpperCase()
                  : returnShortenCarrieName(
                      programMapping[header].actualCarrierName
                    )}
                <p>{programDate(header)}</p>
              </div>
              <div className="d-flex w-25 align-items-center justify-content-center">
                <input
                  className="chekcbox-input"
                  type="checkbox"
                  checked={currentCarrierMap[programMapping[header].carrier]}
                  onChange={(e) =>
                    this.handleCarrierClick(header, e.target.checked)
                  }
                />
              </div>
            </div>
          ),
          field: header,
          align: "center",
          headerStyle: {
            backgroundColor: "#2f75b5",
            color: "#FFF",
            fontWeight: "bold",
            fontFamily: "MontserratSemiBold",
            borderLeft: "1px solid white",
            borderRight: "1px solid white",
            borderTop: "1px solid white",
            borderBottom: "1px solid white",
            fontSize: "1.7rem",
            borderRadius: "13px",
            boxShadow: "none",
            // padding: "16px 0px 16px 0px",
            textShadow: "0px 0px",
          },
          cellStyle: {
            borderLeft: "1px solid white",
            borderRight: "1px solid white",
            borderTop: "1px solid white",
            borderBottom: "1px solid white",
            borderRadius: "5px",
            boxShadow: "none",
            fontSize: "1.5rem",
            textShadow: "0px 0px",
            padding: "16px 0px 16px 0px",
            // display: "flex",
            // justifyContent: "center"
          },
          render: (rowData) => {
            return rowData?.[header]?.premium ||
              rowData?.[header]?.premium === 0 ? (
              rowData[header].premium !== "Included in Master" ? (
                <div
                  className={
                    "d-flex w-100 " +
                    (rowData[header].isMasterWithFundRate ? "cream-color" : "")
                  }
                >
                  <div
                    className="d-flex w-100 flex-column"
                    style={{ float: "left" }}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="w-75 dark-blue-text small-text-quote-table constant-margin-top-bottom">
                        {rowData[header]?.state_type?.toUpperCase() || ""}
                      </div>
                      <div className="w-25">
                        {rowData.name !== "TOTAL" && (
                          <input
                            className="chekcbox-input"
                            type="checkbox"
                            checked={rowData.checked[header]}
                            onChange={(e) =>
                              this.handleCarrierClick(
                                header,
                                e.target.checked,
                                rowData.name
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <div
                        className={
                          (rowData[header]?.state_type?.toUpperCase() === "MONO"
                            ? "red-text"
                            : "green-text") +
                          " small-text-quote-table w-75 constant-margin-top-bottom"
                        }
                      >
                        {rowData[header].premium === 0
                          ? "N/A"
                          : numeral(rowData[header].premium).format("$0,0")}
                      </div>
                      <div className="w-25"></div>
                    </div>
                    <div>
                      <div className=" w-75 small-text-quote-table blue-text constant-margin-top-bottom">
                        {this.formatPayroll(rowData[header].payroll)}
                      </div>
                      <div className="w-25"></div>
                    </div>
                  </div>
                  {/* <div className="w-25 text-align-left"> */}
                  {/* <span>
                      {rowData.name !== "TOTAL" && <input
                        className="chekcbox-input"
                        type="checkbox"
                        style={{marginLeft:"0.16rem"}}
                        checked={rowData.checked[header]}
                        onChange={(e) =>
                          this.handleCarrierClick(header, e.target.checked, rowData.name)
                        }
                      />}
                    </span> */}
                  {/* </div> */}
                </div>
              ) : (
                <div className="small-text-quote-table">
                  {rowData[header].premium}
                </div>
              )
            ) : (
              <div></div>
            );
          },
        });
      }
    } catch (error) {
      console.log(error);
    }

    return columns;
  };

  formatPayroll = (num) => {
    if (num) {
      num = Number(num);
      return numeral(num).format(num < 1000 ? "$0,0" : "$0,0ak");
    }
    return num;
  };

  prepareTableData = (
    quoteTableData,
    programs,
    currentCarrierMap,
    programDateMap
  ) => {
    let rowDataList = this.prepareRowDataList(quoteTableData, programs);
    let columns = this.prepareColumns(
      programs,
      currentCarrierMap,
      programDateMap
    );
    console.log("rdl", rowDataList);
    console.log("rdl", columns);
    this.setState({
      rowDataList,
      columns,
      isLoading: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !isDeepEquals(prevProps.quoteTableData, this.props.quoteTableData) ||
      !isDeepEquals(
        prevProps.currentCarrierMap,
        this.props.currentCarrierMap
      ) ||
      !isDeepEquals(prevProps.programDateMap, this.props.programDateMap)
    ) {
      this.prepareTableData(
        this.props.quoteTableData,
        this.props.programs,
        this.props.currentCarrierMap,
        this.props.programDateMap
      );
    }
  }

  exportToPdf = (program, listToShow = [], programAndPages) => {
    return new Promise(async (resolve, reject) => {
      let mainElement = document.createElement("div");
      mainElement.setAttribute("id", `temp-div-download-${program}`);
      try {
        let currProspect = sessionStorage.getItem("currProspect");
        try {
          currProspect = JSON.parse(currProspect);
        } catch (error) {
          currProspect = {};
        }

        let demoPeo = currProspect?.peoDetails?.selectedPeo === "demo";

        mainElement.setAttribute("type", "hidden");

        document.body.append(mainElement);

        let navbarClone = document.getElementById("navbarMain").cloneNode(true);
        // navbarClone
        //   .querySelector("#temperory")
        //   .setAttribute("style", "display: none");
        navbarClone.style.width = "1080px";
        navbarClone.style.margin = "0 0 35px 0";
        navbarClone
          .querySelector("#imagesizelogoicomp")
          .setAttribute("style", "height: 100px !important");

        try {
          navbarClone
            .querySelector("#imagesizelogo")
            .setAttribute("style", "height: 160px !important");
        } catch (error) {}

        navbarClone.childNodes[0].childNodes[1].remove();

        mainElement.appendChild(navbarClone);

        const pdf = new jsPDF("p", "mm", "a4");

        mainElement.appendChild(
          document.getElementById(program + "_carrier").cloneNode(true)
        );
        for (let page = 0; page < programAndPages[program]; page++) {
          let input = document
            .getElementById(program + "_program" + page)
            .cloneNode(true);

          mainElement.appendChild(input);

          let saveAll = page === programAndPages[program] - 1;

          let canvas = await html2canvas(mainElement, {
            width: 1080,
            height: 1527,
          });

          // .then(async (canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          const margin = 8;
          const documentHeight = pdf.internal.pageSize.getHeight();
          const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;

          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
          let heightLeft = pdfHeight;
          let position = 5;

          pdf.addImage(imgData, "JPEG", margin, position, pdfWidth, pdfHeight);

          heightLeft -= documentHeight;
          console.log(heightLeft);

          while (heightLeft >= 0) {
            position = heightLeft - pdfHeight + 5;
            pdf.addPage();
            pdf.addImage(
              imgData,
              "JPEG",
              margin,
              position,
              pdfWidth,
              pdfHeight
            );
            heightLeft -= documentHeight;
          }

          if (saveAll) {
            let mainElement1 = document.createElement("div");
            mainElement1.setAttribute(
              "id",
              `temp-div-download-${program}-info`
            );

            mainElement1.setAttribute("type", "hidden");

            let navbarClone1 = document
              .getElementById("navbarMain")
              .cloneNode(true);
            // navbarClone1
            //   .querySelector("#temperory")
            //   .setAttribute("style", "display: none");
            navbarClone1.style.width = "1080px";
            navbarClone1.style.margin = "0 0 50px 0";
            navbarClone1
              .querySelector("#imagesizelogoicomp")
              .setAttribute("style", "height: 100px !important");
            try {
              navbarClone1
                .querySelector("#imagesizelogo")
                .setAttribute("style", "height: 160px !important");
            } catch (error) {}

            navbarClone1.childNodes[0].childNodes[1].remove();

            document.body.append(mainElement1);

            let statement = document
              .getElementById(`${program}_info`)
              .cloneNode(true);

            mainElement1.appendChild(navbarClone1);

            mainElement1.appendChild(
              document.getElementById(program + "_carrier").cloneNode(true)
            );

            mainElement1.appendChild(statement);

            let canvas1 = await html2canvas(mainElement1, {
              width: 1080,
              height: 1527,
            });

            const notesImage = canvas1.toDataURL("image/jpeg");

            pdf.addPage();
            pdf.addImage(
              notesImage,
              "JPEG",
              8,
              5,
              pdfWidth,
              (canvas1.height * pdfWidth) / canvas1.width
            );

            // pdf.save(`Net Rates - ${program}.pdf`);
            let blobPdf = new Blob([pdf.output("blob")], {
              type: "application/pdf",
            });

            try {
              mainElement1.remove();
            } catch (e) {}

            listToShow.unshift(blobPdf);

            console.log("listToShow: ", listToShow);

            let finalPdf = await mergePdf(listToShow);
            // console.log("finalPdf: ", finalPdf);
            var url = URL.createObjectURL(finalPdf);
            const link = document.createElement("a");
            // create a blobURI pointing to our Blob
            link.href = url;
            link.download = `${
              demoPeo ? programMapping[program].carrier : program.split("-")[1]
            }.pdf`;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);

            try {
              mainElement.remove();
            } catch (e) {}
          } else {
            mainElement.removeChild(input);
            pdf.addPage();
          }
        }
        resolve();
      } catch (error) {
        try {
          mainElement.remove();
        } catch (e) {}
        console.log("error: ", error);
        reject();
      }
    });
  };

  prepareCheckedStatesDownload = () => {
    const { rowDataList } = this.state;
    const { programStateType } = this.props;
    let master_state_to_include = {};
    let response = {},
      _reponse = {};
    try {
      for (let row of rowDataList) {
        let name = row.name.toLowerCase();
        for (let program in row.checked) {
          if (
            (row[program].premium !== "Included in Master" &&
              row[program].premium !== 0) ||
            program === "Insurecomp-Amtrust"
          ) {
            if (program !== "name" && row.checked[program]) {
              if (!response[program]) {
                response[program] = {};
              }
              if (row[program].state_type === "master") {
                response[program].master = programStateType[program]["master"];

                if (!(program in master_state_to_include))
                  master_state_to_include[program] = [];

                master_state_to_include[program].push(name);
              } else {
                response[program][name] = [name];
              }
            }
          }
        }
      }

      _reponse = JSON.parse(JSON.stringify(response));

      for (let prgm in _reponse) {
        if (_reponse[prgm]?.master) {
          if (_reponse[prgm].master?.with_fundrate?.length) {
            let state_to_include = [];
            for (let state of _reponse[prgm].master.with_fundrate) {
              if (master_state_to_include?.[prgm]?.includes(state)) {
                state_to_include.push(state);
              }
            }

            if (state_to_include.length)
              _reponse[prgm].master.with_fundrate = state_to_include;
            else delete _reponse[prgm].master.with_fundrate;
          }
          if (_reponse[prgm].master?.without_fundrate?.length) {
            let state_to_include = [];
            for (let state of _reponse[prgm].master.without_fundrate) {
              if (master_state_to_include?.[prgm]?.includes(state)) {
                state_to_include.push(state);
              }
            }

            if (state_to_include.length)
              _reponse[prgm].master.without_fundrate = state_to_include;
            else delete _reponse[prgm].master.without_fundrate;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    return _reponse;
  };

  getCarrierFromProgram = (program) => {
    for (let prgm in programMapping) {
      if (prgm === program) return programMapping[prgm].carrier;
    }
    return "";
  };

  downloadNetRates = async () => {
    try {
      let currProspect = sessionStorage.getItem("currProspect");
      try {
        currProspect = JSON.parse(currProspect);
      } catch (error) {
        currProspect = {};
      }

      let hasProrateValue =
        sessionStorage.getItem("hasProrateValue") === "true" ? true : false;

      let selectedPeo = currProspect?.peoDetails?.selectedPeo;

      this.setState({ displayRatesLoader: true });
      let { excludedClassCodeMap, currentCarrierMap } = this.props;
      let currentCarrier;
      for (let carrier in currentCarrierMap) {
        if (currentCarrierMap[carrier]) {
          currentCarrier = carrier;
        }
      }

      let { filesToShow, calculated_net_rate } = await this.downloadQuotes();
      // console.log("filesToShow: ", filesToShow);
      let programStateResponse = this.prepareCheckedStatesDownload();

      let download_data = [];
      let download_data_list = {};
      let peoData = JSON.parse(JSON.stringify(this.props.peoData));
      let master_without_fundrates = {},
        master_with_fundrates = {},
        mcp_data = {};

      let master_data = [],
        carrier_data = [];

      try {
        for (let prog in calculated_net_rate) {
          for (let state in calculated_net_rate[prog]) {
            for (let row of peoData[prog][state]) {
              row.net_rate = calculated_net_rate[prog][state][row.wc_code];
              try {
                row.teap = numeral(row.payroll)
                  .multiply(row.net_rate)
                  .divide(100)
                  .value();
              } catch (error) {
                console.log(error);
              }
            }
          }
        }

        // console.log(calculated_net_rate, peoData)
      } catch (error) {
        console.log(error);
      }

      for (let program in programStateResponse) {
        for (let stateType in programStateResponse[program]) {
          let stateList = [];

          if (stateType === "master") {
            if (!(program in master_without_fundrates))
              master_without_fundrates[program] = [];
            if (!(program in master_with_fundrates))
              master_with_fundrates[program] = [];

            if (programStateResponse[program][stateType].without_fundrate) {
              stateList.push(
                ...programStateResponse[program][stateType].without_fundrate
              );
              master_without_fundrates[program] =
                programStateResponse[program][stateType].without_fundrate;
            }
            if (programStateResponse[program][stateType].with_fundrate) {
              stateList.push(
                ...programStateResponse[program][stateType].with_fundrate
              );
              master_with_fundrates[program] =
                programStateResponse[program][stateType].with_fundrate;
            }
          } else {
            if (!(program in mcp_data)) mcp_data[program] = [];
            stateList = programStateResponse[program][stateType];
            if (stateType !== "total") mcp_data[program].push(stateType);
          }
          for (let state of stateList) {
            let curr_state_info_list =
              peoData && peoData[program] && peoData[program][state]
                ? peoData[program][state]
                : [];

            if (!(program in download_data_list))
              download_data_list[program] = [];

            for (let row of curr_state_info_list) {
              download_data_list[program].push(row);
            }
          }
        }
      }

      let programAndPages = {};

      for (let program in download_data_list) {
        let carrierInUse = this.getCarrierFromProgram(program);

        let programData = download_data_list[program];

        let check_map = {
          master_without_fundrates:
            master_without_fundrates[program]?.length > 0,
          master_with_fundrates: master_with_fundrates[program]?.length > 0,
          mcp_data: mcp_data[program]?.length > 0,
        };

        let excludedDataList = [];
        if (
          excludedClassCodeMap?.[currentCarrier] &&
          Object.keys(excludedClassCodeMap[currentCarrier] || {}).length > 0
        ) {
          for (let state in excludedClassCodeMap[currentCarrier]) {
            for (let classCode of excludedClassCodeMap[currentCarrier][state]) {
              let value = `${state.toUpperCase()}-${classCode}`;
              excludedDataList.push(value);
            }
          }
        }

        // console.log("master_with_fundrates[program]: ", master_with_fundrates[program]);

        master_data.push(
          <div
            id={`${program}_info`}
            className="font-family-montserrat-regular"
            style={{
              fontSize: "18px",
              width: "1080px",
            }}
          >
            <ul>
              {(check_map["master_without_fundrates"] ||
                check_map["mcp_data"]) && (
                <li
                  className="font-family-montserrat-regular"
                  style={{ fontSize: "18px" }}
                >
                  {`The below pricing is only an indication and is subject to change based on documents submitted.`}
                </li>
              )}
              {master_without_fundrates[program]?.length > 0 && (
                <li
                  className="font-family-montserrat-regular"
                  style={{ fontSize: "18px" }}
                >
                  {`Please find the detailed quotes for ${master_without_fundrates[
                    program
                  ].map((ele) => ele.toUpperCase()) || ""}.`}
                </li>
              )}
              {master_with_fundrates[program]?.length > 0 && (
                <li
                  className="font-family-montserrat-regular"
                  style={{ fontSize: "18px" }}
                >
                  {`${master_with_fundrates[program].map((ele) =>
                    ele.toUpperCase()
                  )} are already part of the master policy, please find their net rates above.`}
                </li>
              )}
              {mcp_data[program]?.length > 0 && (
                <li
                  className="font-family-montserrat-regular"
                  style={{ fontSize: "18px" }}
                >
                  {`The quotes for ${mcp_data[program].map((ele) =>
                    ele.toUpperCase()
                  )} will be found towards the end of the document.`}
                </li>
              )}
              {excludedDataList.length > 0 && (
                <li
                  className="font-family-montserrat-regular"
                  style={{ fontSize: "18px" }}
                >
                  {`Some class codes have been excluded for this carrier: ${excludedDataList.map(
                    (e) => e
                  )}`}
                </li>
              )}
              {
                <li
                  className="font-family-montserrat-regular"
                  style={{ fontSize: "18px" }}
                >
                  {
                    "Net rate is inclusive of all elements, i,e., including Catastrophe, TRIA, Expense constant, Tax and assessment."
                  }
                </li>
              }
            </ul>
          </div>
        );

        carrier_data.push(
          <div
            id={`${program}_carrier`}
            className="font-family-montserrat-regular"
            style={{
              fontSize: "20px",
              width: "1080px",
              marginBottom: "20px",
              marginInline: "5px",
              fontWeight: "bold",
            }}
          >
            <div>
              <span>{"Insured: "}</span>
              <span>
                {selectedPeo === "demo"
                  ? "Demo"
                  : JSON.parse(sessionStorage.getItem("currProspect"))
                      ?.companyProfile?.companyName?.value}
              </span>
            </div>
            <u>
              <span>{"Carrier: "}</span>
              <span>
                {selectedPeo === "demo"
                  ? carrierInUse.toUpperCase()
                  : carrierNameMap[carrierInUse]}
              </span>
            </u>
          </div>
        );

        let len = programData.length;
        let indx = 0;
        const increment = 30;
        let pageCount = 0;

        let total_values_row = {
          payroll: 0,
          manual_premium: 0,
          teap: 0,
          annualized_teap: 0,
        };

        while (len > 0) {
          let dataToShow = programData.slice(indx, indx + increment);

          dataToShow.map((obj) => {
            total_values_row.payroll = numeral(roundVaues(obj.payroll))
              .add(total_values_row.payroll)
              .value();
            total_values_row.manual_premium = numeral(
              roundVaues(obj.manual_premium)
            )
              .add(total_values_row.manual_premium)
              .value();
            total_values_row.teap = numeral(roundVaues(obj.teap))
              .add(total_values_row.teap)
              .value();

            //we have prorated in backend
            if (hasProrateValue) {
              total_values_row.annualized_teap = numeral(
                roundVaues(obj.annualized_eap)
              )
                .add(total_values_row.annualized_teap)
                .value();

              //if either master with fundrate Or Minimum premium sum has been applied
              if (obj.masterWithFundRateOrMinimumPremSum) {
                total_values_row.annualized_teap = numeral(roundVaues(obj.teap))
                  .add(total_values_row.annualized_teap)
                  .value();

                total_values_row.teap = numeral(
                  roundVaues(total_values_row.teap)
                )
                  .subtract(obj.teap)
                  .value();

                if (obj.masterWithFundRateProratedEAP) {
                  total_values_row.teap = numeral(
                    roundVaues(obj.masterWithFundRateProratedEAP)
                  )
                    .add(total_values_row.teap)
                    .value();
                }
              }
            }
          });

          download_data.push(
            <DataTable
              data={dataToShow}
              id={program + "_program" + pageCount}
              lastPage={len - increment <= 0}
              total_values_row={total_values_row}
              hasProrateValue={hasProrateValue}
            />
          );
          indx += increment;
          len -= increment;
          pageCount += 1;
        }

        programData.push({ ...total_values_row, state: "total" });
        console.log(program);
        console.dir(programData, { depth: null });

        if (!(program in programAndPages)) programAndPages[program] = pageCount;
      }

      let promiseListExport = [];

      this.setState({ download_data, master_data, carrier_data }, async () => {
        for (let program in download_data_list) {
          if (program === "Insurecomp-Amtrust") {
            promiseListExport.push(
              this.exportToPdf(program, [], programAndPages).catch((err) =>
                console.log(err)
              )
            );
          } else {
            promiseListExport.push(
              this.exportToPdf(
                program,
                filesToShow[program],
                programAndPages
              ).catch((err) => console.log(err))
            );
          }
        }

        await Promise.all(promiseListExport)
          .then((res) => {
            this.setState({ download_data: [] }, () => {
              this.setState({ displayRatesLoader: false });
            });
          })
          .catch((err) => {
            this.setState({ download_data: [] }, () => {
              this.setState({ displayRatesLoader: false });
            });
          });
      });
    } catch (error) {
      console.log("error: ", error);
      this.setState({ download_data: [] }, () => {
        this.setState({ displayRatesLoader: false });
      });
    }
  };

  isOnlyMasterWithFundRateOnlyOrNothing = () => {
    let programStateResponse = this.prepareCheckedStatesDownload();

    let hasOnlyMasterWithFundRate = false;

    for (let program in programStateResponse) {
      for (let stateType in programStateResponse[program]) {
        if (stateType === "total") continue;
        if (stateType === "master") {
          if (programStateResponse[program][stateType].without_fundrate) {
            return { v_quote: false, g_net_rate: false };
          } else if (programStateResponse[program][stateType].with_fundrate) {
            hasOnlyMasterWithFundRate = true;
          }
        } else if (stateType) {
          return { v_quote: false, g_net_rate: false };
        }
      }
    }

    if (hasOnlyMasterWithFundRate) {
      return { v_quote: true, g_net_rate: false };
    }

    return { v_quote: true, g_net_rate: true };
  };

  downloadQuotes = async () => {
    try {
      let currProspect = sessionStorage.getItem("currProspect");
      try {
        currProspect = JSON.parse(currProspect);
      } catch (error) {
        currProspect = {};
      }
      let response = {};
      let demoPeo = currProspect?.peoDetails?.selectedPeo === "demo";
      let checkedProgramData = this.prepareCheckedStatesDownload();
      let timestamp = JSON.parse(sessionStorage.getItem("quoteData")).date;
      let userId = sessionStorage.getItem("user_id");
      let quotePromises = [];
      let calculated_net_rate = {};

      let fetchData = async (response, id, program, key) => {
        try {
          // Keep checking the data until it's no longer in "processing"
          const res = await axios.get(awsUrl + `/api/getPDFQuotesData/${id}`);

          if (res.data === "processing") {
            return new Promise((resolve) => {
              setTimeout(async () => {
                resolve(await fetchData(response, id, program, key)); // Recursive call with a delay
              }, 5000);
            });
          } else if (res.data === "error") {
            throw new Error("Oops!! Timed Out. Please try again.");
          } else {
            try {
              let urlData = res.data;
              let urlResponse = await axios.get(urlData.url, {
                responseType: "blob",
              });
              const pdfBlob = new Blob([urlResponse.data], {
                type: "application/pdf",
              });
              // console.log("pdfBlob", pdfBlob);

              if (pdfBlob) {
                let file = pdfBlob;
                let fileURL = URL.createObjectURL(file);

                // Update the calculated_net_rate with additional data
                calculated_net_rate[program] = {
                  ...calculated_net_rate[program],
                  ...res.data.additional_data.calc_net_rates,
                };

                // console.log(
                //   "Return Response:",
                //   { program, key, fileURL, file },
                //   calculated_net_rate
                // );

                // response[program] = { file, cnr: calculated_net_rate };
                quotePromises.push({ program, key, fileURL, file });
              }
            } catch (error) {
              throw error;
            }
          }
        } catch (error) {
          console.error("Error:", error);
          throw error; // Ensure the error propagates
        }
      };

      for (let program in checkedProgramData) {
        let masterStateList = [],
          mcpStateList = [];

        if (!calculated_net_rate[program]) calculated_net_rate[program] = {};

        for (let key in checkedProgramData[program]) {
          if (key === "total") continue;

          if (key === "master") {
            masterStateList = checkedProgramData[program][key].without_fundrate;
          } else {
            mcpStateList.push(...checkedProgramData[program][key]);
          }
        }

        for (let { stateList, key } of [
          { stateList: masterStateList, key: "master" },
          { stateList: mcpStateList, key: "mcp" },
        ]) {
          if (stateList?.length > 0) {
            let requestBody = {
              state: stateList,
              peo: programMapping[program].peo,
              carrier: programMapping[program].carrier,
              email: userId,
              timestamp,
              demoPeo,
            };

            const resID = await axios.post(
              awsUrl + `/api/generateQuotePDFId`,
              JSON.stringify({ body: requestBody })
            );

            let id = resID.data.id;

            await fetchData(response, id, program, key);
            // quotePromises.push(resultData);
          }
        }
      }

      let filesToShow = {};
      console.log(quotePromises);

      let quoteFiles = await Promise.all(quotePromises);
      console.log("quoteFiles: ", quoteFiles);
      for (let fileData of quoteFiles) {
        const { fileURL, program, key, file } = fileData;

        if (!(program in filesToShow)) filesToShow[program] = [];
        filesToShow[program].push(file);

        // let blobUrl = fileURL;
        // const link = document.createElement("a");
        // // create a blobURI pointing to our Blob
        // link.href = blobUrl;
        // link.download = `Quote - ${program} (${key === "master" ? "New Addition to Master" : key.toUpperCase()
        //   })`;
        // // some browser needs the anchor to be in the doc
        // document.body.append(link);
        // link.click();
        // link.remove();
        // // in case the Blob uses a lot of memory
        // setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      }
      // this.setState({ displayQuotesLoader: false });
      return { filesToShow, calculated_net_rate };
    } catch (error) {
      console.log(error);
      // this.setState({ displayQuotesLoader: false });
      return {};
    }
  };

  render() {
    const {
      rowDataList,
      columns: columnsState,
      isLoading,
      displayQuotesLoader,
      displayRatesLoader,
      download_data,
      master_data,
      carrier_data,
    } = this.state;

    const {
      masterWithoutFundRateMap,
      programs,
      peoData,
      amtrustError,
    } = this.props;
    let incInMaster = false;

    for (let row of rowDataList) {
      for (let program of programs) {
        if (row?.[program]?.premium === "Included in Master") {
          incInMaster = true;
          break;
        }
      }
    }

    let disable = this.isOnlyMasterWithFundRateOnlyOrNothing();

    let currProspect = sessionStorage.getItem("currProspect");
    try {
      currProspect = JSON.parse(currProspect);
    } catch (error) {
      currProspect = {};
    }

    let demoPeo = currProspect?.peoDetails?.selectedPeo === "demo";
    let isLibPro = currProspect?.peoDetails?.selectedPeo === "insurecomp";

    const columns = columnsState.map((column) => {
      return { ...column };
    });
    const checkManualPremiumIsPresent = (state, peoProgramData) => {
      let result = true;
      for (let key in peoProgramData?.[state]) {
        if (peoProgramData[state][key]["manual_premium"] !== 0) {
          result = false;
          break;
        }
      }
      return result;
    };

    let notes = [];
    for (let program in masterWithoutFundRateMap) {
      let key;
      if (demoPeo) {
        key = `Demo-${programMapping[program].carrier}`;
      } else {
        key = program;
      }

      notes.push(
        <div
          className="notes"
          key={program}
        >{`${key}: ${masterWithoutFundRateMap[program].map((state) =>
          state.toUpperCase()
        )} not part of the existing Master Policy`}</div>
      );
    }

    notes.push(
      <div className="notes">{"Associated payrolls are indicated above"}</div>
    );

    notes.push(
      <div className="notes">
        {
          "The above premium numbers include Employer's Liability Limits calculated for each state independently"
        }
      </div>
    );

    if (currProspect?.peoDetails?.selectedPeo === "rippling") {
      notes.push(
        <div className="notes">
          {"The displayed rates for rippling are on an annual basis"}
        </div>
      );
    }
    if (incInMaster)
      notes.push(
        <div className="notes">
          {
            "Included in Master- Indicates that the state is part of the master states"
          }
        </div>
      );

    let childrenLoc = currProspect?.childrenLoc;
    let excludedStateCarrMap = {};

    if (childrenLoc)
      for (let key in childrenLoc) {
        if (
          mono_states.includes(childrenLoc[key]?.state?.value?.toLowerCase()) &&
          !this.props.uwLogin
        )
          continue;
        if (programs)
          for (let program of programs) {
            let excludedStateResult = checkManualPremiumIsPresent(
              childrenLoc[key]?.state?.value?.toLowerCase(),
              peoData[program]
            );
            if (
              excludedStateResult ||
              !(
                childrenLoc[key]?.state?.value?.toLowerCase() in
                peoData[program]
              )
            ) {
              if (!(program in excludedStateCarrMap))
                excludedStateCarrMap[program] = [];
              if (
                !excludedStateCarrMap[program].includes(
                  childrenLoc[key]?.state?.value?.toLowerCase()
                )
              )
                excludedStateCarrMap[program].push(
                  childrenLoc[key]?.state?.value?.toLowerCase()
                );
            }
          }
      }

    for (let prog in excludedStateCarrMap) {
      if (excludedStateCarrMap[prog].length > 0)
        notes.push(
          <div className="notes">{`${
            prog.split("-")[1]
          } is not licensed to write in ${excludedStateCarrMap[prog]
            .join(", ")
            .toUpperCase()}`}</div>
        );
    }

    notes.push(
      <div className="notes">
        {
          "Net rate is inclusive of all elements, i,e., including Catastrophe, TRIA, Expense constant, Tax and assessment"
        }
      </div>
    );

    let prg_len = "";

    if (columns.length === 2) prg_len = "quote_table_1header";
    else if (columns.length === 3) prg_len = "quote_table_2header";
    else if (columns.length === 4) prg_len = "quote_table_3header";
    else if (columns.length === 5) prg_len = "quote_table_4header";

    return (
      <>
        <div>
          <div
            className="pink-header font-family-montserrat-bold"
            style={{ fontSize: "x-large" }}
          >
            Here's Your Price Indication
          </div>
          {amtrustError && (
            <div className="amtrust-error">
              <MdOutlineReportGmailerrorred size={25} />
              <b>
                Error while fetching Net Rates for Amtrust. Please Try Agian
                Later.
              </b>
            </div>
          )}
          <div className={"container-fluid quotes-table " + prg_len}>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <SmallLoader />
              </div>
            )}
            {rowDataList.length > 0 && (
              <>
                <div className="quote-color-indication-info-row">
                  <div className="cream-color small-box"></div>
                  <div style={{ fontWeight: "bold" }}>
                    States Included in Current Master Policy
                  </div>
                </div>
                <MaterialTable
                  isLoading={isLoading}
                  options={{
                    search: false,
                    paging: false,
                    showTitle: false,
                    sorting: false,
                    selection: false,
                    fixedColumns: {
                      left: 1,
                    },
                  }}
                  columns={columns}
                  data={rowDataList}
                />
                <div className="mt-2 d-flex justify-content-end">
                  <QuoteActionButtons
                    downloadNetRates={this.downloadNetRates}
                    downloadQuotes={this.downloadQuotes}
                    displayQuotesLoader={displayQuotesLoader}
                    displayRatesLoader={displayRatesLoader}
                    disableMap={disable}
                  />
                </div>
                {
                  <div className="font-family-montserrat notes-section mt-4">
                    <b>Notes**:</b>
                    {notes}
                  </div>
                }
              </>
            )}
          </div>
          <div
            className="create-html-container"
            style={{ visibility: "hidden", position: "absolute" }}
          >
            {download_data.length > 0 ? download_data : []}
          </div>
          <div
            className="create-html-container2"
            style={{ visibility: "hidden", position: "absolute" }}
          >
            {master_data?.length ? master_data : []}
          </div>
          <div
            className="create-html-container2"
            style={{ visibility: "hidden", position: "absolute" }}
          >
            {carrier_data?.length ? carrier_data : []}
          </div>
        </div>
      </>
    );
    // let { selectedCarrier, data } = this.state;
    // let { dataFromAPI } = this.props;
    // let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
    // let userId = sessionStorage.getItem("user_id");
    // let dataToShow =
    //   data && selectedCarrier && data[selectedCarrier]
    //     ? data[selectedCarrier]
    //     : [];
    // let availableCarriers = Object.keys(data);
    // return (
    //   <div className="quote-table">
    //     <div
    //       className="pink-header font-family-montserrat-bold"
    //       style={{ fontSize: "x-large" }}
    //     >
    //       Here's Your Price Indication
    //     </div>
    //     <div className="d-flex align-items-center mb-4 carrieroptionselect">
    //       {availableCarriers.map((carrier) => {
    //         return (
    //           <button
    //             className="btn peo-btn keyriskcenter"
    //             style={
    //               selectedCarrier === carrier
    //                 ? { backgroundColor: "#8ab93f" }
    //                 : { backgroundColor: "gray" }
    //             }
    //             onClick={() => this.props.setSelectedCarrier(carrier)}
    //           >
    //             <span
    //               className="font-family-montserrat-regular "
    //               style={{ fontWeight: "800" }}
    //             >
    //               {carrierMap[carrier]}
    //             </span>
    //           </button>
    //         );
    //       })}
    //     </div>
    //     <div className="container quote-table-box">
    //       <div
    //         className="text-center font-family-montserrat-semi-bold"
    //         style={{ marginBottom: "10px" }}
    //       >
    //         The Below Pricing is only an Indication and is subject to change
    //         based on documents submitted.
    //       </div>
    //       <DataTable data={dataToShow} />
    //       <div
    //         className="text-left font-family-montserrat-semi-bold"
    //         style={{ fontSize: "14px", marginInline: "2rem" }}
    //       >
    //         *Workers' Compensation will not be charged on tipped wages, nor will
    //         it be charged on the premium portion of overtime wages.
    //       </div>
    //       <div className="quote-button-container">
    //         {/* <button
    //           className="btn close-btn"
    //           style={{ fontWeight: "800", fontFamily: "MontserratRegular" }}
    //         >
    //           Close
    //         </button> */}
    //         <button
    //           className="btn export-btn"
    //           style={{ fontWeight: "800", fontFamily: "MontserratRegular" }}
    //           onClick={() =>
    //             this.quoteView(dataFromAPI, selectedCarrier, quoteData, userId)
    //           }
    //         >
    //           Export to PDF
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

let firstCaps = (st) => {
  return st
    .split(" ")
    .map((e) => {
      e = e.trim().toLowerCase();
      return e[0].toUpperCase() + e.substring(1);
    })
    .join(" ");
};

let roundVaues = (a, b) => {
  let divi = 100,
    fix = 2;
  if (b) {
    divi = 10000;
    fix = 4;
  }
  if (a) return (Math.round(Number(a) * divi) / divi).toFixed(fix);
  return 0;
};

const roundOffAndThousandSeperated = (num) => {
  if (!num) return "";
  let val = Math.round(Number(num));
  return "$" + val.toLocaleString("en");
};

const DataTable = (props) => {
  let rowData = props.data.map((obj, indx) => {
    return (
      <tr key={indx}>
        <td
          style={{ fontSize: "16px" }}
          className="text-center  font-family-montserrat-semi-bold"
        >
          {obj.state.toUpperCase()}
        </td>
        <td style={{ fontWeight: "600" }}>{obj.wc_code}</td>
        <td
          className="desc-td"
          style={{
            overflow: "hidden",
            textAlign: "inherit",
            width: "45%",
            tableLayout: "fixed",
            fontWeight: "600",
          }}
          title={obj.wc_desc}
        >
          {firstCaps(obj.wc_desc)}
        </td>
        <td
          className=" eligibility_Netrate font-family-MontserratMedium"
          style={{
            textTransform: "capitalize",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {obj.eligibility}
        </td>
        <td className="text-right" style={{ fontWeight: "600" }}>
          {roundVaues(obj.manual_rate)}
        </td>
        <td className="text-right" style={{ fontWeight: "600" }}>
          {roundVaues(
            props.hasProrateValue
              ? !obj.masterWithFundRateOrMinimumPremSum
                ? obj.annualized_net_rate
                : obj.net_rate
              : obj.net_rate,
            true
          )}
        </td>
        <td className="text-right" style={{ fontWeight: "600" }}>
          {roundOffAndThousandSeperated(obj.payroll)}
        </td>
        <td className="text-right" style={{ fontWeight: "600" }}>
          {roundOffAndThousandSeperated(obj.manual_premium)}
        </td>
        {props.hasProrateValue && (
          <td className="text-right" style={{ fontWeight: "600" }}>
            {roundOffAndThousandSeperated(
              props.hasProrateValue
                ? !obj.masterWithFundRateOrMinimumPremSum
                  ? obj.annualized_eap
                  : obj.teap
                : obj.teap
            )}
          </td>
        )}
        <td className="text-right" style={{ fontWeight: "600" }}>
          {props.hasProrateValue
            ? !obj.masterWithFundRateOrMinimumPremSum
              ? roundOffAndThousandSeperated(obj.teap)
              : roundOffAndThousandSeperated(
                  obj.masterWithFundRateProratedEAP
                ) || "-"
            : roundOffAndThousandSeperated(obj.teap)}
        </td>
      </tr>
    );
  });
  if (props.lastPage)
    rowData.push(
      <tr key={props.data.length}>
        <td
          className="font-family-montserrat-semi-bold"
          style={{ fontSize: "16px" }}
        >
          {"TOTAL"}
        </td>
        <td></td>
        <td className="desc-td"></td>
        <td style={{ textTransform: "capitalize", fontSize: "16px" }}></td>
        <td></td>
        <td></td>
        <td
          className="font-family-montserrat-semi-bold text-right"
          style={{ fontSize: "16px" }}
        >
          {roundOffAndThousandSeperated(props.total_values_row.payroll)}
        </td>
        <td
          className="font-family-montserrat-semi-bold text-right"
          style={{ fontSize: "16px" }}
        >
          {roundOffAndThousandSeperated(props.total_values_row.manual_premium)}
        </td>
        {props.hasProrateValue && (
          <td
            className="font-family-montserrat-semi-bold text-right"
            style={{ fontSize: "16px" }}
          >
            {roundOffAndThousandSeperated(
              props.total_values_row.annualized_teap
            )}
          </td>
        )}
        <td
          className="font-family-montserrat-semi-bold text-right"
          style={{ fontSize: "16px" }}
        >
          {roundOffAndThousandSeperated(props.total_values_row.teap)}
        </td>
      </tr>
    );

  let tableData = (
    <table
      className="table table-sm table-bordered netrateimagefont"
      id="quote-table"
      // style={{ marginLeft: "30px", marginRight: "20px" }}
    >
      <thead>
        <tr>
          <th
            className="text-center"
            style={{
              // maxWidth: "20px",
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
            }}
          >
            State
          </th>
          <th
            style={{
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
            }}
          >
            W.C.<br></br>Code
          </th>
          <th
            style={{
              maxWidth: "100px",
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
            }}
          >
            W.C. Description
          </th>
          <th
            className="eligibility_Netrate "
            style={{
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
            }}
          >
            Eligibility
          </th>
          <th
            style={{
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
              alignItems: "center",
            }}
            className="text-right"
          >
            Manual<br></br>Rate
          </th>
          <th
            style={{
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
              alignItems: "center",
            }}
            className="text-right"
          >
            Net<br></br>Rate
          </th>
          <th
            style={{
              // minWidth: "20px",
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
            }}
            className="text-right"
          >
            Payroll
          </th>
          <th
            style={{
              // minWidth: "20px",
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
              alignItems: "center",
            }}
            className="text-right"
          >
            Manual<br></br>Premium
          </th>
          <th
            style={{
              // minWidth: "20px",
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
              fontSize: "18px",
              fontFamily: "montserratMedium",
            }}
            className="text-right"
          >
            {props.hasProrateValue ? "Annual EAP" : "EAP"}
          </th>
          {props.hasProrateValue && (
            <th
              style={{
                // minWidth: "20px",
                backgroundColor: "#3e9ccc",
                color: "#ffffff",
                verticalAlign: "middle",
                fontSize: "18px",
                fontFamily: "montserratMedium",
              }}
              className="text-right"
            >
              Pro-rated EAP
            </th>
          )}
        </tr>
      </thead>

      <tbody>{rowData}</tbody>
    </table>
  );

  return (
    <div
      className="table-responsive table-wrapper"
      id={props.id}
      style={{
        width: "1080px",
        fontSize: "13px",
      }}
    >
      {tableData}
    </div>
  );
};

export default QuoteTable;
